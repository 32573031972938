import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { obtenerCompanias } from "../utilities/request";
import {
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useQuery } from "react-query";
import swal from "sweetalert2";

const MtableRegulatorioView = (props) => {
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedFuente, setSelectedfuente] = useState("");
  const [selectedNombre, setSelectedNombre] = useState("");
  const [selectedFecha, setSelectedFecha] = React.useState(null);
  const [errors, SetErrors] = useState({});
  const [data, setdata] = React.useState(null);
  const [distribucion, setdistribucion] = React.useState(null);
  const [logica_vectorATQ, setlogica_vectorATQ] = React.useState(null);
  const [confidencialidad, setconfidencialidad] = React.useState(null);
  const [integridad, setintegridad] = React.useState(null);
  const [disponibilidad, setdisponibilidad] = React.useState(null);
  const [ambienteAntes, setambienteAntes] = React.useState(null);
  const [ambienteDespues, setambienteDespues] = React.useState(null);
  const [ambiente, setambiente] = React.useState(null);
  const [fuenteDisponibilidad, setfuenteDisponibilidad] = React.useState(null);
  const [fuenteIntegridad, setfuenteIntegridad] = React.useState(null);
  const [fuenteConfidencialidad, setfuenteConfidencialidad] =
    React.useState(null);
  const [fuenteLogica, setfuenteLogica] = React.useState(null);
  const { data: companiaDat } = useQuery(["compañias"], () =>
    obtenerCompanias()
  );

  const handleChangeCompania = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleChangeFuente = (event) => {
    setSelectedfuente(event.target.value);
  };

  const handleChangeNombre = (event) => {
    setSelectedNombre(event.target.value);
  };

  const handleChangeFecha = (event) => {
    setSelectedFecha(event.target.value);
  };

  const validate = () => {
    let temp = {};
    temp.fuente = selectedFuente ? "" : "Este Campo es mandatorio";
    temp.compania = selectedCompany ? "" : "Este Campo es mandatorio";
    temp.fecha = selectedFecha ? "" : "Este Campo es mandatorio";
    temp.nombre = selectedNombre ? "" : "Este Campo es mandatorio";
    SetErrors({ ...temp });

    return Object.values(temp).every((x) => x == "");
  };

  const handleSubmit = () => {
    if (validate()) calcularReg();
  };

  const calcularReg = async () => {
    const Data = {
      compania: selectedCompany,
      fuente: selectedFuente,
      nombre: selectedNombre,
      fecha: selectedFecha,
    };

    const timer15sec = new Promise((resolve, reject) => {
      setTimeout(resolve, 15000, "overtime");
    });
    const firstCall = fetch(
      process.env.REACT_APP_API_URL + "regulatorioEstadisticas/",
      {
        method: "POST",
        responseType: "blob",
        body: JSON.stringify(Data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          //Authorization: "Bearer " + token,
        },
      }
    );

    Promise.race([timer15sec, firstCall])
      .then(async (value) => {
        let response;
        if (value === "overtime") {
          //means timer exceeded 15secs

          swal.fire(
            "Calculando Regulatorio, intentelo nuevamente mas tarde",
            "",
            "warning"
          );
        } else {
          response = value;
          try {
            if (value.status == 200) {
              swal.fire("mostrando estadisticas", "", "success");
              let data = await value.json();
              setdata(data);
              if (data) {
                const dist = JSON.parse(data.distribucion);
                const atq = JSON.parse(data.logica_vectorATQ);
                const con = JSON.parse(data.confidencialidad);
                const int = JSON.parse(data.integridad);
                const dis = JSON.parse(data.disponibilidad);
                const ambA = JSON.parse(data.ambienteAntes);
                const ambD = JSON.parse(data.ambienteDespues);
                const amb = JSON.parse(data.ambiente);
                const fuenD = JSON.parse(data.fuenteDisponibilidad);
                const fuentI = JSON.parse(data.fuenteIntegridad);
                const fuentC = JSON.parse(data.fuenteConfidencialidad);
                const fuentL = JSON.parse(data.fuenteLogica);
                setdistribucion(dist);
                setlogica_vectorATQ(atq);
                setconfidencialidad(con);
                setintegridad(int);
                setdisponibilidad(dis);
                setambienteAntes(ambA);
                setambienteDespues(ambD);
                setambiente(amb);
                setfuenteDisponibilidad(fuenD);
                setfuenteIntegridad(fuentI);
                setfuenteConfidencialidad(fuentC);
                setfuenteLogica(fuentL);
              }
            } else if (value.status == 404) {
              swal.fire(
                "No existe ningun regulatorio con los datos ingresados",
                "",
                "error"
              );
            }
          } catch (error) {
            swal.fire("Error", "Ha ocurrido un error", "error");
          }
        }
        return response;
      })
      .then((result) => {});
  };

  return (
    <Box
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Row>
        <Col>
          {" "}
          <h2 className='subtitulo'>Estadisticas Regulatorio</h2>
          <hr />
        </Col>
      </Row>
      <Form>
        <Box>
          <Row className='mb-3'>
            <Col sm={3} xs={3}>
              <label className='label forn-label'>Compañía*</label>
            </Col>
            <FormControl sx={{ m: 2, minWidth: 120 }}>
              <InputLabel>compania</InputLabel>

              <Select
                value={selectedCompany}
                label='seleccione la compania'
                width='100px'
                onChange={handleChangeCompania}
                error={errors.compania}
              >
                {companiaDat &&
                  companiaDat.map((company) => {
                    return (
                      <MenuItem value={company.idcompania}>
                        {company.compania}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Row>
        </Box>
        <Box>
          <Row className='mb-3'>
            <Col sm={3} xs={3}>
              <label className='label forn-label'>Fuente*</label>
            </Col>
            <FormControl sx={{ m: 2, minWidth: 120 }}>
              <InputLabel>Fuente</InputLabel>
              <Select
                placeholder={"Seleccione la Fuente..."}
                label='seleccione la Fuente'
                value={selectedFuente}
                onChange={handleChangeFuente}
                error={errors.fuente}
              >
                <MenuItem value={"RegTenable"}>RegTenable</MenuItem>
                <MenuItem value={"RegPrismaCloud"}>RegPrismaCloud</MenuItem>
              </Select>
            </FormControl>
          </Row>
          <Row className='mb-3'>
            <Col sm={3} xs={3}>
              <label className='label forn-label'>Nombre*</label>
            </Col>
            <Col sm={{ minWidth: 120 }} xs={10}>
              <TextField
                id='outlined-controlled'
                label='nombre'
                value={selectedNombre}
                onChange={handleChangeNombre}
                error={errors.nombre}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col sm={3} xs={3}>
              <label className='label forn-label'>Fecha*</label>
            </Col>
            <Col sm={{ minWidth: 120 }} xs={10}>
              <TextField
                id='outlined-controlled'
                label='Fecha'
                value={selectedFecha}
                onChange={handleChangeFecha}
                error={errors.fecha}
              />
            </Col>
          </Row>
          <Col sm={4} xs={6}>
            <Box>
              <Button
                style={{
                  backgroundColor: "#00c389",
                  color: "#fff",
                  width: "100px",
                  borderRadius: "10px",
                  height: "40px",
                }}
                onClick={handleSubmit}
              >
                Calcular
              </Button>
            </Box>
          </Col>
        </Box>
      </Form>

      {data &&
        distribucion &&
        logica_vectorATQ &&
        confidencialidad &&
        integridad &&
        disponibilidad &&
        ambienteAntes &&
        ambienteDespues &&
        ambiente &&
        fuenteDisponibilidad &&
        fuenteIntegridad &&
        fuenteConfidencialidad &&
        fuenteLogica && (
          <Box>
            <h1>
              Estadisticas regulatorio {selectedNombre} de {selectedFecha}{" "}
            </h1>

            <span>fecha de escaneo: {data.fecha_escaneo}</span>
            <br></br>
            <br></br>
            <div style={{ display: "flex" }}>
              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>distribucion</h2>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(distribucion.cvssfinalcualitativo).map(
                    (value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    }
                  )}
                </div>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(
                    distribucion.impactocualitativovulnerabilidad
                  ).map((value, index) => {
                    return (
                      <div key={index}>
                        {value}

                        <hr />
                      </div>
                    );
                  })}
                </div>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(distribucion.cantidad).map((value, index) => {
                    return (
                      <div key={index}>
                        {value}

                        <hr />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>vector de Ataque</h2>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(logica_vectorATQ.ve_vectorataque).map(
                    (value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    }
                  )}
                </div>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(logica_vectorATQ.vvebex_ubicacionlogica).map(
                    (value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    }
                  )}
                </div>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(logica_vectorATQ.size).map((value, index) => {
                    return (
                      <div key={index}>
                        {value}

                        <hr />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>confidencialidad </h2>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(confidencialidad.vi_confidencialidad).map(
                    (value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    }
                  )}
                </div>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(confidencialidad.vvebimp_confidencialidad).map(
                    (value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    }
                  )}
                </div>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(confidencialidad.size).map((value, index) => {
                    return (
                      <div key={index}>
                        {value}

                        <hr />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>integridad</h2>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(integridad.vi_integridad).map(
                    (value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    }
                  )}
                </div>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(integridad.vvebimp_integridad).map(
                    (value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    }
                  )}
                </div>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(integridad.size).map((value, index) => {
                    return (
                      <div key={index}>
                        {value}

                        <hr />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>disponibilidad</h2>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(disponibilidad.vi_disponibilidad).map(
                    (value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    }
                  )}
                </div>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(disponibilidad.vvebimp_disponibilidad).map(
                    (value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    }
                  )}
                </div>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  {Object.values(disponibilidad.size).map((value, index) => {
                    return (
                      <div key={index}>
                        {value}

                        <hr />
                      </div>
                    );
                  })}
                </div>
                <br></br>
                <br></br>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>Ambientes Antes</h2>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(ambienteAntes.tipo_ambiente).map(
                      (value, index) => {
                        return (
                          <div key={index}>
                            {value}

                            <hr />
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(ambienteAntes.cvssfinalcualitativo).map(
                      (value, index) => {
                        return (
                          <div key={index}>
                            {value}

                            <hr />
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(ambienteAntes.size).map((value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>Ambientes Despues</h2>
                <div style={{ display: "inline-block", padding: "5px" }}>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(ambienteDespues.vvebimp_tipoambiente).map(
                      (value, index) => {
                        return (
                          <div key={index}>
                            {value}

                            <hr />
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(
                      ambienteDespues.impactocualitativovulnerabilidad
                    ).map((value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    })}
                  </div>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(ambienteDespues.size).map((value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>Ambientes</h2>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(ambiente.fuente_vvebimp_tipoambiente).map(
                      (value, index) => {
                        return (
                          <div key={index}>
                            {value}

                            <hr />
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(ambiente.size).map((value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>Fuente Disponibilidad</h2>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(
                      fuenteDisponibilidad.fuente_vvebimp_disponibilidad
                    ).map((value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    })}
                  </div>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(fuenteDisponibilidad.size).map(
                      (value, index) => {
                        return (
                          <div key={index}>
                            {value}

                            <hr />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>Fuente Integridad</h2>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(
                      fuenteIntegridad.fuente_vvebimp_integridad
                    ).map((value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    })}
                  </div>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(fuenteIntegridad.size).map(
                      (value, index) => {
                        return (
                          <div key={index}>
                            {value}

                            <hr />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>Fuente Confidencialidad</h2>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(
                      fuenteConfidencialidad.fuente_vvebimp_confidencialidad
                    ).map((value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    })}
                  </div>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(fuenteConfidencialidad.size).map(
                      (value, index) => {
                        return (
                          <div key={index}>
                            {value}

                            <hr />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{ display: "inline", border: "solid", margin: "2px" }}
              >
                <h2>Fuente Logica</h2>
                <div style={{ display: "inline-block", padding: "10px" }}>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(
                      fuenteLogica.fuente_vvebex_ubicacionlogica
                    ).map((value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    })}
                  </div>
                  <div style={{ display: "inline-block", padding: "10px" }}>
                    {Object.values(fuenteLogica.size).map((value, index) => {
                      return (
                        <div key={index}>
                          {value}

                          <hr />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        )}
    </Box>
  );
};

export default MtableRegulatorioView;
