import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, Row, Col, Form} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import AADService from "../funcionesAuth.js";


function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='success'>Guardó exitosamente</Alert>;     
    case 2:
      return <Alert variant='warning'>Error al enviar la información</Alert>;      
    case 3:
      return <Alert variant='danger'>Error en el servidor</Alert>;     
    default:
      return <p></p>;
  }
}
function GridComponent() {
  const [data, setData] = useState([]); 
  const [validated, setValidated] = useState(false);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const handleCheckboxChange = (rowIndex, columnName) => {
    const newData = [...data];
    newData[rowIndex][columnName] = newData[rowIndex][columnName] ? 0 : 1;
    setData(newData);
  };

  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;   
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      fetch(process.env.REACT_APP_API_URL + "cuestionarioAdmin/", {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      })      
      .then((data) =>
      {        
          if (data.status >= 200 && data.status < 300) {
            setEstadoPost(1);           
          } else if (data.status >= 500) {
            setEstadoPost(3);            
          } else if (data.status >= 400 && data.status < 500) {
            setEstadoPost(2);
          }   
      })
      .catch(function (err) {});
    }
    setValidated(true);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "cuestionarioAdmin/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setData(data);
    };
  fetchData();
  }, []);

  return (
    <>
      <AlertDismissibleExample alerta={estadoPOST} />
      <Row>
        <Col sm={5}>
          <h3 className="subtitulo">Cuestionario Admin</h3>
        </Col>
      </Row>
      <Row>
        <Col sm={12}> &nbsp; </Col>
      </Row>
      <Form
        id="formData"
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <Paper style={{ maxWidth: "100%", overflowX: "auto" }}>
          <TableContainer style={{ maxHeight: "70vh" }}>
            <Table stickyheader style={{position:"relative"}}>
              <TableHead style={{top:"0", zIndex: "1", position:"sticky"}}>
                <TableRow>
                  <TableCell
                    style={{backgroundColor: "#2c2a29", color: "#ffffff"}}
                  >
                    ID Pregunta
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                  >
                    Pregunta
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#2c2a29",
                      color: "#ffffff",
                      textAlign: "center"
                    }}
                    colSpan={3}
                  >
                    Tipo de Activo
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#2c2a29",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                    colSpan={3}
                  >
                    Tipo de Despliegue
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#2c2a29",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                    colSpan={4}
                  >
                    Tipo de Canal
                  </TableCell>
                </TableRow>
                <TableRow  style={{backgroundColor: "white"}}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className="border-left">Aplicaciones</TableCell>
                  <TableCell>Servidores</TableCell>
                  <TableCell>Apis</TableCell>
                  <TableCell className="border-left">Onpremise</TableCell>
                  <TableCell>Nube</TableCell>
                  <TableCell>Saas</TableCell>
                  <TableCell className="border-left">Ninguno</TableCell>
                  <TableCell>FIsico</TableCell>
                  <TableCell>Remoto</TableCell>
                  <TableCell>Digital</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, rowIndex) => (
                  <TableRow key={row.idcuestionario}>
                    <TableCell>{row.idcuestionario}</TableCell>
                    <TableCell>{row.pregunta}</TableCell>
                    {Object.keys(row).map((columnName) =>
                      columnName !== "idcuestionario_tipoactivo" &&
                      columnName !== "idcuestionario" &&
                      columnName !== "pregunta" ? (
                        <TableCell key={columnName}>
                          <Checkbox
                            checked={row[columnName] === 1}
                            onChange={() =>
                              handleCheckboxChange(rowIndex, columnName)
                            }
                          />
                        </TableCell>
                      ) : null
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Row>
          <Col sm={12}> &nbsp; </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={1}></Col>
          <Col sm={3} xs={3}>
            {" "}
            <Button type="submit" className="botonPositivo" id="send">
              Guardar
            </Button>
          </Col>
          <Col sm={3} xs={3}>
            <Link to="/">
              {" "}
              <Button className="botonNegativo">Descartar</Button>
            </Link>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default GridComponent;
