import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";
import { param } from "jquery";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TablePagination from "@mui/material/TablePagination";
import { el } from "date-fns/locale";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { IoMdDownload } from "react-icons/io";
import XLSX from "xlsx";
import AADService from "../funcionesAuth.js";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

function AlertEfectividadControles({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return (
        <Alert variant='success'>
          Las frecuencias se calcularon correctamente
        </Alert>
      );
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//Se definen las Columnas que tendra la tabla
const ControlesEvaCells = [
  // {
  //   id: "control_NIST",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Nombre Control",
  // },
  // {
  //   id: "idControl_NIST",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Código NIST",
  // },
  // {
  //   id: "categoria_control",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Categoría",
  // },
  // { id: "capa_control", numeric: false, disablePadding: false, label: "Capa" },
  // {
  //   id: "tipo_control",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Naturaleza",
  // },
  {
    id: "idImplementador",
    numeric: false,
    disablePadding: false,
    label: "Id Implementador",
  },
  {
    id: "implementador",
    numeric: false,
    disablePadding: false,
    label: "Tecnología/Actividad habilitadora",
  },
  {
    id: "descripcion",
    numeric: false,
    disablePadding: false,
    label: "Descripción Tecnología/Actividad habilitadora",
  },
  {
    id: "aplicabilidad",
    numeric: false,
    disablePadding: false,
    label: "Nivel Aplicabilidad",
  },
  {
    id: "automatizacion",
    numeric: false,
    disablePadding: false,
    label: "Nivel Automatización",
  },
  {
    id: "gestion",
    numeric: false,
    disablePadding: false,
    label: "Nivel Gestión",
  },
  {
    id: "gestion2",
    numeric: false,
    disablePadding: false,
    label: " ",
  },
  {
    id: "cobertura_objetivo",
    numeric: false,
    disablePadding: false,
    label: "Cobertura Objetivo",
  },
  {
    id: "cobertura_actual",
    numeric: false,
    disablePadding: false,
    label: "Cobertura Actual",
  },
  {
    id: "porcentaje_cobertura",
    numeric: false,
    disablePadding: false,
    label: "Porcentaje Cobertura(%)",
  },
  {
    id: "eficaciaOperativa",
    numeric: false,
    disablePadding: false,
    label: "Eficacia Operativa(%)",
  },
  {
    id: "prueba_tecnica",
    numeric: false,
    disablePadding: false,
    label: "Prueba Técnica/Indicadores(%)",
  },
  {
    id: "efectividad",
    numeric: false,
    disablePadding: false,
    label: "Efectividad de la Tecnología/Actividad",
  },
  //{ id: 'efectividadAgregada', numeric: false, disablePadding: false, label: 'Efectividad agregada' },
];

/*Encabezado de tabla*/
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        ></TableCell>
        {ControlesEvaCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{
              backgroundColor: "#2c2a29",
              color: "#ffffff",
            }}
          >
            <label className='label'>{headCell.label}</label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

//Estilo de la Tabla
const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "70vh",
    minHeight: "70vh",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "304.41400304414003vh",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

//Funcion que retorna la Tabla completa
export default function ControlesEva({ shouldExecuteEffect}) {
  //Constantes para el funcionamiento de la Tabla
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [loadingEfect, setLoadingEfect] = React.useState(false);
  //const [progress, setProgress] = React.useState(10);
  const [datosControles, setDatosControles] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //Constantes para consumir datos de los Controles
  const [tecnologia, setTecnologia] = React.useState([]);
  const [estadoPUT, setEstadoPUT] = React.useState(0);
  const [estadoPUTEfectividad, setEstadoPUTEfectividad] = React.useState(0);
  const [automatizacionvln, setAutomatizacionVln] = React.useState([]);
  const [gestionvln, setGestionVln] = React.useState([]);
  const [implexEval, setImplexEval] = React.useState([]);
  const [implexRiesgo, setImplexRiesgo] = React.useState([]);
  const [riesgoxamenaza, setRiesgoxamenaza] = React.useState([]);
  const [queryText, setqueryText] = React.useState("");
  const [cantidadComps, setCantidadComps] = React.useState(1);
  const [filterValue, setFilterValue] = React.useState([]);
  const [analizarEfectividades, setAnalizarEfectividades] =
    React.useState(false);
  var codigosResueltos = [];
  /*
  const timer = setInterval(() => {
    setProgress((prevProgress) =>
      prevProgress >= 100 ? 0 : prevProgress + 10
    );
  }, 800);
  */
  const [validRole, setValidRole] = React.useState(
    localStorage.getItem("rolusuario") == 2 ||
      localStorage.getItem("rolusuario") == 1
      ? true
      : false
  );
  /*
  function changeFilter() {
    var valor = document.getElementById("option_select").value;
    setFilterValue(valor);
  }
  */
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  //Peticiones GET para consumir el tipo de opciones que tiene cada columna de la Tabla
  useEffect(() => {
    if (shouldExecuteEffect === false) {
      const fetchdataTech = async () => {
        setLoading(true);
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/controles/" +
            localStorage.getItem("idcompania") +
            "/" +
            localStorage.getItem("idevaluacion") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let param = await result.json();
        setTecnologia(param);
        setLoading(false);
      };
      const componentesFetch = async () => {
        try {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/componentesTI/" +
            localStorage.getItem("id_activo") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let param = await result.json();
        try {
          if (param.length >= 1) {
            setCantidadComps(param.length);
          } else {
            setCantidadComps(1);
          }
        } catch {
          setCantidadComps(1);
        }
      }
      catch (error) {
        console.error('ERROR en fetch de componentesFetch', error);
      }
      };
      const automatizacionFetch = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "general/parametrosGeneralesxGrupoxParametro/Controles/Automatizacion_control/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let param = await result.json();
        setAutomatizacionVln(param);
      };
      const gestionFetch = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "general/parametrosGeneralesxGrupoxParametro/Controles/Gestion_control/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let param = await result.json();
        setGestionVln(param);
      };

      const fetchdataControlImplexEval = async () => {
        try {
          const result = await fetch(
            process.env.REACT_APP_API_URL +
              'control/implementadorxeval/' +
              localStorage.getItem('idevaluacion') +
              '/',
            {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + token,
              },
            }
          );
          let param = await result.json();
          setImplexEval(param);
        } catch (error) {
          console.error('ERROR en fetchdataControlImplexEval:', error);
          setImplexEval(null);
        }
      };

      const fetchdataControlImplementadorxRiesgo = async () => {
        try {
          const result = await fetch(
            process.env.REACT_APP_API_URL +
              "control/implementadorxriesgo/" +
              localStorage.getItem("idevaluacion") +
              "/",
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          let param = await result.json();
          setImplexRiesgo(param);
        } catch (error) {
          console.error('ERROR en fetchdataControlImplementadorxRiesgo:', error);
          setImplexRiesgo(null);
        }
      };

      const fetchdataControlRiesgoxamenaza = async () => {
        try {
          const result = await fetch(
            process.env.REACT_APP_API_URL +
              "control/riesgoxamenaza/" +
              localStorage.getItem("idevaluacion") +
              "/",
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          let param = await result.json();
          setRiesgoxamenaza(param);
        } catch (error) {
          console.error('ERROR en fetchdataControlRiesgoxamenaza:', error);
          setRiesgoxamenaza(null);
        }
      };
      fetchdataControlImplexEval();
      fetchdataControlImplementadorxRiesgo();
      fetchdataControlRiesgoxamenaza();
      gestionFetch();
      componentesFetch();
      automatizacionFetch();
      fetchdataTech();
  }
  }, [shouldExecuteEffect]);
  /*
  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  */
  function enviarLotes(data) {
    let datos = JSON.stringify(data);
    fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/controles/" +
        localStorage.getItem("idcompania") +
        "/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "PUT",
        body: datos,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((data) =>
        data.json().then((response) => {
          if (data.status >= 200 && data.status < 300) {
            return 200;
            //setEstadoPUT(2);
          } else if (data.status >= 500) {
            return 500;
            //setEstadoPUT(5);
          } else if (data.status >= 400 && data.status < 500) {
            return 400;
            //setEstadoPUT(4);
          }
        })
      )
      .catch(function (err) {
        // console.log(err);
      });
  }
  const sendData = (event) => {
    event.preventDefault();
    var dataTemp = [];
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    let lotes = [];
    let result = tecnologia.length;
    let rango = parseInt(tecnologia.length / 5, 10);
    let inicial = 0;
    let dataGeneral = [];
    for (let i = 1; i <= 5; i++) {
      if (i == 5) {
        lotes.push([inicial, result - 1]);
      } else {
        lotes.push([inicial, rango * i]);
      }
      inicial = rango * i + 1;
    }

    for (let i = 0; i < lotes.length; i++) {
      for (let index = lotes[i][0]; index <= lotes[i][1]; index++) {
        const element = tecnologia[index];
        let evaluacion_temp = "";
        if (
          document.getElementById("aplicabilidad" + index.toString()).value ==
          "Aplica"
        ) {
          evaluacion_temp =
            element.idevaluacion == null
              ? parseInt(localStorage.getItem("idevaluacion"))
              : parseInt(element.idevaluacion);
        } else {
          if (element.idevaluacion != null && element.idevaluacion > 0) {
            evaluacion_temp = element.idevaluacion;
          } else {
            evaluacion_temp = null;
          }
        }
        let porcentajeEnviar = parseFloat(
          parseInt(
            document
              .getElementById("porcentaje_cobertura" + index.toString())
              .innerText.split("%")[0]
          ) / 100
        ).toFixed(2);
        let eficaciaEnviar = parseFloat(
          parseInt(
            document.getElementById("eficacia" + index.toString()).value
          ) / 100
        ).toFixed(4);
        eficaciaEnviar = eficaciaEnviar <= 0.0 ? null : eficaciaEnviar;
        let pruebaEnviar = parseFloat(
          parseInt(
            document.getElementById("pruebatecnica" + index.toString()).value
          ) / 100
        ).toFixed(4);
        pruebaEnviar = pruebaEnviar <= 0.0 ? null : pruebaEnviar;

        dataTemp.push({
          idcompania: localStorage.getItem("idcompania"),
          idimplementadorporcontrol: element.idimplementadorporcontrol,
          idcontrol: element.idcontrol,
          idcontrol_nist: element.idcontrol_nist,
          control_nist: element.control_nist,
          descripcion_control_nist: element.descripcion_control_nist,
          categoria_control: element.categoria_control,
          tipo_control: element.tipo_control,
          capa_control: element.capa_control,
          idimplementador: element.idimplementador,
          implementador: element.implementador,
          descripcion: element.descripcion,
          nivel_automatizacion_m: element.nivel_automatizacion_m,
          nivel_gestion_m: element.nivel_gestion_m,
          estado: element.estado,
          idcontrol_evaluacion: element.idcontrol_evaluacion,
          idevaluacion: evaluacion_temp,
          implementadorcontrol: element.implementadorcontrol,
          aplicabilidad: document.getElementById(
            "aplicabilidad" + index.toString()
          ).value,
          nivel_automatizacion: document.getElementById(
            "automatizacion" + index.toString()
          ).value,
          nivel_gestion: document.getElementById("gestion" + index.toString())
            .value,
          coberturaobjetivo: parseInt(
            document.getElementById("cobertura_objetivo" + index.toString())
              .value
          ),
          coberturaactual: parseInt(
            document.getElementById("cobertura_actual" + index.toString()).value
          ),
          porcentajecobertura: porcentajeEnviar,
          eficaciaoperativa: eficaciaEnviar,
          pruebatecnica: pruebaEnviar,
          efectividad: parseFloat(
            document.getElementById("efectividad" + index.toString()).innerText
          ).toFixed(4),
          efectividadagregada:
            document.getElementById(
              "efectividad_agregada" + index.toString()
            ) == null
              ? 1
              : document.getElementById(
                  "efectividad_agregada" + index.toString()
                ).value,
          fechacreacion: element.fechacreacion,
          idusuariocreacion:
            element.idusuariocreacion == null
              ? localStorage.getItem("idusuario")
              : element.idusuariocreacion,
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: localStorage.getItem("idusuario"),
          disp_numerico1: element.disp_numerico1,
          disp_numerico2: element.disp_numerico2,
          disp_varchar1: element.disp_varchar1,
          disp_varchar2: element.disp_varchar2,
        });
      }
      dataGeneral.push(dataTemp);
      dataTemp = [];
    }
    let estados = [];
    setLoading(true);
    for (let i = 0; i < dataGeneral.length; i++) {
      let status = enviarLotes(dataGeneral[i]);
      if (status == 500) {
        setEstadoPUT(5);
        setLoading(false);
        break;
      } else if (status == 400) {
        setEstadoPUT(4);
        setLoading(false);
        break;
      }
      estados.push(200);
    }
    if (estados.length == 5) {
      const fetchdataRefresh = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/controles/" +
            localStorage.getItem("idcompania") +
            "/" +
            localStorage.getItem("idevaluacion") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let param = await result.json();
        setEstadoPUT(2);
        setTecnologia(param);
        setAnalizarEfectividades(true);
        setLoading(false);
      };
      fetchdataRefresh();
    }
  };

  const calcularEfectividades = () => {
    setLoadingEfect(true);
    let data = {
      idusuariomodificacion: localStorage.getItem("idusuario"),
      idevaluacion: localStorage.getItem("idevaluacion"),
    };
    let datos = JSON.stringify(data);
    fetch(
      process.env.REACT_APP_API_URL + "evaluacion/controles/efectividades/",
      {
        method: "PUT",
        body: datos,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((data) =>
        data.json().then((response) => {
          if (data.status >= 200 && data.status < 300) {
            setEstadoPUTEfectividad(2);
            setLoadingEfect(false);
          } else if (data.status >= 500) {
            setEstadoPUTEfectividad(5);
            setLoadingEfect(false);
          } else if (data.status >= 400 && data.status < 500) {
            setEstadoPUTEfectividad(4);
            setLoadingEfect(false);
          }
        })
      )
      .catch(function (err) {
        console.log(err);
      });
  };

  function Control(props) {
    const { control, index, arregloGestion, arregloAutomatizacion } = props;
    const labelId = `enhanced-table-checkbox-${index}`;
    const [nivelAutomatizacion, setNivelAutomatizacion] = React.useState(
      control.nivel_automatizacion
    );
    const [nivelAutomatizacion_M, setNivelAutomatizacion_M] = React.useState(
      control.nivel_automatizacion_m
    );
    const [nivelGestion, setNivelGestion] = React.useState(
      control.nivel_gestion
    );
    const [nivelGestion_M, setNivelGestion_M] = React.useState(
      control.nivel_gestion_m
    );
    const [aplicabilidad, setAplicabilidad] = React.useState(
      control.aplicabilidad
    );
    const [coberturaObjetivo, setCoberturaObjetivo] = React.useState(
      control.coberturaobjetivo == null
        ? cantidadComps
        : control.coberturaobjetivo
    );
    const [coberturaActual, setCoberturaActual] = React.useState(
      control.coberturaactual == null ? cantidadComps : control.coberturaactual
    );
    let percentage = 0.0;
    if (
      control.porcentajecobertura == null ||
      control.porcentajecobertura == 0.0
    ) {
      percentage = parseFloat(
        parseInt(coberturaActual) / parseInt(coberturaObjetivo)
      );
      if (percentage >= 0.0) {
      } else {
        percentage = 0.0;
      }
    } else {
      percentage = control.porcentajecobertura;
    }
    const [porcentajeCobertura, setPorcentajeCobertura] = React.useState(
      aplicabilidad == "Aplica" ||
        (control.idevaluacion != null && control.idevaluacion > 0)
        ? percentage
        : null
    );
    const [eficaciaOperativa, setEficaciaOperativa] = React.useState(
      control.eficaciaoperativa == null ? 0.0 : control.eficaciaoperativa
    );
    const [pruebaTecnica, setPruebaTecnica] = React.useState(
      control.pruebatecnica == null ? 0.0 : control.pruebatecnica
    );
    let automatemp = "";
    if (nivelAutomatizacion == null) {
      automatemp = nivelAutomatizacion_M;
    } else {
      automatemp = nivelAutomatizacion;
    }
    let gestiontemp = "";
    if (nivelGestion == null) {
      gestiontemp = nivelGestion_M;
    } else {
      gestiontemp = nivelGestion;
    }
    const [efectividad, setEfectividad] = React.useState(
      aplicabilidad == "Aplica" ||
        (control.idevaluacion != null && control.idevaluacion > 0)
        ? control.efectividad == null || control.efectividad == 0.0
          ? calcularEfectividadAutomatico(
              control,
              porcentajeCobertura,
              automatemp,
              gestiontemp,
              coberturaActual,
              coberturaObjetivo,
              pruebaTecnica,
              eficaciaOperativa
            )
          : control.efectividad
        : null
    );
    const arreglo_aplicabilidad = [
      "Aplica",
      "No aplica",
      "Aplica no implementado",
    ];
    const list_aplica = arreglo_aplicabilidad.map((aplica) => {
      if (aplica != aplicabilidad) {
        return <option value={aplica}>{aplica}</option>;
      } else {
        return (
          <option value={aplica} selected>
            {aplicabilidad}
          </option>
        );
      }
    });
    const list_gest = arregloGestion.map((gestion) => {
      if (gestion.vlrmetrica != gestiontemp) {
        return <option value={gestion.vlrmetrica}>{gestion.vlrmetrica}</option>;
      } else {
        return (
          <option value={gestion.vlrmetrica} selected>
            {gestion.vlrmetrica}
          </option>
        );
      }
    });

    const list_auto = arregloAutomatizacion.map((automa) => {
      if (automa.vlrmetrica != automatemp) {
        return <option value={automa.vlrmetrica}>{automa.vlrmetrica}</option>;
      } else {
        return (
          <option value={automa.vlrmetrica} selected>
            {automa.vlrmetrica}
          </option>
        );
      }
    });

    function handleChangeAplicabilidad(e) {
      let { value } = e.target;
      setAplicabilidad(value);
      calcularPorcentaje(coberturaActual, coberturaObjetivo, value);
    }
    function handleChangeAutomatizacion(e) {
      let { value } = e.target;
      setNivelAutomatizacion(value);
      calcularEfectividad(
        control,
        porcentajeCobertura,
        value,
        nivelGestion,
        coberturaActual,
        coberturaObjetivo,
        pruebaTecnica,
        eficaciaOperativa,
        aplicabilidad
      );
    }
    function handleChangeGestion(e) {
      let { value } = e.target;
      setNivelGestion(value);
      calcularEfectividad(
        control,
        porcentajeCobertura,
        nivelAutomatizacion,
        value,
        coberturaActual,
        coberturaObjetivo,
        pruebaTecnica,
        eficaciaOperativa,
        aplicabilidad
      );
    }
    function handleChangePruebaTecnica(e) {
      let { value } = e.target;
      let result = parseFloat(parseInt(value) / 100).toFixed(4);
      setPruebaTecnica(result);
      calcularEfectividad(
        control,
        porcentajeCobertura,
        nivelAutomatizacion,
        nivelGestion,
        coberturaActual,
        coberturaObjetivo,
        result,
        eficaciaOperativa,
        aplicabilidad
      );
    }
    function handleChangeEficacia(e) {
      let { value } = e.target;
      let result = parseFloat(parseInt(value) / 100).toFixed(4);
      setEficaciaOperativa(result);
      calcularEfectividad(
        control,
        porcentajeCobertura,
        nivelAutomatizacion,
        nivelGestion,
        coberturaActual,
        coberturaObjetivo,
        pruebaTecnica,
        result,
        aplicabilidad
      );
    }
    function handleChangeCoberturaObjetivo(e) {
      let { value } = e.target;
      setCoberturaObjetivo(value);
      calcularPorcentaje(coberturaActual, value, aplicabilidad);
    }

    function handleChangeCoberturaActual(e) {
      let { value } = e.target;
      setCoberturaActual(value);
      calcularPorcentaje(value, coberturaObjetivo, aplicabilidad);
    }

    function calcularPorcentaje(actual, objetivo, aplica) {
      let resultado = parseFloat(parseInt(actual) / parseInt(objetivo));
      if (resultado != NaN) {
        setPorcentajeCobertura(resultado.toFixed(2));
      } else {
        setPorcentajeCobertura(0);
      }
      calcularEfectividad(
        control,
        resultado,
        nivelAutomatizacion,
        nivelGestion,
        actual,
        objetivo,
        pruebaTecnica,
        eficaciaOperativa,
        aplica
      );
    }

    function calcularEfectividad(
      controlCambio,
      porcentaje,
      automa,
      gest,
      actual,
      objetivo,
      prueba,
      eficaciaOp,
      aplica
    ) {
      let porcentaje_cob_ini = parseFloat(porcentaje);
      let porcentaje_cob = Math.exp(porcentaje_cob_ini - 1);

      let naturaleza = parseFloat(controlCambio.pesotipocontrol);
      if (automa == null) {
        automa = controlCambio.nivel_automatizacion_m;
      }
      let automatizacion = 0;
      for (let i = 0; i < arregloAutomatizacion.length; i++) {
        if (arregloAutomatizacion[i].vlrmetrica == automa) {
          automatizacion = arregloAutomatizacion[i].vlrnumericometrica1;
          break;
        }
      }
      if (gest == null) {
        gest = controlCambio.nivel_gestion_m;
      }
      let gestion = 0;
      for (let i = 0; i < arregloGestion.length; i++) {
        if (arregloGestion[i].vlrmetrica == gest) {
          gestion = arregloGestion[i].vlrnumericometrica1;
          break;
        }
      }

      let eficacia = parseFloat(eficaciaOp).toFixed(4);
      let pruebatecnica = parseFloat(prueba).toFixed(4);
      if (eficacia == null || eficacia <= 0.0) {
        eficacia = 1;
      }
      if (pruebatecnica == null || pruebatecnica <= 0.0) {
        pruebatecnica = 1;
      }

      let resultado =
        (naturaleza * automatizacion * 0.005 + gestion * 0.5) *
        porcentaje_cob *
        eficacia *
        pruebatecnica;
      resultado = resultado.toFixed(4);
      setEfectividad(resultado);
      let temp_Controles = tecnologia;
      for (let i = 0; i < temp_Controles.length; i++) {
        if (
          temp_Controles[i].idimplementadorporcontrol ==
          controlCambio.idimplementadorporcontrol
        ) {
          temp_Controles[i].nivel_automatizacion = automa;
          temp_Controles[i].nivel_gestion = gest;
          temp_Controles[i].eficaciaoperativa = eficaciaOp;
          temp_Controles[i].pruebatecnica = prueba;
          temp_Controles[i].aplicabilidad = aplica;
          temp_Controles[i].coberturaobjetivo = objetivo;
          temp_Controles[i].coberturaactual = actual;
          temp_Controles[i].porcentajecobertura = porcentaje_cob_ini;
          temp_Controles[i].efectividad = resultado;
          break;
        }
      }
      setTecnologia(temp_Controles);
    }

    function calcularEfectividadAutomatico(
      controlCambio,
      porcentaje,
      automa,
      gest,
      actual,
      objetivo,
      prueba,
      eficaciaOp
    ) {
      let porcentaje_cob_ini = parseFloat(porcentaje);
      let porcentaje_cob = Math.exp(porcentaje_cob_ini - 1);

      let naturaleza = parseFloat(controlCambio.pesotipocontrol);
      let automatizacion = 0;
      for (let i = 0; i < arregloAutomatizacion.length; i++) {
        if (automa != null) {
          if (arregloAutomatizacion[i].vlrmetrica == automa) {
            automatizacion = arregloAutomatizacion[i].vlrnumericometrica1;
            break;
          }
        } else {
          if (arregloAutomatizacion[i].vlrmetrica == automa) {
            automatizacion = arregloAutomatizacion[i].vlrnumericometrica1;
            break;
          }
        }
      }
      let gestion = 0;
      for (let i = 0; i < arregloGestion.length; i++) {
        if (gest != null) {
          if (arregloGestion[i].vlrmetrica == gest) {
            gestion = arregloGestion[i].vlrnumericometrica1;
            break;
          }
        } else {
          if (arregloGestion[i].vlrmetrica == gest) {
            gestion = arregloGestion[i].vlrnumericometrica1;
            break;
          }
        }
      }

      let eficacia = parseFloat(eficaciaOp).toFixed(4);
      let pruebatecnica = parseFloat(prueba).toFixed(4);
      if (eficacia == null || eficacia <= 0.0) {
        eficacia = 1;
      }
      if (pruebatecnica == null || pruebatecnica <= 0.0) {
        pruebatecnica = 1;
      }

      let resultado =
        (naturaleza * automatizacion * 0.005 + gestion * 0.5) *
        porcentaje_cob *
        eficacia *
        pruebatecnica;
      resultado = resultado.toFixed(4);
      return resultado;
    }

    return (
      <TableRow
        hover
        role='checkbox'
        tabIndex={-1}
        key={control.idimplementadorporcontrol}
      >
        <TableCell padding='checkbox'></TableCell>
        {/* <TableCell component="th" id={labelId} scope="control" padding="none">{control.control_nist}</TableCell>
        <TableCell align="left">{control.idcontrol_nist}</TableCell>
        <TableCell align="left">{control.categoria_control}</TableCell>
        <TableCell align="left">{control.capa_control}</TableCell>
        <TableCell align="left">{control.tipo_control}</TableCell> */}
        <TableCell align='left'>{control.idimplementador}</TableCell>
        <TableCell align='left'>{control.implementador}</TableCell>
        <TableCell align='left'>{control.descripcion}</TableCell>
        <TableCell align='center'>
          <select
            className='form-control aplicabilidad'
            id={"aplicabilidad" + index.toString()}
            onChange={handleChangeAplicabilidad}
          >
            {list_aplica}
          </select>
        </TableCell>
        <TableCell align='center'>
          <select
            className='form-control automatizacion'
            id={"automatizacion" + index.toString()}
            onChange={handleChangeAutomatizacion}
            disabled={aplicabilidad == "Aplica" ? false : true}
          >
            {list_auto}
          </select>
        </TableCell>
        <TableCell align='center' colSpan={2}>
          <select
            className='form-control gestion'
            id={"gestion" + index.toString()}
            onChange={handleChangeGestion}
            disabled={aplicabilidad == "Aplica" ? false : true}
          >
            {list_gest}
          </select>
        </TableCell>
        <TableCell align='right'>
          <input
            id={"cobertura_objetivo" + index.toString()}
            className='form-control cobertura_objetivo'
            type='text'
            onChange={handleChangeCoberturaObjetivo}
            defaultValue={coberturaObjetivo}
            disabled={aplicabilidad == "Aplica" ? false : true}
          ></input>
        </TableCell>
        <TableCell align='right'>
          <input
            id={"cobertura_actual" + index.toString()}
            className='form-control cobertura_actual'
            type='text'
            onChange={handleChangeCoberturaActual}
            defaultValue={coberturaActual}
            disabled={aplicabilidad == "Aplica" ? false : true}
          ></input>
        </TableCell>
        <TableCell
          align='center'
          id={"porcentaje_cobertura" + index.toString()}
        >
          {aplicabilidad == "Aplica" ||
          (control.idevaluacion != null && control.idevaluacion > 0)
            ? parseFloat(100 * porcentajeCobertura).toFixed(2) + "%"
            : ""}
        </TableCell>
        <TableCell align='center'>
          <input
            id={"eficacia" + index.toString()}
            className='form-control eficacia'
            type='text'
            onChange={handleChangeEficacia}
            defaultValue={(eficaciaOperativa * 100).toFixed(2)}
            disabled={aplicabilidad == "Aplica" ? false : true}
          ></input>
        </TableCell>
        <TableCell align='center'>
          <input
            id={"pruebatecnica" + index.toString()}
            className='form-control prueba_tecnica'
            type='text'
            onChange={handleChangePruebaTecnica}
            defaultValue={(pruebaTecnica * 100).toFixed(2)}
            disabled={aplicabilidad == "Aplica" ? false : true}
          ></input>
        </TableCell>
        <TableCell align='center' id={"efectividad" + index.toString()}>
          {parseFloat(efectividad).toFixed(2)}
        </TableCell>
        {/*<TableCell align="left" id={"efectividad_agregada"+index.toString()} >10</TableCell>*/}
      </TableRow>
    );
  }
  const handleOnExport = () => {
    const newBook = XLSX.utils.book_new(),
      sheet1 = XLSX.utils.json_to_sheet(implexEval),
      sheet2 = XLSX.utils.json_to_sheet(implexRiesgo),
      sheet3 = XLSX.utils.json_to_sheet(riesgoxamenaza);
    XLSX.utils.book_append_sheet(newBook, sheet1, "Ctroles_Implem_Evaluacion");
    XLSX.utils.book_append_sheet(newBook, sheet2, "ImplementadorxRiesgo");
    XLSX.utils.book_append_sheet(newBook, sheet3, "ImplxRiesgoxAmz");
    XLSX.writeFile(newBook, "Informes de Controles.xlsx");
  };
  const evaluationState = JSON.parse(localStorage.getItem("estado_evaluacion"));
  return (
    <>
      <div>
        <p className='label forn-label' style={{ color: 'red' }}>
          Es necesario que al activo a evaluar se le asignen los atributos de tipo despliegue y canal para visualizar los nuevos controles.
        </p>
      </div>
      <AlertDismissibleExample alerta={estadoPUT} />
      <AlertEfectividadControles alerta={estadoPUTEfectividad} />
      <Form id='formData' onSubmit={(e) => sendData(e)}>
        <div className={classes.root}>
          <Row>
            <Col sm={9}>
              <h2 className='subtitulo'>Listado de Controles</h2>
            </Col>
          </Row>
          <hr />
          <Row className='mb-2' style={{ justifyContent: "end" }}>
            <Col sm={1}>{loadingEfect ? <CircularProgress /> : ""}</Col>
            <Col sm={3}>
              {analizarEfectividades ? (
                <Button
                  className='botonIngreso2'
                  onClick={() => calcularEfectividades()}
                >
                  Calcular Efectividades
                </Button>
              ) : (
                ""
              )}
            </Col>
            {/* <Col sm={1}>{loading ? <CircularProgress /> : ""}</Col> */}

            <Col sm={3}>
              <>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button className='botonPositivo' onClick={handleOnExport}>
                    Descarga de Controles &nbsp;
                    <IoMdDownload />
                  </Button>
                )}
              </>
            </Col>
            {/* <Col sm={3}>
              {validRole && evaluationState ? (
                <Button
                  className='botonPositivo'
                  onClick={() => setLoading(true)}
                  type='submit'
                >
                  Guardar
                </Button>
              ) : (
                ""
              )}
            </Col> */}
          </Row>
          <hr />
          <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby='tableTitle'
                size='medium'
                aria-label='enhanced table'
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {stableSort(tecnologia, getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <Control
                          control={row}
                          index={index}
                          arregloGestion={gestionvln}
                          arregloAutomatizacion={automatizacionvln}
                        ></Control>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Form>
    </>
  );
}
