import React from 'react';
import ModalAdjuntos from './ModalAdjuntos';

const AdjuntosDecisiones = ({ show, onHide, fileExists, elemento, idElemento, setValidatedData }) => {
    return (
            <ModalAdjuntos 
                show={show} 
                onHide={onHide} 
                showEstadoDecision={true} 
                fileExists={fileExists}
                elemento={elemento}
                idElemento={idElemento}
                setValidatedData={setValidatedData}
            />
    );
};

const AdjuntosEvaluaciones = ({ show, onHide, fileExists, elemento, idElemento, setValidatedData}) => {
    return (
            <ModalAdjuntos 
                show={show} 
                onHide={onHide} 
                showEstadoDecision={false}
                fileExists={fileExists}
                elemento={elemento}
                idElemento={idElemento}
            />
    );
};

export { AdjuntosDecisiones, AdjuntosEvaluaciones };
