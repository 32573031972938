import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import CheckRole from "../CheckRole";
import AADService from "../funcionesAuth.js";
import CircularProgress from "@mui/material/CircularProgress";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions";
import { useQuery } from "react-query";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ActionPlanTable from "./ActionPlanTable/ActionPlanTable";

function PlanesAccion(params) {
  const [openNoDataInActionPlan, setOpenNoDataInActionPlan] = useState(true);
  const [planaccion, setPlanAccion] = useState([]);
  const [loading, setLoading] = useState(false);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const check = new CheckRole("/planes-de-accion");
  const idEvaluacion = localStorage.getItem("idevaluacion");

  const getActionPlanData = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL + "planesdeAccion/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const data = await result.json();
    return data;
  };

  const getActionPlanDataByEvaluation = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "plan_accion_por_evaluacion/" +
        idEvaluacion +
        "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const data = await result.json();
    return data;
  };

  const fetchdata_PA = async (rol) => {
    setLoading(true);
    const resultUsuario = await fetch(
      process.env.REACT_APP_API_URL +
        "usuariosrol/" +
        localStorage.getItem("idcompania") +
        "/" +
        rol,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const dataUsuario = await resultUsuario.json();
    filteringActionsPlans(rol, dataUsuario);
    setLoading(false);
  };

  const fetchUsuario = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "usuarios/menu/" +
        localStorage.getItem("usuario") +
        "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const encryptedUser = await result.json();
    const decryptedUser = DecryptData(encryptedUser);
    const usuario = JSON.parse(decryptedUser);
    return usuario;
  };

  const { data: actionPlanData, refetch: actionPlanDataFetch } = useQuery(
    ["actionPlanData"],
    () => getActionPlanData(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { data: actionPlanDataByEv, refetch: actionPlanDataByEvFetch } =
    useQuery(["actionPlanDataByEv"], () => getActionPlanDataByEvaluation(), {
      refetchOnWindowFocus: false,
      enabled: false,
    });

  const filteringActionsPlans = (rol, dataUsuario) => {
    let planesaccion = [];
    if (actionPlanData && actionPlanData.length > 0) {
      if (rol === 1 || rol === 2 || rol === 7) {
        setPlanAccion(actionPlanData);
      } else {
        var posicionUsuario = 0;
        for (let i = 0; i < dataUsuario.length; i++) {
          if (dataUsuario[i].email === localStorage.getItem("usuario")) {
            posicionUsuario = dataUsuario[i].idposicion;
            break;
          }
        }
        for (let i = 0; i < actionPlanData.length; i++) {
          if (actionPlanData[i].idposicionresponsablepa === posicionUsuario) {
            planesaccion.push(actionPlanData[i]);
          }
        }
        setPlanAccion(planesaccion);
      }
    }
  };

  const { data: usuario } = useQuery(["usuario"], () => fetchUsuario());

  const opcionesGenerales =
    usuario &&
    usuario.map((usuario) => {
      const permisos = usuario.permisos.split(";");
      return { opcion: usuario.url_opcion, permisos: permisos };
    });

  const checkValidity = () => {
    const validity =
      opcionesGenerales && check.checkPermits(opcionesGenerales, "GET");

    if (validity && usuario && usuario.length > 0) {
      fetchdata_PA(usuario[0].idrol);
    }
  };

  const isInEvaluations = idEvaluacion && idEvaluacion != 0 ? true : false;

  useEffect(() => {
    if (isInEvaluations) {
      actionPlanDataByEvFetch();
    } else {
      actionPlanDataFetch();
    }
  }, [isInEvaluations]);

  useEffect(() => {
    checkValidity();
  }, [usuario, actionPlanData]);

  return (
    <>
      {isInEvaluations &&
        actionPlanDataByEv &&
        actionPlanDataByEv.length > 0 &&
        !loading && (
          <ActionPlanTable
            data={actionPlanDataByEv}
            isInEvaluations={isInEvaluations}
            opcionesGenerales={opcionesGenerales}
            check={check}
          />
        )}
      {isInEvaluations === false &&
        planaccion &&
        planaccion.length > 0 &&
        !loading && (
          <ActionPlanTable
            data={planaccion}
            isInEvaluations={isInEvaluations}
            opcionesGenerales={opcionesGenerales}
            check={check}
          />
        )}
      {loading && (
        <Box>
          <Box display='flex' justifyContent='center'>
            <CircularProgress size={80} color='inherit' />
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            marginTop='10px'
            style={{ fontSize: "20px", fontWeight: "500" }}
          >
            {"Cargando ...."}
          </Box>
        </Box>
      )}

      {isInEvaluations &&
        actionPlanDataByEv &&
        actionPlanDataByEv.length === 0 && (
          <Dialog open={openNoDataInActionPlan} sx={{ borderRadius: "10px" }}>
            <DialogTitle>No Existen Planes de Accion</DialogTitle>
            <DialogContent>
              <DialogContentText>
                No Existen Planes de Accion asociados a esta evaluacion
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                style={{
                  backgroundColor: "#00c389",
                  color: "#fff",
                  width: "100px",
                  borderRadius: "10px",
                  height: "35px",
                }}
                onClick={() =>
                  setOpenNoDataInActionPlan(!openNoDataInActionPlan)
                }
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
    </>
  );
}

export default PlanesAccion;
