import React, { useEffect, useRef, useState } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import TableContainer from "@material-ui/core/TableContainer";
import { Table, TableHead, TableBody, TableRow, TableCell, Paper, Tooltip, List, ListItem, ListItemText} from "@material-ui/core";
import { HelpOutline } from '@material-ui/icons';
import {Row, Col, Button, Alert} from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import AADService from "../funcionesAuth.js";


function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Para el análisis de brechas no hay controles no implementados para mostrar o no son adherentes a ninguna amenaza
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}


  const useStyles = makeStyles({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },   
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "White",
    },
    
  });

function ResumenEfectividad() {
    const AzureADService = new AADService();
    const token = AzureADService.getToken();
    const [dataRiesgo, setDataRiesgos] = useState("");
    const [dataBrechas, setDataBrechas] = useState([]);
    const [flatTable, setFlatTable] = useState(false);
    const [ValueIdRiesgo, setValueIdRiesgo] = useState("");
    const [dataResumenRiesgo, setDataResumenRiesgo] = useState([]);
    const [dataHistoricoEfectividad, setDataHistoricoEfectividad] = useState([]);
    const [columnasCapas, setcolumnasCapas] = useState([]);
    const [columnas, setcolumnas] = useState([]);
    const [amenazas, setamenazas] = useState([]);
    const FirstChangeRiesgoRef = useRef(false);
    const classes = useStyles();
    const [estadoGET, setEstadoGET] = React.useState(0);

    useEffect(() => {
      const fetchRiesgo = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/riesgos/" +
            localStorage.getItem("idevaluacion") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let dataFetchRiesgo = await result.json();
        const dataRiesgomap = dataFetchRiesgo.map(item => ({
          value: item.idriesgo,
          label: "ID Riesgo: " +item.idriesgo + " " +item.evento,
        }));
        setDataRiesgos(dataRiesgomap);
      }
      fetchRiesgo();

      const fetchBrechas = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/brechas/" +
            localStorage.getItem("idevaluacion") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (result.status === 200) {
          let dataFetchBrechas = await result.json();
          const dataBrechasmap = dataFetchBrechas.map(item => ({
            idimplementador: item.idimplementador,
            nombre: item.nombre,
            efectividad: item.efectividad,
            nombres_amenazas: item.nombres_amenazas.map(nombre => ({
              nombre: nombre
            })),
            idriesgo_nombre: item.idriesgo_nombre.map(idriesgo_nombre => ({
              idriesgo_evento: idriesgo_nombre
            })),
            capa_control: item.capa_control.split(","),
            suma_pesos: item.suma_pesos,
            puntaje: item.puntaje,
          }));
          setDataBrechas(dataBrechasmap);
        }
        else if (result.status === 404) {
          setEstadoGET(6);
          return null;
        }
        else {
          setEstadoGET(5);
          return null;
        }
      }
      fetchBrechas();
    },[]);

    useEffect(() => {
      if (FirstChangeRiesgoRef.current) {
        const fetchDataRiesgo = async () => {
          try {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "evaluacion/controlesNew/resumenefectividad/" +
                localStorage.getItem("idevaluacion") +
                "/" + ValueIdRiesgo + "/",
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );   
            const datafetchDataRiesgo = await result.json();   
            const amenazasOb = Object.keys(datafetchDataRiesgo[0])
            .filter(key => key !== 'efectividad_riesgo')
            .filter(key => key !== 'historico_efectividad');   
            const columnasCapasOb = Array.from(
              new Set(
                datafetchDataRiesgo.flatMap(obj =>
                  Object.keys(obj)
                    .filter(key => key !== 'efectividad_riesgo')
                    .filter(key => key !== 'historico_efectividad')
                    .flatMap(key => Object.keys(obj[key]).filter(subKey => subKey !== 'efectividad_amenaza'))
                )
              )
            );   
            const columnas = ['Amenaza', ...columnasCapasOb, 'Efectividad Amenaza'];
            const historicoEfectividad = datafetchDataRiesgo[2].historico_efectividad;
            setcolumnasCapas(columnasCapasOb);
            setcolumnas(columnas);
            setamenazas(amenazasOb);
            setDataResumenRiesgo(datafetchDataRiesgo);
            setDataHistoricoEfectividad(historicoEfectividad);
    
          } catch (error) {
            console.error('Error al obtener datos:', error);
          }
        };    
        fetchDataRiesgo();
      } else {
        FirstChangeRiesgoRef.current = true;
      }
    }, [ValueIdRiesgo]);
  
    const handleOnchangeRiesgo  =  val  => { 
      setFlatTable(true);
      setValueIdRiesgo(val.value)
    }; 

    const renderCapaControlTooltip = () => (
      <Tooltip title={
        <React.Fragment>
          <List>
            <ListItem>
              <ListItemText primary="Peso de cada capa:" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Monitoreo y Respuesta: 10.9%" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seguridad perimetral: 21.4%" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seguridad de la red: 18.9%" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seguridad de Endpoints: 18.3%" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seguridad de aplicaciones: 18.8%" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seguridad de datos: 11.7%" />
            </ListItem>
          </List>
        </React.Fragment>
      }>
        <HelpOutline />
      </Tooltip>
    );

    const renderPuntajeTooltip = () => (
      <Tooltip 
        title={
          <React.Fragment>
          <List>
            <ListItem>
              <ListItemText primary="Puntaje calculado en base a las amenazas que mitiga, los riesgos que aplica, la suma de pesos de capas y el diseño del control." />
            </ListItem>
          </List>
        </React.Fragment>
      }>
        <HelpOutline />
      </Tooltip>
    );

    return (
      <>
        <AlertDismissibleExample alerta={estadoGET} />
        <div className={classes.root}>
            <div style={{ marginBottom: '30px', marginTop:'30' }}>
              <h1 className='titulo' style={{ marginBottom: '30px' }}>Resumen de efectividad de controles</h1>
              <Row>
                <Col sm={2} xs={12} style={{ marginRight: '10px' }} >
                    <label  className="label forn-label">Análisis de brechas</label>
                  </Col>
              </Row> 
              <Row>
                <Col sm={12} xs={12} style={{ marginRight: '10px' }} >
                  <p>Ranking de los controles no implementados que más le aportarían a mejorar la efectividad</p>
                </Col>
              </Row> 
              <hr></hr>
              <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                  <Table
                    stickyHeader
                    className={classes.table}
                    aria-labelledby='tableTitle'
                    size={"medium"}
                    aria-label='enhanced table'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.head}>ID Control</TableCell>
                        <TableCell className={classes.head}>Nombre</TableCell>
                        <TableCell className={classes.head}>Amenazas que mitiga</TableCell>
                        <TableCell className={classes.head}>Riesgos que aplica</TableCell>
                        <TableCell className={classes.head}>
                          Capas que protege 
                          {renderCapaControlTooltip()}
                        </TableCell>
                        <TableCell className={classes.head}>Suma de Pesos</TableCell>
                        <TableCell className={classes.head}>Diseño control</TableCell>
                        <TableCell className={classes.head}>
                          Puntaje 
                          {renderPuntajeTooltip()}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataBrechas.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.idimplementador}</TableCell>
                          <TableCell>{row.nombre}</TableCell>
                          <TableCell>
                            <ul>
                              {row.nombres_amenazas.map((nombre, i) => (
                                <li key={i}>{nombre.nombre}</li>
                              ))}
                            </ul>
                          </TableCell>
                          <TableCell>
                            <ul>
                              {row.idriesgo_nombre.map((idriesgo_nombre, i) => (
                                <li key={i}>{idriesgo_nombre.idriesgo_evento}</li>
                              ))}
                            </ul>
                          </TableCell>
                          <TableCell>
                            <ul>
                              {row.capa_control.map((capa, i) => (
                                <li key={i}>{capa}</li>
                              ))}
                            </ul>
                          </TableCell>
                          <TableCell>{row.suma_pesos}</TableCell>
                          <TableCell>{row.efectividad}</TableCell>
                          <TableCell>{parseFloat(row.puntaje).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>   
              <hr></hr>
              <Row>
                <Col sm={12} xs={12} style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center' }} >
                  <Link to="escenarios-mitigacion">
                    <Button
                      className='botonGeneral'
                    >
                      Escenarios de mitigación
                    </Button>
                  </Link>
                </Col>
              </Row> 
              <Row>
                <Col sm={2} xs={12} style={{ marginRight: '10px' }} >
                  <label  className="label forn-label">Riesgo</label>
                </Col>
                <Col sm={6} xs={12}>
                  <Select 
                  style={{ width: '100%' }}
                  placeholder='Seleccione el riesgo'
                  onChange={handleOnchangeRiesgo}
                  options={dataRiesgo}
                  id= 'riesgoAnalizar'         
                  />
                </Col>
              </Row>   
            </div>               
             {(flatTable === true) && <div>    
              <Paper variant='outlined' className={classes.paper} style={{ maxWidth: "100%", overflowX: "auto" }}>
                <TableContainer style={{ maxHeight: "54vh" }}>
                  <Table className={classes.table} 
                    aria-label="enhanced table"
                    aria-labelledby='tableTitle'
                    size='medium'
                    stickyheader 
                    style={{position:"relative"}}>
                    <TableHead style={{top:"0", position:"sticky"}}>
                        <TableRow>
                        {columnas.map((column, index) => (
                            <TableCell className={classes.head} key={index}>{column}</TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {amenazas.map((amenaza, index) => (
                      amenaza !== "efectividad_riesgo" ? (
                        <TableRow key={index}>
                          <TableCell>{amenaza}</TableCell>
                          {columnasCapas.map((capa, i) => {
                            const capaData = dataResumenRiesgo?.[0]?.[amenaza]?.[capa];
                            return (
                              <TableCell key={i}>
                                {capaData && capaData.controles_no_implementados !== undefined ? (
                                  <div>
                                    <p>Controles no implementados: {capaData.controles_no_implementados}</p>
                                    <ul>
                                      {capaData.nombres_no_imp.map((nombre, j) => (
                                        <li key={j}>{nombre}</li>
                                      ))}
                                    </ul>
                                  </div>
                                ) : "No Aplica"}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            {dataResumenRiesgo?.[0]?.[amenaza]?.efectividad_amenaza ?? ''}
                          </TableCell>
                        </TableRow>
                      ) : null
                    ))}
                    </TableBody>
                  </Table>
                </TableContainer>      
              </Paper>
               <div style={{ marginTop: '30px' }}>
                <h3 className='subtitulo'>
                  Efectividad Actual: {dataResumenRiesgo?.[1]?.efectividad_riesgo?.[0]?.valor ?? ''}
                </h3>
              </div> 
            <div>
              {/*Tabla de Efectidad Histórica*/}
              <hr />
              <Row>
                <Col sm={2} xs={12} style={{ marginRight: '10px' }} >
                  <label  className="label forn-label">Histórico de efectividad</label>
                </Col>
              </Row>   
              <Paper variant='outlined' className={classes.paper} style={{ maxWidth: "50%", overflowX: "auto" }}>
                <TableContainer style={{ maxHeight: "54vh" }}>
                  <Table className={classes.table} 
                    aria-label="enhanced table"
                    aria-labelledby='tableTitle'
                    size='medium'
                    stickyheader 
                    style={{position:"relative"}}>
                    <TableHead style={{top:"0", position:"sticky"}}>
                      <TableRow>
                        <TableCell className={classes.head}>Efectividad Control</TableCell>
                        <TableCell className={classes.head}>Estado ERO</TableCell>
                        <TableCell className={classes.head}>Validador ERO</TableCell>
                        <TableCell className={classes.head}>Fecha</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataHistoricoEfectividad.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.efectividad}</TableCell>
                          <TableCell>{row.estado_flujo}</TableCell>
                          <TableCell>{"Responsable de" + row.validadorero}</TableCell>
                          <TableCell>{row.fechacreacion}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                  </Table>
                </TableContainer>
              </Paper>
              </div> 
            </div>
            }
        </div>  
      </>     
    );
}           
export default ResumenEfectividad;