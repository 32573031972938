import React, { useState } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Alert } from 'react-bootstrap';
import AADService from "../funcionesAuth.js";
import { useHistory } from "react-router-dom";

function AlertDismissibleExample({ alerta }) {
    switch (alerta) {
      case 1:
        return <Alert variant='warning'>Alerta</Alert>;
        break;
      case 2:
        return <Alert variant='success'>Guardó exitosamente</Alert>;
        break;
      case 3:
        return <Alert variant='danger'></Alert>;
        break;
      case 4:
        return <Alert variant='warning'>Error al enviar la información</Alert>;
        break;
      case 5:
        return <Alert variant='danger'>Error en el servidor</Alert>;
        break;
      case 6:
        return (
          <Alert variant='warning'>
            Ya existe una evaluación para el activo seleccionado
          </Alert>
        );
        break;
      default:
        return <p></p>;
        break;
    }
  }

const ModalAdjuntos = ({ show, onHide, showEstadoDecision, fileExists, elemento, idElemento, setValidatedData}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [estadoDecision, setEstadoDecision] = useState(null);
    const [estadoPOST, setEstadoPost] = useState(0);
    const history = useHistory();
    const AzureADService = new AADService();
    const token = AzureADService.getToken();

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const fetchCambioEstado = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}evaluacion/cambioestadoflujo/${idElemento}/`;
            const response = await fetch(url, {
                method: 'PUT',
                body: JSON.stringify({ estado_flujo: 'Aprobada'}),
                headers: {
                    'Authorization': "Bearer " + token,
                    'Content-Type': 'application/json'
                },
            });

            if (response.ok) {
                setEstadoPost(2);
                alert("Evidencia subida exitosamente")
                history.push('/evaluaciones');
            } else {
                console.error('Fetch failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const fetchUsuarioEvidencia = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}EditarDecisiones/${idElemento}/`;
            const response = await fetch(url, {
                method: 'PUT',
                body: JSON.stringify({ 
                    disp_numerico1: localStorage.getItem('idusuario'),
                    decision: estadoDecision.label,
                }),
                headers: {
                    'Authorization': "Bearer " + token,
                    'Content-Type': 'application/json'
                },
            });

            if (response.ok) {
                setEstadoPost(2);  
                setValidatedData(true);  
            } else {
                console.error('Fetch failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCloseButton = () => {
        onHide();
        setSelectedFile(null);
    };

    const allowedExtensions = ['.pdf', '.docx', '.xlsx', '.jpg', '.png', '.msg'];

    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Seleccione un archivo por favor');
            return;
        }
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes('.' + fileExtension)) {
            alert('El tipo de archivo no está permitido. Por favor seleccione un archivo con alguna de las siguientes extensiones: ' + allowedExtensions.join(', '));
            return;
        }
        let selectedFileName = selectedFile.name;
        let searchString = elemento + "_" + idElemento + "_";
        const formData = new FormData();
        if (selectedFileName.includes(searchString)) {
            let newFileName = selectedFileName.replace(searchString, "");
            let newFile = new File([selectedFile], newFileName, {
                type: selectedFile.type,
                lastModified: selectedFile.lastModified
            });
            formData.append('file', newFile); 
        } else {
            formData.append('file', selectedFile); 
        }
        if (fileExists) {
            alert('El adjunto existente será reemplazado');
        }
        try {
            const method = fileExists ? 'PUT' : 'POST';
            
            const url = `${process.env.REACT_APP_API_URL}administrador/adjuntos/${elemento}/${idElemento}/`;
    
            const response = await fetch(url, {
                method: method,
                body: formData,
                headers: {
                    'Authorization': "Bearer " + token,
                },
            });
    
            if (response.ok) {
                onHide();
                if (elemento === 'ERO') {	
                    fetchCambioEstado();
                }
                else {  
                    fetchUsuarioEvidencia();
                }
                setSelectedFile(null);
                setEstadoDecision(null);
            } else {
                console.error('Upload/Replace failed');
                onHide();
                //Lo siguiente es solo para pruebas en local
                //if (elemento === 'ERO') {	
                //    fetchCambioEstado();
                //}
                //else {  
                //    fetchUsuarioEvidencia();
                //}
                setSelectedFile(null);
                setEstadoDecision(null);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const estadoDecisionOptions = [
        { value: 'evitar', label: 'Evitar' },
        { value: 'aceptar', label: 'Aceptar' },
        { value: 'mitigar', label: 'Mitigar' },
        { value: 'pendiente', label: 'Pendiente' }
        ];

    const handleEstadoDecisionChange = (selectedOption) => {
        setEstadoDecision(selectedOption);
    };

    return (
        <>
            <AlertDismissibleExample alerta={estadoPOST} />
            <Modal show={show} onHide={handleCloseButton} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>Adjuntar archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showEstadoDecision ? (
                        <div>
                            <p>Tener en cuenta que el estado de la decisión será cambiado al subir satisfactoriamente la evidencia</p>
                        </div>
                        ):(<div>
                            <p>Tener en cuenta que el estado de la ERO cambiará a Validada/Aprobada al subir satisfactoriamente la evidencia</p>
                        </div>
                    )}
                    <input type="file" onChange={handleFileChange} className="form-control-lg form-control-file" />
                    {showEstadoDecision && (
                        <div>
                            <label htmlFor="estadoDecision" className='label forn-label'>Estado decisión</label>
                            <Select
                                id="estadoDecision"
                                options={estadoDecisionOptions}
                                onChange={handleEstadoDecisionChange}
                                value={estadoDecision}
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        onClick={handleUpload} 
                        className='botonDescargar'
                        disabled={(estadoDecision === null && elemento !== 'ERO' ) || (estadoDecision !== null && elemento === 'ERO')}
                    >
                        {fileExists ? 'Reemplazar archivo' : 'Subir Archivo'}
                    </button>
                    <button onClick={handleCloseButton} 
                            className="btn btn-secondary">Cerrar</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalAdjuntos;