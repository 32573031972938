import React, { useEffect, useState } from 'react';
import VulnoTec from "./Vul_no_tec";
import VulnoTecNew from "./Vul_no_tec_New";

function VulnoTecPadre() {
    const [dataStorageDespliegue, setDataStorageDespliegue] = useState(null);
    const [dataStorageTipoCanal, setDataStorageTipoCanal] = useState(null); 
    useEffect(() => {
        setDataStorageDespliegue(localStorage.getItem("variable_tipo_despliegue"));
        setDataStorageTipoCanal(localStorage.getItem("variable_tipo_canal"));
      }, []);
    
    return (
        <div>
            {dataStorageDespliegue !== null && dataStorageTipoCanal !== null &&
                <VulnoTecNew/>
            }
            {/*Si dataStorageDespliegue y dataStorageTipoCanal son null reenderizaremos el componente Riesgos*/}
            {dataStorageDespliegue === null && dataStorageTipoCanal === null &&
                <VulnoTec />
            }
        </div>
    );
}
export default VulnoTecPadre;