import React, { useState } from "react";
import { Alert, Button, Row, Col } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import { IoMdDownload } from "react-icons/io";
import XLSX from "xlsx";
import AADService from "../funcionesAuth.js";

export default function AdminEfectividad() {
  const [datos, setDatos] = useState(null);
  const [loading, setLoading] = useState(false);
  const [idERO, setIdERO] = useState(0);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const handleOnExport = () => {
    const generarInfo = async () => {
      try {
        setLoading(true);
        const result = await fetch(
          `${process.env.REACT_APP_API_URL}evaluacion/efectividadadmin/${idERO}`,
          {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              Authorization: "Bearer " + token,
            },
          }
        );
        const datainfo = await result.json();
        setDatos(datainfo);
        exportToExcel(datainfo);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    generarInfo();
  };

  const exportToExcel = (data) => {
    const workbook = XLSX.utils.book_new();

    const worksheet1 = XLSX.utils.json_to_sheet(data.decarga_variables_generales);
    XLSX.utils.book_append_sheet(workbook, worksheet1, 'Decarga Variables Generales');

    const worksheet2 = XLSX.utils.json_to_sheet(data.agrupado_por_amenaza);
    XLSX.utils.book_append_sheet(workbook, worksheet2, 'Agrupado por Amenaza');

    const worksheet3 = XLSX.utils.json_to_sheet(data.conteo);
    XLSX.utils.book_append_sheet(workbook, worksheet3, 'Conteo');

    const worksheet4 = XLSX.utils.json_to_sheet(data.descarga_variables);
    XLSX.utils.book_append_sheet(workbook, worksheet4, 'Descarga Variables');

    XLSX.writeFile(workbook, 'Variables_efectividad_ERO_' + idERO + '.xlsx');
  };

  return (
    <div className='d-flex flex-column'>
      <h2 className='titulo'>Descarga especifica de variables en la efectividad</h2>
      <hr />
      <Row>
        <Col>
          <input 
            type='number'
            className='form-control'
            placeholder='Digite ERO'
            onChange={(e) => setIdERO(e.target.value)}
          />
        </Col>
        <Col>
          <div className='d-flex justify-content-center align-items-center col-12'>
            <Button className='botonPositivo' onClick={handleOnExport} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Descargar'}
              &nbsp;
              <IoMdDownload />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
