import { Box, Tabs, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useQuery } from "react-query";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
//import AADService from "./funcionesAuth.js";

const DisplayActionPlans = ({ data, comment1, value, Icon }) => {
  return (
    <>
      <div className='font-weight-bold p-0'>{comment1}</div>
      {data.map((action) => {
        return (
          <div className='col-12 mt-4 p-0'>
            <div className='row'>
              <div
                className='col d-flex justify-content-between rounded shadow'
                style={{ background: "#f1f5f7" }}
              >
                <div className='p-3 d-flex justify-content-between col-11 '>
                  <div className='flex-column p-2'>
                    <div className='font-weight-bold'>Nombre</div>
                    <div>{action.nombre_pa}</div>
                  </div>
                  <div className='flex-column p-2'>
                    <div className='font-weight-bold'>Nombre del Analista</div>
                    <div>{action.nombre_analista}</div>
                  </div>
                  <div className='flex-column p-2'>
                    <div className='font-weight-bold'>Nombre del Responsable</div>
                    <div>{action.nombre_responsable}</div>
                  </div>
                  <div className='flex-column p-2'>
                    <div className='font-weight-bold'>Fecha inicial</div>
                    <div>{action.fechainicio}</div>
                  </div>
                  <div className='flex-column p-2'>
                    <div className='font-weight-bold'>Fecha Final</div>
                    <div className='text-danger'>
                      {action.fechacompromisoactual}
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center col-1 justify-content-center'>
                  <Icon
                    sx={{
                      fontSize: "70px",
                      color: `${value === 0 ? "#fdda24" : "#dc3545"}`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const HomePage = () => {
  const [isOpenActionPlanAlert, setIsOpenActionPlanAlert] = useState(true);
  const [value, setValue] = useState(0);
  const userRol = localStorage.getItem("rolusuario");
  const userPosition = localStorage.getItem("idposicion");
  //const AzureADService = new AADService();
  //const token = AzureADService.getToken();
  const getActionPlanData = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + 
      "homepage/" + 
      userPosition + "/",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        //Authorization: "Bearer " + token,
      },
    });
    if (response.status >= 200 && response.status <= 300) {
      const result = await response.json();
      return result;
    } else if (response.status >= 400 && response.status <= 500) {
      return response.status;
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const {
    data: actionPlan,
    isLoading,
    error,
  } = useQuery(["actionPlans"], () => {
    if (parseInt(userRol) === 1 || parseInt(userRol) === 2 || parseInt(userRol) === 3 || parseInt(userRol) === 4 || parseInt(userRol) === 5) {
      return getActionPlanData(); 
    }
  });

  const arrActionPlan = actionPlan && Object.values(actionPlan);

  const dateInPast = (firstDate, secondDate) => {
    if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
      return true;
    }
    return false;
  };

  const expiredActionPlans =
    arrActionPlan &&
    arrActionPlan.filter((actionPlan) => {
      const past = new Date(actionPlan.fechacompromisoactual);
      const today = new Date();
      const isExpired = dateInPast(past, today);
      return isExpired;
    });

  useEffect(() => {
    setIsOpenActionPlanAlert(true);
  }, []);
  return (
    <div>
      <div
        className='pl-3 mt-4 font-weight-bold text-capitalize'
        style={{ fontSize: "36px" }}
      >
        Bienvenidos
      </div>

      {arrActionPlan &&
        arrActionPlan.length > 0 &&
        isOpenActionPlanAlert &&
        !isLoading &&
        (parseInt(userRol) === 1) | (parseInt(userRol) === 2) | (parseInt(userRol) === 3) | (parseInt(userRol) === 4 | (parseInt(userRol) === 5)) && (
          <div className='loading-mask justify-content-center'>
            <div
              className='bg-light fade-in one p-4 rounded'
              style={{
                width: "1200px",
                height: "600px",
                maxWidth: "100%",
                overflowY: "auto",
              }}
            >
              <div className='col-12 d-flex'>
                <div
                  className='text-danger col-6 d-flex align-items-center'
                  style={{ fontSize: "36px", fontWeight: "500" }}
                >
                  Atencion !
                </div>
                <div className='col-6 d-flex justify-content-end align-items-center'>
                  <div
                    className='col-1'
                    onClick={() => setIsOpenActionPlanAlert(false)}
                  >
                    <CloseIcon size={30} />
                  </div>
                </div>
              </div>
              <div className='col-12 mt-2'>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='basic tabs example'
                  >
                    <Tab label='Pendientes' />
                    {expiredActionPlans && expiredActionPlans.length > 0 && (
                      <Tab label='Vencidas' />
                    )}
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <DisplayActionPlans
                    data={arrActionPlan}
                    comment1='Los siguientes planes de accion finalizaran en los proximos 8
                  dias:'
                    value={value}
                    Icon={ErrorOutlineIcon}
                  />
                </TabPanel>

                {expiredActionPlans && expiredActionPlans.length > 0 && (
                  <TabPanel value={value} index={1}>
                    <DisplayActionPlans
                      data={expiredActionPlans}
                      comment1='Los siguientes planes de accion estan vencidos:'
                      value={value}
                      Icon={WarningAmberIcon}
                    />
                  </TabPanel>
                )}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default HomePage;
