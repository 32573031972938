import { CircularProgress } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { fetchRolesData } from "../utilities/request";
import EditTableRolView from "../EditTableRolView/EditTableRolView";
import NewRolView from "../NewRolView/NewRolView";
import Paper from "@mui/material/Paper";
import { forwardRef } from "react";
import { ChevronLeft, ChevronRight, Search } from "react-bootstrap-icons";
import {
  ArrowDownward,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  ViewColumn,
} from "@material-ui/icons";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button } from "@mui/material";

const MTableRolView = () => {
  const columns = [
    {
      field: "idrol",
      title: "Id Rol",
    },
    {
      field: "nombre_rol",
      title: "Nombre Rol",
    },
    {
      field: "descripcion_rol",
      title: "Descripcion",
    },
  ];

  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      //margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  const tableIcons = {
    //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });

  const [selected, setSelected] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [buttonEdit, setButtonEdit] = useState(false);
  const [isEditRole, setIsEditRole] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(null);
  const [isNewRole, setIsNewRole] = useState(false);
  const [monitoringRequest, setMonitoringRequest] = useState(false);
  const classes = useStyles();
  //const AzureADService = new AADService();
  //const token = AzureADService.getToken();
  /* const check = new CheckRole("/dominio-de-informacion"); */
  const { data: rolesData, isLoading } = useQuery(
    ["Roles", monitoringRequest],
    () => fetchRolesData()
  );

  const handleClickRow = (id, selectedRow) => {
    setRoleToEdit(selectedRow);
    setSelected(selected.includes(id) ? [] : [id]);
    setButtonEdit(!selected.includes(id));
  };

  return (
    <Box>
      {!isEditRole && !isNewRole && (
        <>
          <Box display='flex' justifyContent='space-between'>
            <Box style={{ fontSize: "40px", fontWeight: "600" }}>Roles</Box>
            <Box display='flex' alignItems='center'>
              {buttonEdit && (
                <Box paddingRight={3}>
                  <Button
                    style={{
                      backgroundColor: "#a9a9a9",
                      color: "#fff",
                      width: "100px",
                      borderRadius: "5px",
                      height: "40px",
                    }}
                    onClick={() => setIsEditRole(true)}
                  >
                    Editar
                  </Button>
                </Box>
              )}

              <Box>
                <Button
                  style={{
                    backgroundColor: "#00c389",
                    color: "#fff",
                    width: "100px",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={() => setIsNewRole(true)}
                >
                  Nuevo
                </Button>
              </Box>
            </Box>
          </Box>

          <Box marginTop={2}>
            {rolesData && (
              <Paper variant='outlined' className={classes.paper}>
                <MuiThemeProvider theme={theme}>
                  <MaterialTable
                    columns={columns}
                    data={rolesData}
                    icons={tableIcons}
                    options={{
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          buttonEdit === true &&
                          selectedRow === rowData.tableData.id
                            ? "#fff8d3"
                            : "#FFF",
                      }),
                      showTitle: false,

                      searchFieldAlignment: "left",
                      searchFieldStyle: {}, //Llenar estilo de barra de busqueda
                      headerStyle: {
                        backgroundColor: "#2c2a29",
                        color: "#ffffff",
                        position: "sticky",
                        top: 0,
                        fontFamily: "CIBFont Sans Regular",
                        fontWeight: 400,
                        fontSize: "1.2rem",
                        lineHeight: 1.6,
                        letterSpacing: "0.0075em",
                        flex: "1 1 100%",
                      },
                      maxBodyHeight: "650px",
                      selection: false,
                      disableMultipleSelection: true,
                      pageSizeOptions: [10, 20, 30],
                      showSelectAllCheckbox: false,
                      showTextRowsSelected: false,
                      pageSize: 10,
                    }}
                    onRowClick={(evt, selectedRow) => {
                      handleClickRow(selectedRow.idactivo, selectedRow);
                      return setSelectedRow(selectedRow.tableData.id);
                    }}
                    localization={{
                      toolbar: {
                        searchPlaceholder: "Buscar",
                      },
                      body: {
                        emptyDataSourceMessage: "No se encontraron registros",
                      },
                      pagination: {
                        labelRowsSelect: "Filas",
                        firstTooltip: "Primera página",
                        previousTooltip: "Anterior",
                        nextTooltip: "Siguiente",
                        lastTooltip: "Última página",
                      },
                    }}
                  />
                </MuiThemeProvider>
              </Paper>
            )}
            {isLoading && (
              <Box>
                <Box display='flex' justifyContent='center'>
                  <CircularProgress size={80} color='inherit' />
                </Box>
                <Box
                  display='flex'
                  justifyContent='center'
                  marginTop='10px'
                  style={{ fontSize: "20px", fontWeight: "500" }}
                >
                  {"Cargando ...."}
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
      {isEditRole && roleToEdit && (
        <EditTableRolView
          setIsEditRole={setIsEditRole}
          roleToEdit={roleToEdit}
          setRoleToEdit={setRoleToEdit}
          setButtonEdit={setButtonEdit}
          setMonitoringRequest={setMonitoringRequest}
          monitoringRequest={monitoringRequest}
        />
      )}
      {isNewRole && (
        <NewRolView
          setIsNewRole={setIsNewRole}
          setMonitoringRequest={setMonitoringRequest}
          monitoringRequest={monitoringRequest}
        />
      )}
    </Box>
  );
};

export default MTableRolView;
