export const fetchRolesData = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_API_URL + "roles/1/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        /*Authorization: "Bearer " + this.token,*/
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getOptionRolData = async (rol) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `roles/opcion/${rol}/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          /*Authorization: "Bearer " + this.token,*/
        },
      }
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getOptionTableData = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_API_URL + `rolesId/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        /*Authorization: "Bearer " + this.token,*/
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};
