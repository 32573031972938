import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";
import decode from 'jwt-decode';
export const adalConfig = {
  tenant: "0cfe6dd5-3866-4d37-adb5-faf8761a1e4d",
  clientId: "57838bfa-bfb3-4519-950d-8596fe7c466a",
  endpoints: {    
    api:'57838bfa-bfb3-4519-950d-8596fe7c466a'  
  },  
  'apiUrl': 'https://grc-rotic-alb-qa.apps.bancolombia.com',
  cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);
export const obtainTimeLeft = () => {
  const token = getToken();
  if (!token) {
    console.log("No hay token");
    return;
  }
  const tokenDecoded = decode(token);
  //Calcular el tiempo restante en minutos
  const timeLeft = (tokenDecoded.exp - Date.now() / 1000) / 60;
  //Guardar timeLeft en localStorage
  localStorage.setItem("timeLeft", timeLeft);

  return timeLeft;
  //Ver el tiempo restante del token en la consola, pero en minutos, es decir, pasar de UNIX a minutos
};
//Llamar la función obtainTimeLeft() para obtener el tiempo restante del token
obtainTimeLeft();