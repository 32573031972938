import React, { useEffect, useState } from 'react';
import ControlesEva from "./ControlesEva.js";
import ControlesEvaNew from "./ControlesEvaNew.js";

    function ControlesEvaluacionPadre() {
    // Estado para controlar la inclusión del componente basado en los datos recuperados.
    const [incluirRiesgoNew, setIncluirRiesgoNew] = useState("");

    useEffect(() => {
        const tipoDespliegue = localStorage.getItem("variable_tipo_despliegue");
        const tipoCanal = localStorage.getItem("variable_tipo_canal");
        
        if (tipoDespliegue !== null && tipoCanal !== null) {
            setIncluirRiesgoNew("Nuevo");
        }
        else if (tipoDespliegue === null && tipoCanal === null) {
            setIncluirRiesgoNew("Viejo");
        }
    }, []);


    return (
        <div>
                {/* Si incluirRiesgoNew es vacío no ejecutar nada */}
            {incluirRiesgoNew === "Nuevo" ? (
                <ControlesEvaNew shouldExecuteEffect={true} />
            )
            : incluirRiesgoNew === "Viejo" ? (
                <ControlesEva shouldExecuteEffect={false} />
            )
            : (
                <div></div>
            )}
        </div>
    );
}
export default ControlesEvaluacionPadre;
