import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";

function AlertDismissibleExample({ alerta }) {
    switch (alerta) {
        case 1:
            return <Alert variant="warning">Alerta</Alert>;
            break;
        case 2:
            return <Alert variant="success">Guardó exitosamente la informacióm del control</Alert>;
            break;
        case 3:
            return <Alert variant="danger"></Alert>;
            break;
        case 4:
            return <Alert variant="warning">Error al enviar la información del control</Alert>;
            break;
        case 5:
            return <Alert variant="danger">Error en el servidor</Alert>;
            break;
        default:
            return <p></p>;
            break;
    }
}

const opcion_categoria_control = [
    { id: "1", nombre: "Controles generales de ciber seguridad" },
    { id: "2", nombre: "Controles específicos sobres los componentes tecnológicos" },
    { id: "3", nombre: "Controles específicos sobre los datos" },
];
const opcion_tipo_control = [
    { id: "1", nombre: "Correctivos" },
    { id: "2", nombre: "Detectivos" },
    { id: "3", nombre: "Preventivos" },
];
const opcion_capa_control = [
    { id: "1", nombre: "Gobierno de seguridad y conciencia del personal" },
    { id: "2", nombre: "Aplicaciones y sistemas de información" },
    { id: "3", nombre: "Perímetro y red de datos" },
    { id: "4", nombre: "Archivos y datos" },
    { id: "5", nombre: "Plataformas tecnológicas" },
    { id: "6", nombre: "Respuesta a los eventos de seguridad" },
    { id: "7", nombre: "Almacenamiento" },
    { id: "8", nombre: "Acceso físico a las instalaciones de procesamiento" },
];
const opcion_framework_nist_control = [
    { id: "1", nombre: "Proteger" },
    { id: "2", nombre: "Identificar" },
    { id: "3", nombre: "Detectar" },
    { id: "4", nombre: "Responder" },
];
const opcion_habilitador_control = [
    { id: "1", nombre: "Control embebido en el componente" },
    { id: "2", nombre: "Proceso/Actividad manual" },
    { id: "3", nombre: "Proceso/Tecnología de control" },
];

const NuevoControl = (props) => {
    const [estadoPOST, setEstadoPost] = React.useState(0);
    const [validated, setValidated] = useState(false);
    const [checkedEstado, setCheckedEstado] = useState(true);
    const [stateEstado, setStateEstado] = useState("Activo");
    const [checkedImplementador, setCheckedImplementador] = useState(true);
    const [stateImplementador, setStateImplementador] = useState("Si");
    const [checkedTransversal, setCheckedTransversal] = useState(true);
    const [stateTransversal, setStateTransversal] = useState("Si");
    const [checkedControlProveedor, setCheckedControlProveedor] = useState(true);
    const [stateControlProveedor, setStateControlProveedor] = useState("Si");
    // const [categoriaControl, setCategoriaControl] = useState(null);
    // const [TipoControl, setTipoControl] = useState(null);
    // const [capaControl, setCapaControl] = useState(null);
    const [frameworkNistControl, setFrameworkNistControl] = useState(null);
    const [habilitadorControl, setHabilitadorControl] = useState(null);
    let history = useHistory();

    const handleChangeStateEstado = (event) => {
        setCheckedEstado(event.target.checked)
        if (stateEstado == "Activo") {
            setStateEstado("Inactivo");
        } else {
            setStateEstado("Activo");
        }

    };
    const handleChangeStateImplementador = (event) => {
        setCheckedImplementador(event.target.checked)
        if (stateImplementador == "Si") {
            setStateImplementador("No");
        } else {
            setStateImplementador("Si");
        }

    };
    const handleChangeStateTransversal = (event) => {
        setCheckedTransversal(event.target.checked)
        if (stateTransversal == "Si") {
            setStateTransversal("No");
        } else {
            setStateTransversal("Si");
        }

    };
    const handleChangeStateControlProveedor = (event) => {
        setCheckedControlProveedor(event.target.checked)
        if (stateControlProveedor == "Si") {
            setStateControlProveedor("No");
        } else {
            setStateControlProveedor("Si");
        }

    };

    // const onChangeCategoriaControl = (value) => {
    //     setCategoriaControl(value.nombre);
    // };
    // const onChangeTipoControl = (value) => {
    //     setTipoControl(value.nombre);
    // };
    // const onChangeCapaControl = (value) => {
    //     setCapaControl(value.nombre);
    // };
    const onChangeFrameworkNistControl = (value) => {
        setFrameworkNistControl(value.nombre);
    };
    const onChangeHabilitadorControl = (value) => {
        setHabilitadorControl(value.nombre);
    };


    const stylesSelect = {
        control: (base) => ({
            ...base,
            fontFamily: "Roboto",
        }),
        menu: (base) => ({
            ...base,
            fontFamily: "CIBFont Sans Regular",
        }),

        option: (provided, state) => ({
            ...provided,
            //color: state.isFocused ? 'white' : '#495057',
        }),
    };

    const sendData = (event) => {
        event.preventDefault();
        var data = JSON.stringify({
            idcontrol: 0,
            idcontrol_nist: document.getElementById("idcontrolNist").value,
            nombre_control: document.getElementById("NombreControl").value,
            descripcion_control: document.getElementById("Descripcion").value,
            // categoria_control: categoriaControl,
            // tipo_control: TipoControl,
            // capa_control: capaControl,
            framework_nist: frameworkNistControl,
            estado: checkedEstado,
            idfamilia: document.getElementById("idfamilia").value,
            conimplementador: checkedImplementador,
            habilitador: habilitadorControl,
            bajo: document.getElementById("Bajo") == null ? false : document.getElementById("Bajo").checked,
            moderado: document.getElementById("Moderado") == null ? false : document.getElementById("Moderado").checked,
            alto: document.getElementById("Alto") == null ? false : document.getElementById("Alto").checked,
            critico: document.getElementById("Critico") == null ? false : document.getElementById("Critico").checked,
            transversal: checkedTransversal,
            controlbcoproveedor: checkedControlProveedor,
        });  

        fetch(
            process.env.REACT_APP_API_URL +
            "maestro/controles/0/",
            {
              method: "POST",
              body: data,
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                //Authorization: "Bearer " + token,
              },
            }
          )
            .then((data) =>
              data.json().then((response) => {
                if (data.status >= 200 && data.status < 300) {
                  setEstadoPost(2);
                  localStorage.setItem("idcontrol", response.idcontrol);
                  history.push("/EditarMControl");
                } else if (data.status >= 500) {
                  setEstadoPost(5);                  
                } else if (data.status >= 400 && data.status < 500) {
                  setEstadoPost(4);
                }
              })
            )
            .catch(function (err) { });
            setValidated(true);
    }


    return (
        <>
            <AlertDismissibleExample alerta={estadoPOST} />
            <Row>
                <Col>
                    {" "}
                    <h2 className="subtitulo">Nuevo Estándar</h2>
                </Col>
            </Row>
            <hr />
            <Form
                id="formData"
                onSubmit={(e) => sendData(e)}
                noValidate
                validated={validated}
            >
                <Row className="mb-3">
                    <Col sm={2} xs={12}>
                        <label className="label forn-label">Id</label>
                    </Col>
                    <Col sm={4} xs={12}>
                        <input
                            type="text"
                            disabled
                            className="form-control text-center"
                            placeholder="ID Automático"
                            id="IDcontrol"
                        ></input>
                    </Col>
                    <Col sm={2} xs={12}>
                        <label className="label form-label">Id Estándar NIST*</label>
                    </Col>
                    <Col sm={4} xs={12}>
                        <input
                            type="text"
                            className="form-control text-center"
                            placeholder="Id Estándar NIST"
                            required
                            id="idcontrolNist"
                        ></input>
                        <Form.Control.Feedback type="invalid">
                            Por favor introduzca un id Estándar NIST.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label form-label">Nombre*</label>
                    </Col>
                    <Col sm={8} xs={12}>
                        <input
                            type="text"
                            className="form-control text-center"
                            placeholder="Nombre del Estándar"
                            required
                            id="NombreControl"
                        ></input>
                        <Form.Control.Feedback type="invalid">
                            Por favor introduzca un nombre.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label form-label">Descripción*</label>
                    </Col>
                    <Col sm={8} xs={10}>
                        <textarea
                            className="form-control text-center"
                            placeholder="Descripción del Estándar"
                            rows="3"
                            required
                            id="Descripcion"
                        ></textarea>
                        <Form.Control.Feedback type="invalid">
                            Por favor introduzca una descripción.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                {/* <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label forn-label">Categoria del NIST*</label>
                    </Col>
                    <Col sm={8} xs={12}>
                        <Select
                            placeholder={"Seleccione la Categoria del Control"}
                            getOptionLabel={(opcion_categoria_control) => opcion_categoria_control.nombre}
                            getOptionValue={(opcion_categoria_control) => opcion_categoria_control.nombre}
                            options={opcion_categoria_control}
                            onChange={onChangeCategoriaControl}
                            styles={stylesSelect}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor seleccione la categoria del NIST.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label forn-label">Tipo de Control*</label>
                    </Col>
                    <Col sm={8} xs={12}>
                        <Select
                            placeholder={"Seleccione el tipo de Control"}
                            getOptionLabel={(opcion_tipo_control) => opcion_tipo_control.nombre}
                            getOptionValue={(opcion_tipo_control) => opcion_tipo_control.nombre}
                            options={opcion_tipo_control}
                            onChange={onChangeTipoControl}
                            styles={stylesSelect}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor seleccione el tipo de control.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label forn-label">Capa de Control*</label>
                    </Col>
                    <Col sm={8} xs={12}>
                        <Select
                            placeholder={"Seleccione la Capa de Control"}
                            getOptionLabel={(opcion_capa_control) => opcion_capa_control.nombre}
                            getOptionValue={(opcion_capa_control) => opcion_capa_control.nombre}
                            options={opcion_capa_control}
                            onChange={onChangeCapaControl}
                            styles={stylesSelect}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor seleccione la Capa de control.
                        </Form.Control.Feedback>
                    </Col>
                </Row> */}
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label forn-label">Framework NIST*</label>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Select
                            placeholder={"Seleccione el framework NIST"}
                            getOptionLabel={(opcion_framework_nist_control) => opcion_framework_nist_control.nombre}
                            getOptionValue={(opcion_framework_nist_control) => opcion_framework_nist_control.nombre}
                            options={opcion_framework_nist_control}
                            onChange={onChangeFrameworkNistControl}
                            styles={stylesSelect}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor seleccione el Framework NIST.
                        </Form.Control.Feedback>
                    </Col>
                    <Col xs={2}>
                        <label className="label forn-label">Estado*</label>
                    </Col>
                    <Col sm={2} xs={12}>
                        <FormControlLabel
                            control={<Switch checked={checkedEstado}
                            />}
                            label={stateEstado}
                            onChange={handleChangeStateEstado}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label form-label">Id Familia*</label>
                    </Col>
                    <Col sm={4} xs={12}>
                        <input
                            type="text"
                            className="form-control text-center"
                            placeholder="Nombre de Familia"
                            required
                            id="idfamilia"
                        ></input>
                        <Form.Control.Feedback type="invalid">
                            Por favor introduzca un id Familia.
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm={2} xs={12}>
                        <label className="label forn-label">Tiene Implementador*</label>
                    </Col>
                    <Col sm={2} xs={12}>
                        <FormControlLabel
                            control={<Switch checked={checkedImplementador}
                            />}
                            label={stateImplementador}
                            onChange={handleChangeStateImplementador}
                        />
                    </Col>
                </Row>
                <Row style={{ padding: "15px 0px" }}>
                    <Col sm={4} xs={12}>
                        <label className="label forn-label">Habilitador*</label>
                    </Col>
                    <Col sm={4} xs={12}>
                        <Select
                            placeholder={"Seleccione el Habilitador"}
                            getOptionLabel={(opcion_habilitador_control) => opcion_habilitador_control.nombre}
                            getOptionValue={(opcion_habilitador_control) => opcion_habilitador_control.nombre}
                            options={opcion_habilitador_control}
                            onChange={onChangeHabilitadorControl}
                            styles={stylesSelect}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor seleccione el habilitador.
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm={2} xs={12}>
                        <label className="label forn-label">Transversal*</label>
                    </Col>
                    <Col sm={1} xs={12}>
                        <FormControlLabel
                            control={<Switch checked={checkedTransversal}
                            />}
                            label={stateTransversal}
                            onChange={handleChangeStateTransversal}
                        />
                    </Col>

                </Row>
                <Row className="mb-3" style={{ paddingTop: "10px" }}>

                    <Col sm={1} xs={12}>
                        <label className="label forn-label">Bajo:</label>
                    </Col>
                    <Col sm={1} xs={5}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Bajo"
                        ></input>
                    </Col>

                    <Col sm={1} xs={12}>
                        <label className="label forn-label">Moderado:</label>
                    </Col>
                    <Col sm={1} xs={5}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Moderado"
                        ></input>
                    </Col>
                    <Col sm={1} xs={12}>
                        <label className="label forn-label">Alto:</label>
                    </Col>
                    <Col sm={1} xs={5}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Alto"
                        ></input>
                    </Col>
                    <Col sm={1} xs={12}>
                        <label className="label forn-label">Critico:</label>
                    </Col>
                    <Col sm={1} xs={5}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Critico"
                        ></input>
                    </Col>
                    <Col sm={2} xs={12}>
                        <label className="label forn-label">NIST Proveedor*</label>
                    </Col>
                    <Col sm={1} xs={12}>
                        <FormControlLabel
                            control={<Switch checked={checkedControlProveedor}
                            />}
                            label={stateControlProveedor}
                            onChange={handleChangeStateControlProveedor}
                        />
                    </Col>

                </Row>

                <Row style={{ padding: "15px 0px" }}>

                </Row>

                <Row className="mb-3" style={{ padding: "25px 0px" }}>
                    <Col sm={4} xs={1}></Col>
                    <Col sm={3} xs={3}>
                        {" "}
                        <Button type="submit" className="botonPositivo" id="send">
                            Guardar
                        </Button>
                    </Col>
                    <Col sm={3} xs={3}>
                        {" "}
                        <Link to="maestro-controles">
                            <Button className="botonNegativo">Descartar</Button>
                        </Link>
                    </Col>
                </Row>
            </Form>

        </>
    )
}
export default NuevoControl;