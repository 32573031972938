import { Checkbox, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import swal from "sweetalert2";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { useQuery } from "react-query";
import { getOptionRolData, getOptionTableData } from "../utilities/request";
import { useEffect } from "react";
import { Box, Button, Input } from "@mui/material";
import { ChevronLeft, ChevronRight, Search } from "react-bootstrap-icons";
import {
  ArrowDownward,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  ViewColumn,
} from "@material-ui/icons";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const EditTableRolView = ({
  setIsEditRole,
  roleToEdit,
  setRoleToEdit,
  setButtonEdit,
  setMonitoringRequest,
  monitoringRequest,
}) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });
  const tableIcons = {
    //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    {
      field: "opcion",
      title: "Opcion",
      render: (rol) => optionName(rol),
    },
    {
      field: "crear",
      title: "Crear",
      type: "boolean",
      render: (rol) => createCheckbox(rol, "C"),
    },
    {
      field: "leer",
      title: "Leer",
      type: "boolean",
      render: (rol) => createCheckbox(rol, "R"),
    },
    {
      field: "editar",
      title: "Editar",
      render: (rol) => createCheckbox(rol, "U"),
    },
    {
      field: "borrar",
      title: "Borrar",
      render: (rol) => createCheckbox(rol, "D", tableData),
    },
    {
      field: "descargar",
      title: "Descargar",
    },
  ];

  const createCheckbox = (rol, perm, data = null) => {
    return (
      <Checkbox
        checked={rol[perm]}
        onChange={(e) =>
          handleChangePermissions(
            e,
            data
              ? data.find((permission) => permission.idopcion === rol.idopcion)
              : rol,
            perm
          )
        }
      />
    );
  };
  const [name, setName] = useState(roleToEdit.nombre_rol);
  const [description, setDescription] = useState(roleToEdit.descripcion_rol);
  const [tableData, setTableData] = useState(null);
  const { data: optionRolData, isLoading } = useQuery(["optionRol"], () =>
    getOptionRolData(roleToEdit.idrol)
  );
  const { data: optionTable } = useQuery(["optionTable"], () =>
    getOptionTableData()
  );

  const handleChangePermissions = (e, rol, key) => {
    setTableData(
      tableData.map((roles) => {
        if (roles.idopcionrol === rol.idopcionrol) {
          return { ...roles, [key]: e.target.checked };
        } else {
          return roles;
        }
      })
    );
  };

  const handleEditRolPermissions = () => {
    const data =
      tableData &&
      tableData.map((rol) => {
        const getRolPermissions = (rol) => {
          const permissions = ["C", "R", "U", "D"].filter((perm) => rol[perm]);
          return permissions.map((perm) => `${perm};`);
        };

        const modifiedRol = {
          idopcionrol: rol.idopcionrol,
          permisos: getRolPermissions(rol).join(""),
        };

        return modifiedRol;
      });

    return data;
  };

  const handleEditRol = async () => {
    const modifiedPermissions = handleEditRolPermissions();
    const data = tableData.map((row) => {
      const { permisos, idopcionrol } = modifiedPermissions.find(
        (permission) => permission.idopcionrol === row.idopcionrol
      );
      return {
        nombre_rol: name,
        descripcion_rol: description,
        idrol: roleToEdit.idrol,
        permisos,
        idopcionrol,
        iopcion: row.idopcion,
      };
    });
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `roles/${roleToEdit.idrol}/`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            //Authorization: "Bearer " + token,
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        swal.fire("Guardado exitoso!", "", "success");
        setRoleToEdit(null);
        setButtonEdit(false);
        setIsEditRole(false);
        setMonitoringRequest(!monitoringRequest);
      } else if (response.status >= 400) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      swal.fire("Error", error.message, "error");
    }
  };

  useEffect(() => {
    const modifiedOptionsRol =
      optionRolData &&
      optionRolData.map((row) => {
        const permissions = ["C", "R", "U", "D"];
        const newData = permissions.reduce((acc, permission) => {
          acc[permission] = row.permisos.includes(permission);
          return acc;
        }, {});
        return { ...row, ...newData };
      });

    setTableData(modifiedOptionsRol);
  }, [optionRolData]);

  const optionName = (rol) => {
    const option = optionTable?.find(
      ({ idopcion }) => rol.idopcion === idopcion
    );
   
    return option?.opcion;
  };

  return (
    <>
      {!isLoading && (
        <Box p={4}>
          <Box display='flex' justifyContent='space-between'>
            <Box style={{ fontSize: "40px", fontWeight: "600" }}>
              Editar Rol
            </Box>
            <Box display='flex' alignItems='center'>
              <Box paddingRight={3}>
                <Button
                  style={{
                    backgroundColor: "#ff7f41",
                    color: "#fff",
                    width: "100px",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={() => setIsEditRole(false)}
                >
                  Cancelar
                </Button>
              </Box>

              <Box>
                <Button
                  style={{
                    backgroundColor: "#00c389",
                    color: "#fff",
                    width: "100px",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={handleEditRol}
                >
                  Guardar
                </Button>
              </Box>
            </Box>
          </Box>
          <Box display='flex' justifyContent='space-between' paddingTop={3}>
            <Box display='flex'>
              <Box style={{ fontSize: "20px", fontWeight: "500" }}>Nombre</Box>
              <Box paddingLeft={3}>
                <Input
                  type='text'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  fullWidth={true}
                />
              </Box>
            </Box>
            <Box display='flex' sx={{ width: "50%" }}>
              <Box style={{ fontSize: "20px", fontWeight: "500" }}>
                Descripcion
              </Box>
              <Box paddingLeft={3}>
                <Input
                  type='text'
                  value={description}
                  sx={{ width: "250%" }}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box paddingTop={3}>
            <MuiThemeProvider theme={theme}>
              <MaterialTable
                columns={columns}
                data={tableData}
                icons={tableIcons}
                options={{
                  showTitle: false,
                  searchFieldAlignment: "left",
                  searchFieldStyle: {}, //Llenar estilo de barra de busqueda
                  headerStyle: {
                    backgroundColor: "#2c2a29",
                    color: "#ffffff",
                    position: "sticky",
                    top: 0,
                    fontFamily: "CIBFont Sans Regular",
                    fontWeight: 400,
                    fontSize: "1.2rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    flex: "1 1 100%",
                  },
                  maxBodyHeight: "650px",
                  selection: false,
                  disableMultipleSelection: true,
                  pageSizeOptions: [10, 20, 30],
                  showSelectAllCheckbox: false,
                  showTextRowsSelected: false,
                  pageSize: 10,
                  actionsColumnIndex: -1,
                }}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                  },
                  pagination: {
                    labelRowsSelect: "Filas",
                    firstTooltip: "Primera página",
                    previousTooltip: "Anterior",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Última página",
                  },
                }}
              />
            </MuiThemeProvider>
          </Box>
        </Box>
      )}
      {isLoading && (
        <Box>
          <Box display='flex' justifyContent='center'>
            <CircularProgress size={80} color='inherit' />
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            marginTop='10px'
            style={{ fontSize: "20px", fontWeight: "500" }}
          >
            {"Cargando ...."}
          </Box>
        </Box>
      )}
    </>
  );
};

export default EditTableRolView;
