
import React, { useEffect, useState, Component } from "react";
import Typography from "@mui/material/Typography";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, TextField } from '@mui/material';

//Librerias de la tabla
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, StepButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";

import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import AADService from "../funcionesAuth.js";
import { Tooltip, List, ListItem, ListItemText} from "@material-ui/core";
import { HelpOutline } from '@material-ui/icons';

function AlertDismissibleExample({ alerta }) {
    switch (alerta) {
        case 1:
            return <Alert variant="warning">Alerta</Alert>;
            break;
        case 2:
            return <Alert variant="success">Guardó exitosamente la informacióm del control</Alert>;
            break;
        case 3:
            return <Alert variant="danger"></Alert>;
            break;
        case 4:
            return <Alert variant="warning">Error al enviar la información del control</Alert>;
            break;
        case 5:
            return <Alert variant="danger">Error en el servidor</Alert>;
            break;
        case 6:
            return <Alert variant="danger">Faltan campos por llenar</Alert>;
            break;
        default:
            return <p></p>;
            break;
    }
}

const opcion_nivel_automatizacion = [
    { id: "1", nombre: "Manual" },
    { id: "2", nombre: "Semiautomático" },
    { id: "3", nombre: "Automático" },
];

/* const opcion_nivel_gestion = [
    { id: "1", nombre: "No existe" },
    { id: "2", nombre: "Inicial" },
    { id: "3", nombre: "Repetible" },
    { id: "4", nombre: "Definido" },
    { id: "5", nombre: "Administrado" },
    { id: "6", nombre: "Optimizado" },
]; */

const headCells = [
    { id: "idcontrol", numeric: false, disablePadding: false, label: "ID " },
    { id: "idcontrol_nist", numeric: false, disablePadding: false, label: "ID Estandar Nist" },
    { id: "nombre_control", numeric: false, disablePadding: false, label: "Nombre " },
];
const headCellsCompP = [
    { id: "id", numeric: false, disablePadding: false, label: "ID" },
    { id: "nombre", numeric: false, disablePadding: false, label: "Nombre" },
    {
      id: "descripcion",
      numeric: false,
      disablePadding: false,
      label: "Descripción",
    },
    {
      id: "UsuarioResponsableTI",
      numeric: false,
      disablePadding: false,
      label: "Responsable TI",
    },
    {
      id: "UsuarioResponsableNegocio",
      numeric: false,
      disablePadding: false,
      label: "Responsable Negocio",
    },
  ];

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#2c2a29",

        color: "white",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: "#f4f4f4",
    },
}))(TableRow);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const stylesSelect = {
    control: (base) => ({
        ...base,
        fontFamily: "Roboto",
    }),
    menu: (base) => ({
        ...base,
        fontFamily: "CIBFont Sans Regular",
    }),

    option: (provided, state) => ({
        ...provided,
        //color: state.isFocused ? 'white' : '#495057',
    }),
};

function EnhancedTableHead(props) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell
                    padding="checkbox"
                    style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                >
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className="label"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
function EnhancedTableHeadCompP(props) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell
                    style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                >
                </TableCell>
                {headCellsCompP.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCellsCompP.id ? order : false}
                        style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
                    >
                        <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                        className='label'
                        >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                            </span>
                        ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
EnhancedTableHeadCompP.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStylesModal = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        //marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    container: {
        maxHeight: "40vh",
        minHeight: "40vh",
    },
}));
function NuevosImplementadores() {
    const AzureADService = new AADService();
    const token = AzureADService.getToken();
    const [estadoPOST, setEstadoPost] = React.useState(0);
    const [companiaDat, setCompaniaDat] = React.useState([]);
    const [companiaDatOption, setCompaniaDatOption] = React.useState([]);
    const [companiaSelect, setCompaniaSelect] = useState(null);
    const [valuecompnusada, setvaluecompnusada] = useState([])
    const [nivelAutomatizacion, setNivelAutomatizacion] = useState([""]);
    const [nivelGestion, setNivelGestion] = useState(null);
    const [checkedEstado, setCheckedEstado] = useState(true);
    const [stateEstado, setStateEstado] = useState("Activo");
    const [checkedMapeadoControles, setCheckedMapeadoControles] = useState(true);
    const [stateMapeadoControles, setStateMapeadoControles] = useState("Si");
    const [controles, setControles] = useState([]);
    const [idrolUser, setIdrolUser] = useState(parseInt(localStorage.getItem("rolusuario")));
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowCompP, setModalShowCompP] = React.useState(false);
    const [direccion, setDireccion] = React.useState(false);
    const [controlSelected, setControlSelected] = React.useState([]);
    const [rowsP, setRowsP] = React.useState([]);
    const [validated, setValidated] = useState(false);
    let history = useHistory();
    const [categoriaControl, setCategoriaControl] = useState(null);
    const [tipoControl, setTipoControl] = useState();
    const [capaControl, setCapaControl] = useState([]);
    const [dataZeroTrust, setDataZeroTrust] = useState([]);
    const [dataDominio, setDataDominio] = useState([]);
    const [dataTipoUsuario, setDataTipoUsuario] = useState([]);
    const [datAreaResponsable, setdatAreaResponsable] = useState([]);
    const [capaControlTemp, setCapaControlTemp] = useState([]);
    const [valcategoriaControl, setvalCategoriaControl] = useState("");
    const [valTipoControl, setvalTipoControl] = useState();
    const [valcapaControl, setvalCapaControl] = useState([]); 
    const [codigoActivo, setCodigoActivo] = React.useState("");
    const [canalT, setCanalT] = React.useState("");
    const [despliegue, setvaluedesp] = useState([]);
    const [DataDespliegue,setDataDespliegue] = React.useState([]);
    const [DataCanal,setDataCanal] = React.useState([]);
    const [tipoComp2, setTipoComp2] = React.useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [modalCompo, setModalCompo] = useState([]);
    const [rowsc, setRowsC] = React.useState([]);
    const [componentesSelected, setComponentesSelected] = React.useState([]);
    const [valueNombre, setValueNombre] = React.useState([]); 
    const [valueFabricante, setValueFabricante] = React.useState(""); 
    const [valueDescripcion, setValueDescripcion] = React.useState(""); 
    const [valueZero, setValueZero] = React.useState([]);
    const [valueDominio, setValueDominio] = React.useState([]);
    const [valueTipoUsuario, setValueTipoUsuario] = React.useState([]);
    const [valueAreaResponsable, setValueAreaResponsable] = React.useState([]);
    const [valueDocumentacion, setValueDocumentacion] = React.useState("");
    const [valueEficacia, setValueEficacia] = React.useState("");
    const [errorEO, setErrorEO] = useState(false);
    const [errorPT, setErrorPT] = useState(false);
    const [listaAmenaza, setListaAmenaza] = useState([]);
    const [valAmenaza, setvalAmenaza] = useState([]);
    const [sections, setSections] = useState([]);
    const mapValCapaControl = valcapaControl.map(option => option.label); 
    const labelCapaControl = mapValCapaControl.join(',');
    const mapTipoDespliegue = despliegue.map(option => option.label); 
    const labelTipoDespliegue = mapTipoDespliegue.join(',');
    const mapCompUsada = valuecompnusada.map(option => option.label); 
    const labelCompUsada = mapCompUsada.join(',');
    const mapAmenaza = valAmenaza.map(option => option.value); 
    const labelAmenaza = mapAmenaza.join(',');
    const handleChangeStateEstado = (event) => {
        setCheckedEstado(event.target.checked)
        if (stateEstado === "Activo") {
            setStateEstado("Inactivo");
        } else {
            setStateEstado("Activo");
        }
    };
    const handleChangeStateMapeadoControles = (event) => {
        setCheckedMapeadoControles(event.target.checked)
        if (stateMapeadoControles === "Si") {
            setStateMapeadoControles("No");
        } else {
            setStateMapeadoControles("Si");
        }

    };

    const mapearNiveles = (datos) => {
        const temp = {
            sections: [],
            map: {},
        };
        datos.forEach((item) => {
            const { idparametrosgenerales, vlrmetrica, disp_numerico1 } = item;
            const dispNumerico1Int = parseInt(parseFloat(disp_numerico1));
            const baseItem = dispNumerico1Int === 0 ? {
                title: vlrmetrica,
                subSections: [],
            } : {
                subtitle: vlrmetrica,
                checkboxes: [],
            };
            temp.map[idparametrosgenerales] = baseItem;
            if (dispNumerico1Int === 0) {
                temp.sections.push(baseItem);
            }
        });
        datos.forEach((item) => {
            const { idparametrosgenerales, disp_numerico1 } = item;
            const dispNumerico1Int = parseInt(parseFloat(disp_numerico1));
            if (dispNumerico1Int !== 0) {
                const parentItem = temp.map[dispNumerico1Int];
                const currentItem = temp.map[idparametrosgenerales];
    
                if (parentItem) {
                    if ("subSections" in parentItem) {
                        parentItem.subSections.push(currentItem);
                    } else if ("checkboxes" in parentItem) {
                        parentItem.checkboxes.push({ ...currentItem, value: false, name: currentItem.subtitle });
                    }
                }
            }
        });
        return temp.sections.map(section => ({
            ...section,
            subSections: section.subSections.map(subSection => ({
                subtitle: subSection.subtitle,
                checkboxes: subSection.checkboxes.map(checkbox => ({
                    name: checkbox.name,
                    value: checkbox.value,
                }))
            }))
        }));
    };



    useEffect(() => {
        const obtenerListaAmenaza= async () => {
            const result = await fetch(
                process.env.REACT_APP_API_URL + "maestro/amenazas/0",
                {
                    method: "GET",
                    headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await result.json();
            const mapListaAmenaza = data.map(option => ({
                value: option.idamenaza,
                label: option.evento_amenaza
            }));
            setListaAmenaza(mapListaAmenaza);
        };
        const obtenerComponentes = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "allcomponents/" +
                localStorage.getItem("idcompania") +
                "/1/",  
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let datComponentes = await result.json();
      /*       datComponentes = datComponentes.filter(
              (dato) => dato.componente_principal === false
            ); */
            setModalCompo(datComponentes);
          };
        const obtenerTipoComp = async () => {
            const result = await fetch(
                process.env.REACT_APP_API_URL + "general/tipoactivo/",
                {
                    method: "GET",
                    headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await result.json();
            setTipoComp2(data);
            };
        const obtenerTipoDespliegue = async () => {
            const result = await fetch(
                process.env.REACT_APP_API_URL +
                "general/parametrosGeneralesxGrupoxParametro/Componentes/Tipo_Despliegue/" ,
                {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
                }
            );
        let datTipoDespliegue = await result.json();
        setDataDespliegue(datTipoDespliegue);
        };
        const obtenerTipoCanal = async () => {
            const result = await fetch(
                process.env.REACT_APP_API_URL +
                "general/parametrosGeneralesxGrupoxParametro/Componentes/Tipo_Canal/" ,
                {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
                }
            );
        let datTipoCanal = await result.json();
        setDataCanal(datTipoCanal);
        };
        const obtenerCategoriaControl = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "general/parametrosGeneralesxGrupoxParametro/Implementadores/Categoria_Control/" ,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let datCategoria = await result.json();
            const mapCategoriaControl = datCategoria.map(option => ({ 
                value: option.idparametrosgenerales,
                label: option.vlrmetrica
            }));
            setCategoriaControl(mapCategoriaControl);
          };
          const obtenerTipoControl = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "general/parametrosGeneralesxGrupoxParametro/Implementadores/Tipo_Control/" ,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let datTipoControl = await result.json();
            const mapTipoControl = datTipoControl.map(option => ({ 
                value: option.idparametrosgenerales,
                label: option.vlrmetrica
            }));
    
            setTipoControl(mapTipoControl);
          };
          const obtenercapaControl = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "general/parametrosGeneralesxGrupoxParametro/Implementadores/Capa_Control/" ,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let datCapaControl = await result.json();
            const mapCapaControl = datCapaControl.map(option => ({
                value: option.idparametrosgenerales,
                label: option.vlrmetrica
            }));
            setCapaControl(mapCapaControl);
          };
        const obtenerCompañias = async () => {
            const result = await fetch(
                process.env.REACT_APP_API_URL + "general/compania/",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let datCompania = await result.json();
            setCompaniaDatOption(datCompania);
            setCompaniaDat(datCompania);
        };
        const obtenerZeroTrust = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "general/parametrosGeneralesxGrupoxParametro/Implementadores/ZeroTrust/" ,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let datZeroTrust = await result.json();
            const mapZeroTrust = datZeroTrust.map(option => ({
                value: option.idparametrosgenerales,
                label: option.vlrmetrica
            }));
            setDataZeroTrust(mapZeroTrust);
          };
          const obtenerDominio = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "general/parametrosGeneralesxGrupoxParametro/Implementadores/Dominio/" ,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let datDominio = await result.json();
            const mapDominio = datDominio.map(option => ({
                value: option.idparametrosgenerales,
                label: option.vlrmetrica
            }));
            setDataDominio(mapDominio);
          };
          const obtenerTipoUsuario = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "general/parametrosGeneralesxGrupoxParametro/Implementadores/Tipo_Usuario/" ,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let datTipoUsuario = await result.json();
            const mapTipoUsuario = datTipoUsuario.map(option => ({
                value: option.idparametrosgenerales,
                label: option.vlrmetrica
            }));
            setDataTipoUsuario(mapTipoUsuario);
          };
          const obtenerAreaResponsable = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "general/parametrosGeneralesxGrupoxParametro/Implementadores/Area_Responsable/" ,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let datAreaResponsable = await result.json();
            const mapAreaResponsable = datAreaResponsable.map(option => ({
                value: option.idparametrosgenerales,
                label: option.vlrmetrica
            }));
            setdatAreaResponsable(mapAreaResponsable);
          };
        const obtenerNiveles = async () => {
            const result = await fetch(process.env.REACT_APP_API_URL + "general/parametrosGeneralesxGrupoxParametro/Implementadores/Niveles/", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
            });
            let datNiveles = await result.json();
            const seccionesMapeadas = mapearNiveles(datNiveles);
            setSections(seccionesMapeadas);
        };
        const fetchdata = async (rol) => {
            const result = await fetch(
                process.env.REACT_APP_API_URL + "maestro/controles/0/",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let data = await result.json();
            setControles(data);

        };
        obtenerListaAmenaza();
        obtenerComponentes();
        obtenerCompañias();
        fetchdata();
        obtenerCategoriaControl();
        obtenerTipoControl();
        obtenercapaControl();
        obtenerTipoComp();
        obtenerTipoDespliegue();
        obtenerTipoCanal();
        obtenerZeroTrust();
        obtenerDominio();
        obtenerTipoUsuario();
        obtenerAreaResponsable();
        obtenerNiveles();
    }, []);
    const enviarImplemenXControl = () => {
        let dato = []
        rowsP.map((n) => {
            dato.push({
                idimplementadorporcontrol: 0,
                idcontrol: n.idcontrol,
                idimplementador: localStorage.getItem("idimplementador"),
                disp_numerico1: 1,
            })
        })
        fetch(
            process.env.REACT_APP_API_URL +
            "maestro/implementador_por_control/0",
            {
                method: "POST",
                body: JSON.stringify(dato),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: "Bearer " + token,
                },
            }
        )
            .then((data) =>
                data.json().then((response) => {
                    if (data.status >= 200 && data.status < 300) {
                        setEstadoPost(2);
                    } else if (data.status >= 500) {
                        setEstadoPost(5);
                    } else if (data.status >= 400 && data.status < 500) {
                        setEstadoPost(4);
                    }
                })
            )
            .catch(function (err) { });
    };
    function llenarTipoComp(data) { 
        let tipo = [];
        for (const clave in data) {
          if (
            data[clave]["codigogeneral"] === "COM" && (data[clave]["estado"] === true)
          ) {
            tipo.push(data[clave]);
          }
        }
        tipo.push({ idtipo_activo: "", 
                    codigogeneral: "",
                    estado: true,
                    codigogeneral: "",
                    tipo_activo: "Ninguno(No será considerado en las EROS)",});
        return tipo;
    }  
  
    const tipoComp = llenarTipoComp(tipoComp2); 
    const maptipodespliegue = DataDespliegue.map(option => ({
        value: option.idparametrosgenerales,
        label: option.vlrmetrica
    }));
    const mapcompnusada = companiaDatOption.map(option => ({
        value: option.idcompania,
        label: option.compania,
        
      }));

    const onChangeCategoriaControl = (value) => {
        setvalCapaControl([]);
        setvalCategoriaControl(value);
        if (value.label === "Controles generales de ciber seguridad") {
            setCapaControlTemp(capaControl.filter((capa) => capa.label === "Monitoreo y Respuesta"));
        }
        else if (value.label === "Controles específicos sobres los componentes tecnológicos") {
            setCapaControlTemp(capaControl.filter((capa) => capa.label === "Seguridad perimetral" || capa.label === "Seguridad de la red" || capa.label === "Seguridad de endpoints" || capa.label === "Seguridad de aplicaciones"));
        }
        else if (value.label === "Controles específicos sobre los datos") {
            setCapaControlTemp(capaControl.filter((capa) => capa.label === "Seguridad de los datos"));
        }
        else {
            setCapaControlTemp([]);
        }
    };
    const onChangeTipoControl = (value) => {
        setvalTipoControl(value);
    };
    const onChangeCapaControl = (value) => {
        setvalCapaControl(value);
    };
    const onChangeCompania = (value) => {
        setCompaniaSelect(value);
    };
    const handleOnchangecompn  =  val  => {
        setvaluecompnusada(val)       
    };
    const onChangeNivelAutomatizacion = (value) => {
        setNivelAutomatizacion(value);
    };

    const  changeCompT  =  val  => {
        setCodigoActivo(val)
    }
    const  changeCanalT  =  val  => {
        setCanalT(val)
    }
    const  changeDesp  =  val  => {
        setvaluedesp(val)
      }
    const handleChangeNombre = val =>{
        setValueNombre(val.target.value) //TODO: 
    }
    const handleChangeFabricante = val =>{
        setValueFabricante(val.target.value)
    }
    const handleChangeDescripcion = val =>{
        setValueDescripcion(val.target.value)
    }
    const handleChangeZero = val =>{
        setValueZero(val)
    }
    const handleChangeDominio = val =>{
        setValueDominio(val)
    }
    const handleChangeTipoUsuario = val =>{
        setValueTipoUsuario(val)
    }
    const handleChangeAreaResponsable = val =>{
        setValueAreaResponsable(val)
    }
    const handleChangeDocumentacion = val =>{
        setValueDocumentacion(val.target.value)
    }
    const handleChangeEficacia = val =>{
        const newValue = val.target.value;
        if (newValue === '' || (parseFloat(newValue) >= 0 && parseFloat(newValue) <= 1)) {
            setValueEficacia(newValue);
            setErrorEO(false);
        } else {
            setErrorEO(true);
        }
    }
    const onChangeAmenaza = (value) => {
        setvalAmenaza(value);
    };
    //TODO:hacer if 0-1 y set post 4(error)
    const sendData = (event) => {
        event.preventDefault();
        //Agregaremos validaciones para campos obligatorios
        if (valueNombre === "" || 
            valueDescripcion === "" || 
            companiaSelect === null ||
            labelCompUsada === "" ||
            nivelAutomatizacion === "" ||
            valTipoControl === "" ||
            labelTipoDespliegue === "") {
            setEstadoPost(6);
            return;
        }
        else {
            var data = JSON.stringify({
                idimplementador: 0,
                idcompania: parseInt(companiaSelect.idcompania),
                nombre: valueNombre,
                descripcion: valueDescripcion,
                nivel_automatizacion: nivelAutomatizacion.nombre, 
                nivel_gestion: nivelGestion? nivelGestion: null,
                estado:checkedEstado,
                mapeado_en_controles: stateMapeadoControles === "Si" ? 1 : 0,
                categoria_control: valcategoriaControl.label? valcategoriaControl.label: null,
                tipo_control: valTipoControl.label? valTipoControl.label: null,
                capa_control: labelCapaControl? labelCapaControl: null,
                fabricante: valueFabricante? valueFabricante: null,
                companias_usan: labelCompUsada ? labelCompUsada: null,
                tipo_despliegue: labelTipoDespliegue ? labelTipoDespliegue: null,
                tipo_canal: canalT.vlrmetrica? canalT.vlrmetrica: null,
                idtipo_activo: codigoActivo.idtipo_activo? codigoActivo.idtipo_activo : null,
                eficaciaoperativa: valueEficacia? parseFloat(valueEficacia): null,
                idactivo:  componentesSelected ? componentesSelected[0] : null,
                zero_trust: valueZero.label? valueZero.label: null,
                dominio:valueDominio.label? valueDominio.label: null,
                tipo_de_usuario: valueTipoUsuario.label? valueTipoUsuario.label: null,
                area_responsable: valueAreaResponsable.label? valueAreaResponsable.label: null,
                documentacion: valueDocumentacion? valueDocumentacion: null,
                niveles: sections? JSON.stringify(sections): null,
                amenazas: labelAmenaza ? labelAmenaza: null,
            });
            fetch(
                process.env.REACT_APP_API_URL +
                "maestro/implementadores/0/",
                {
                    method: "POST",
                    body: data,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        Authorization: "Bearer " + token,
                    },
                }
            )
                .then((data) =>
                    data.json().then((response) => {
                        if (data.status >= 200 && data.status < 300) {
                            setEstadoPost(2);
                            localStorage.setItem("idimplementador", response.idimplementador);
                            enviarImplemenXControl();
                            history.push("/EditarMimplementador");
                        } else if (data.status >= 500) {
                            setEstadoPost(5);
                        } else if (data.status >= 400 && data.status < 500) {
                            setEstadoPost(4);
                        }
                    })
                )
                .catch(function (err) { });
            setValidated(true);

        }
    }
    
    function ComponentInfraModal(props) {
        const [queryText, setqueryText] = React.useState("");
        const [contTecno, setContTecno] = React.useState(props.componentes);
        const [dense, setDense] = React.useState(false);
        const [order, setOrder] = React.useState("asc");
        const [orderBy, setOrderBy] = React.useState("name");
        const [page, setPage] = React.useState(0);
        const [rowsPerPage, setRowsPerPage] = React.useState(10);
        const classes = useStylesModal();

        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        };
        const handleSelectAllClick = (event) => {
            if (event.target.checked) {
                const newSelecteds = controles.map((n) => n.idcontrol);
                setContTecno(newSelecteds);
                return;
            }
            setContTecno([]);
        };
        const handleClick = (event, id, idtipo_activo, obj) => {
            const selectedIndex = contTecno.indexOf(id);
            let newSelected = [];
            if (selectedIndex === -1) {
                newSelected.push(id);
            }
            else {
                newSelected = contTecno.filter((item) => item !== id);
            }
            setContTecno(newSelected);
        };
        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };
        
        const isSelected = (idcontrol) => contTecno.indexOf(idcontrol) !== -1;
        const comData = stableSort(modalCompo, getComparator(order, orderBy)).filter((row) =>
        row.idactivo.toString().includes(queryText.toLowerCase())).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        const final_data_ifrastructure = comData;
        const handleChangeDense = (event) => {
            setDense(event.target.checked);
        };
        return (
            <>
                <Modal {...props}
                size='xl'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                > 
                <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                    Añadir componente principal
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                    <Row>
                        <Col sm={4} xs={2}>
                        <Form className='buscar'>
                            <Form.Control
                            type='text'
                            placeholder='Buscar'
                            onChange={(e) => setqueryText(e.target.value)}
                            style={{ align: "center" }}
                            />
                        </Form>
                        </Col>
                        <Col sm={4} xs={6}>
                        <Button
                            className='botonPositivo'
                            style={{ marginTop: "1%", width: "100%" }}
                            onClick={() => completarTabla(contTecno, "detalles")}
                        >
                            {" "}
                            Guardar{" "}
                        </Button>
                        </Col>
                    </Row>
                    <TableContainer className={classes.container}>
                        <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size={dense ? "small" : "medium"}
                        aria-label='enhanced table'
                        >
                        <EnhancedTableHeadCompP
                            classes={classes}
                            numSelected={contTecno.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={modalCompo.length}
                        />
                        <TableBody>
                            {final_data_ifrastructure?.map((row, index) => {
                            const isItemSelected = isSelected(row.idactivo);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow
                                onClick={(event) =>
                                    handleClick(
                                    event,
                                    row.idactivo,
                                    row.idtipo_activo,
                                    row
                                    )
                                }
                                role='checkbox'
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.idactivo}
                                selected={isItemSelected}
                                >
                                <TableCell padding='checkbox'>
                                    <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ "aria-labelledby": labelId }}
                                    />
                                </TableCell>
                                <TableCell
                                    component='th'
                                    id={labelId}
                                    scope='row'
                                    padding='none'
                                >
                                    {row.idactivo}
                                </TableCell>
                                <TableCell align='left'>{row.nombre}</TableCell>
                                <TableCell align='left'>{row.descripcion}</TableCell>
                                <TableCell align='left'>{row.UsuarioResponsableTI}</TableCell>
                                <TableCell align='left'>{row.UsuarioResponsableNegocio}</TableCell>
                                </TableRow>
                            );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <div>
                        <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component='div'
                        count={modalCompo.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                    </Paper>
                    <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label='Vista compacta'
                    />
                </div>
                </Modal.Body>
                </Modal>
            </>
        );             
    }   
    function MyVerticallyCenteredModal(props) {
        const [queryText, setqueryText] = React.useState("");
        const [contTecno, setContTecno] = React.useState(props.componentes);
        const [dense, setDense] = React.useState(false);
        const [order, setOrder] = React.useState("asc");
        const [orderBy, setOrderBy] = React.useState("name");
        const [page, setPage] = React.useState(0);
        const [rowsPerPage, setRowsPerPage] = React.useState(10);
        const classes = useStylesModal();


        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        };
        const handleSelectAllClick = (event) => {
            if (event.target.checked) {
                const newSelecteds = controles.map((n) => n.idcontrol);
                setContTecno(newSelecteds);
                return;
            }
            setContTecno([]);
        };
        const handleClick = (event, id, idtipo_activo, obj) => {
            const selectedIndex = contTecno.indexOf(id);
            let newSelected = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(contTecno, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(contTecno.slice(1));
            } else if (selectedIndex === contTecno.length - 1) {
                newSelected = newSelected.concat(contTecno.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    contTecno.slice(0, selectedIndex),
                    contTecno.slice(selectedIndex + 1)
                );
            }
            setContTecno(newSelected);
        };
        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };
        
        const isSelected = (idcontrol) => contTecno.indexOf(idcontrol) !== -1;      
        return (
            <>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Añadir Estandar Nist
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.root}>
                        <Row>
                            <Col sm={4} xs={2}>
                                <Form className="buscar">
                                    <Form.Control
                                        type="text"
                                        placeholder="Buscar"
                                        onChange={(e) => setqueryText(e.target.value)}
                                        style={{ align: "center" }}
                                    />
                                </Form>
                            </Col>
                            <Col sm={4} xs={6}>
                            </Col>
                            <Col sm={4} xs={6}>
                                <Button
                                    className="botonPositivo"
                                    style={{ marginTop: "1%", width: "100%" }}
                                    onClick={() => completarTabla(contTecno)}
                                >
                                    {" "}
                                    Guardar{" "}
                                </Button>
                            </Col>
                        </Row>
                        <Paper className={classes.paper}>
                            <TableContainer className={classes.container}>
                                <Table
                                    stickyHeader
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={contTecno.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={controles.length}
                                    />
                                    <TableBody>
                                        {stableSort(controles, getComparator(order, orderBy))
                                            .filter((row) =>
                                                row.idcontrol
                                                    .toString()
                                                    .includes(queryText.toLowerCase())
                                            )
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.idcontrol);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        onClick={(event) =>
                                                            handleClick(
                                                                event,
                                                                row.idcontrol,
                                                                row
                                                            )
                                                        }
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.idcontrol}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={isItemSelected}
                                                                inputProps={{ "aria-labelledby": labelId }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{row.idcontrol}</TableCell>
                                                        <TableCell>{row.idcontrol_nist}</TableCell>
                                                        <TableCell>{row.nombre_control}</TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 30]}
                                component="div"
                                count={controles.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={"Filas por página"}
                            />
                        </Paper>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>       
        </>      
        );
    }
    const completarTabla = (obj, id) => {
        let temp = [];
        setControlSelected(obj);
        // for (let i = 0; i < obj.length; i++) {
        //     temp.push(
        //         controles.filter((dato) => dato.idcontrol === obj[i])[0]
        //     );
        // }
        setRowsP(temp);
        setModalShow(false);
        switch (id) {
            case "detalles":
              setComponentesSelected(obj);
              for (let i = 0; i < obj.length; i++) {
                temp.push(modalCompo.filter((dato) => dato.idactivo === obj[i])[0]);
              }
              setRowsC(temp);
              setModalShowCompP(false);
              break;
      
            default:
              break;
          }

    }
    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
      };
    const [selectedInfra, setSelectedInfra] = useState([]);  
    const isSelectedInfra = (name) => selectedInfra.indexOf(name) !== -1;
    const handleClickInfra = (event, name) => {
        const selectedIndex = selectedInfra.indexOf(name);
    
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat([], name);
          //SetButtonEdit(true);
        } else {
          //SetButtonEdit(false);
        }
    
        setSelectedInfra(newSelected);
    };
    const classes = useStylesModal();
    
    
    const toggleAccordion = (sectionIndex) => {
        const updatedSections = [...sections];
        updatedSections[sectionIndex].isOpen = !updatedSections[sectionIndex].isOpen;
        setSections(updatedSections);
    };
    
    const handleCheckboxChange = (sectionIndex, subSectionIndex, checkboxIndex) => {
        const updatedSections = [...sections];
        updatedSections[sectionIndex].subSections[subSectionIndex].checkboxes[checkboxIndex].value =
        !updatedSections[sectionIndex].subSections[subSectionIndex].checkboxes[checkboxIndex].value;
        setSections(updatedSections);
    };      

    const renderTooltip = (value) => (
        <Tooltip 
          title={
            <React.Fragment>
            <List>
              <ListItem>
                <ListItemText primary={value} />
              </ListItem>
            </List>
          </React.Fragment>
        }>
          <HelpOutline />
        </Tooltip>
      );

    return (
        <>  
            <AlertDismissibleExample alerta={estadoPOST} />
            <AppBar position="static" style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}>
                <Tabs value={activeTab} onChange={handleChangeTab} aria-label="simple tabs example">
                <Tab label="General" className="vult-title" />
                <Tab label="Arquitectura" className="vult-title"/>
                <Tab label="Detalle Riesgo" className="vult-title"/>
                </Tabs>
            </AppBar>
            {activeTab === 0 && (               
                <>
                    <Row>
                        <Col>
                            {" "}
                            <h2 className="titulo" style={{ marginTop: '20px' }}>Nuevo Control</h2>
                        </Col>
                    </Row>
                    <hr />
                    <Form
                        id="formData"
                        onSubmit={(e) => sendData(e)}
                        noValidate
                        validated={validated}
                    >
                        <Row className="mb-3">
                            <Col sm={2} xs={12}>
                                <label className="label forn-label">Id Control</label>
                            </Col>
                            <Col sm={2} xs={12}>
                                <input
                                    type="text"
                                    disabled
                                    className="form-control text-center"
                                    placeholder="ID Automático"
                                    id="IDImplementador"
                                ></input>
                            </Col>
                            <Col sm={2} xs={12}>
                                <label className="label form-label">Nombre Control*</label> 
                            </Col>
                            <Col sm={4} xs={12}>
                                <input
                                    type="text"
                                    className="form-control text-center"
                                    placeholder="Nombre del Control"
                                    required
                                    id="NombreImplementador"
                                    onChange={handleChangeNombre}
                                    value={valueNombre}
                                ></input>
                                <Form.Control.Feedback type="invalid">
                                    Por favor introduzca un nombre.
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={1} xs={12} >
                                <label className="label forn-label">Estado*</label>
                            </Col>
                            <Col sm={1} xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={checkedEstado}
                                    />}
                                    label={stateEstado}
                                    onChange={handleChangeStateEstado}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2} xs={12}>
                                <label className="label forn-label">Compañía*</label>
                            </Col>
                            <Col sm={4} xs={10}>
                                <Select
                                    placeholder={"Seleccione la compañía..."}
                                    getOptionLabel={(option) => option.compania}
                                    getOptionValue={(option) => option.idcompania}
                                    options={companiaDat}
                                    onChange={onChangeCompania}
                                    styles={stylesSelect}
                                    value ={companiaSelect}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Por favor seleccione una compañía.
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={2} xs={12}>
                                <label className="label forn-label">Compañías que la usan*</label>
                            </Col>
                            <Col sm={4} xs={10}>
                                <Select 
                                isMulti
                                styles={stylesSelect}
                                style={{ width: '100%' }}
                                placeholder='Seleccione compañías que la usan...'
                                onChange={handleOnchangecompn}
                                options={mapcompnusada}
                                id= 'companiausada'  
                                value={valuecompnusada}       
                                />     
                                <Form.Control.Feedback type="invalid">
                                    Por favor seleccione una compañía.
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2} xs={12}>
                                <label className="label form-label">Fabricante</label> {/*TODO: guaradar en estado*/}
                            </Col>
                            <Col sm={4} xs={12}>
                                <input
                                    type="text"
                                    className="form-control text-center"
                                    placeholder="Nombre del Fabricante"
                                    id="fabricante"
                                    onChange={handleChangeFabricante}
                                    value={valueFabricante}
                                ></input>
                            </Col>
                            <Col sm={2} xs={12}>
                                <label className="label forn-label">Nivel de Automatización*</label>
                            </Col>
                            <Col sm={4} xs={12}>
                                <Select
                                    placeholder={"Seleccione nivel de automatización"}
                                    getOptionLabel={(opcion_nivel_automatizacion) => opcion_nivel_automatizacion.nombre}
                                    getOptionValue={(opcion_nivel_automatizacion) => opcion_nivel_automatizacion.nombre}
                                    options={opcion_nivel_automatizacion}
                                    onChange={onChangeNivelAutomatizacion}
                                    styles={stylesSelect}
                                    value={nivelAutomatizacion}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Por favor seleccione el nivel de Automatización.
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2} xs={12}>
                                <label className="label form-label">Descripción*</label> {/*TODO: guaradar en estado*/}
                            </Col>
                            <Col sm={10} xs={10}>
                                <textarea
                                    className="form-control text-center"
                                    placeholder="Descripción del Control"
                                    rows="3"
                                    required
                                    id="Descripcion"
                                    onChange={handleChangeDescripcion}
                                    value={valueDescripcion}
                                ></textarea>
                                <Form.Control.Feedback type="invalid">
                                    Por favor introduzca una descripción.
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                      
                        <Row className="mb-3">
                            <Col sm={2} xs={12}>
                                <label className="label forn-label">Tipo de Control*</label>
                            </Col>
                            <Col sm={4} xs={12}>
                                <Select
                                    placeholder={"Seleccione el tipo de Control"}
                                    options={tipoControl}
                                    onChange={onChangeTipoControl}
                                    styles={stylesSelect}
                                    value={valTipoControl}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Por favor seleccione el tipo de control.
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={2} xs={12}>
                            <label className='label forn-label'>Tipo Despliegue*</label>
                            </Col>
                            <Col sm={4} xs={12}>         
                                <Select
                                isMulti
                                style={{ width: '100%' }}
                                styles={stylesSelect}
                                placeholder='Seleccione el tipo de despliegue'
                                onChange={changeDesp}
                                options={maptipodespliegue}
                                id ='despliegueT'    
                                value={despliegue}                          
                                />   
                                <Form.Control.Feedback type='invalid'>
                                Por favor seleccione un tipo de despliegue.
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form>
                </>
            )}
            {activeTab === 1  && (
                <>  
                    <Form
                        id="formData"
                        onSubmit={(e) => sendData(e)}
                        noValidate
                        validated={validated}
                    >                        
                    <Row>{/*TODO: guaradar en estado*/}
                    <Col>
                        <h2 className="titulo" style={{ marginBottom: '20px', marginTop: '20px' }}>Arquitectura</h2>
                        <hr style={{ border: '0', borderTop: '1px solid #ccc', margin: '20px 0' }} />
                        {" "}
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col sm={2} xs={12}>
                        <label className='label forn-label'>Zero Trust</label>
                    </Col>
                    <Col sm={3} xs={12}>
                    { <Select
                        placeholder={"Seleccione un trust"}        
                        required
                        isDisabled={idrolUser != 1 && idrolUser != 7}
                        id='ZeroT'
                        isMulti={false}
                        options={dataZeroTrust}
                        styles={stylesSelect}
                        onChange={handleChangeZero}
                        value={valueZero}
                        /> 
                    }
                        <Form.Control.Feedback type='invalid'>
                        Por favor seleccione un Trust
                        </Form.Control.Feedback>
                    </Col>
    
                    <Col sm={2} xs={12}>
                        <label className='label forn-label'>Dominio</label>
                    </Col>
                    <Col sm={3} xs={12}>
                    { <Select
                        placeholder={"Seleccione un dominio"}        
                        required
                        isDisabled={idrolUser != 1 && idrolUser != 7}
                        id='Ddominio'
                        isMulti={false}
                        styles={stylesSelect}
                        options={dataDominio}
                        onChange={handleChangeDominio}
                        value={valueDominio}  
                        
                        /> 
                    }
                        <Form.Control.Feedback type='invalid'>
                        Por favor seleccione un Dominio
                        </Form.Control.Feedback>
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col sm={2} xs={12}>
                        <label className='label forn-label'>Tipo de usuario</label>
                    </Col>
                    <Col sm={3} xs={12}>
                    { <Select
                        placeholder={"Seleccione un usuario"}        
                        required
                        isDisabled={idrolUser != 1 && idrolUser != 7}
                        id='tusuario'
                        isMulti={false}
                        styles={stylesSelect}
                        options={dataTipoUsuario}
                        onChange={handleChangeTipoUsuario}   
                        value={valueTipoUsuario}
                        
                        /> 
                    }
                        <Form.Control.Feedback type='invalid'>
                        Por favor seleccione un Tipo de usuario
                        </Form.Control.Feedback>
                    </Col>
    
                    <Col sm={2} xs={12}>
                        <label className='label forn-label'>Area Responsable</label>
                    </Col>
                    <Col sm={3} xs={12}>
                    { <Select
                         placeholder={"Seleccione un area de responsable"}        
                         required
                         isDisabled={idrolUser != 1 && idrolUser != 7}
                         id='idRespo'
                         isMulti={false}
                         styles={stylesSelect}
                         options={datAreaResponsable}
                         onChange={handleChangeAreaResponsable}  
                         value={valueAreaResponsable}

                        /> 
                    }
                        <Form.Control.Feedback type='invalid'>
                        Por favor seleccione un Responsable
                        </Form.Control.Feedback>
                    </Col>
                </Row>

                <Row className="mb-3">
                            <Col sm={2} xs={12}>
                                <label className="label form-label">Documentacion</label>
                            </Col>
                            <Col sm={8} xs={10}>
                                <textarea
                                    className="form-control text-center"
                                    placeholder="Documentación"
                                    disabled={idrolUser != 1 && idrolUser != 7}
                                    rows="3"
                                    id="documentacion"
                                    onChange={handleChangeDocumentacion}
                                    value={valueDocumentacion}
                                ></textarea>
                                <Form.Control.Feedback type="invalid">
                                    Por favor introduzca una Descripción.
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                <hr style={{ border: '0', borderTop: '1px solid #ccc', margin: '20px 0' }} />
                <div className="accordion">
                {sections.map((section, sectionIndex) => (
                    <div key={sectionIndex} className="accordion-section">
                    <div className="accordion-header" onClick={() => toggleAccordion(sectionIndex)}>
                        <span className="accordion-arrow">
                            {section.isOpen ? <ExpandMoreIcon style={{ fontSize: '32px'}}/> : <ExpandMoreIcon style={{ fontSize: '32px', transform: 'rotate(270deg)' }}/>}
                        </span>
                        <h3 className="accordion-title" style={{ display: 'inline' }}>
                        {section.title}
                        </h3>
                    </div>
                    {section.isOpen && (
                        <div className="accordion-content">
                        {section.subSections.map((subSection, subSectionIndex) => (
                            <div key={subSectionIndex}>
                            <h4>{subSection.subtitle}</h4>
                            {subSection.checkboxes.map((checkbox, checkboxIndex) => (
                                <div key={checkboxIndex}>
                                <label key={checkboxIndex} className="checkbox-item">
                                    <input
                                    type="checkbox"
                                    disabled={idrolUser != 1 && idrolUser != 7}
                                    checked={checkbox.value}
                                    onChange={() => handleCheckboxChange(sectionIndex, subSectionIndex, checkboxIndex)}
                                    />
                                    {checkbox.name}
                                </label>
                                </div>
                            ))}
                            </div>
                        ))}
                        </div>
                    )}
                    </div>
                ))}
                </div>
                <hr/>  
                <Row className="mb-3" style={{ padding: "25px 0px" }}>
                            <Col sm={4} xs={1}></Col>
                            <Col sm={3} xs={3}>
                                {" "}
                                <Button 
                                    type="submit" 
                                    className="botonPositivo" 
                                    id="send" 
                                    disabled={idrolUser != 1 && idrolUser != 7}
                                >
                                    Guardar
                                </Button>
                            </Col>
                            <Col sm={3} xs={3}>
                                {" "}
                                <Link to="maestro-implementador">
                                    <Button 
                                        className="botonNegativo"
                                        disabled={idrolUser != 1 && idrolUser != 7}
                                >
                                        Descartar</Button>
                                </Link>
                            </Col>
                        </Row>
                </Form>
                </>               
            )}
            {activeTab === 2 && (
                <> 
                <MyVerticallyCenteredModal
                componentes={controlSelected}
                show={modalShow}
                onHide={() => {
                    setDireccion(false);
                    setModalShow(false);
                }}/>
                <ComponentInfraModal
                componentes={componentesSelected}
                show={modalShowCompP}
                onHide={() => {
                    setModalShowCompP(false);
                }}
                />
                <Row>
                    <Col>
                        <h2 className="titulo" style={{ marginTop: '20px' }}>Detalle Riesgo</h2>
                    </Col>
                </Row>
                <hr />
                <Form
                id="formData"
                onSubmit={(e) => sendData(e)}
                noValidate
                validated={validated}
                >
                    <Row className="mb-3">
                        <Col sm={2} xs={12}>
                            <label className="label forn-label">Categoria del Control</label> 
                        </Col>
                        <Col sm={4} xs={12}>
                            <Select
                                placeholder={"Seleccione la categoria"}
                                isDisabled={idrolUser == 7} 
                                options={categoriaControl}
                                onChange={onChangeCategoriaControl}
                                styles={stylesSelect}
                                value={valcategoriaControl}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor seleccione la categoria del control.
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={2} xs={12}>
                            <label className="label forn-label">Capa de Control</label>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Select
                                isMulti
                                isDisabled={idrolUser == 7} 
                                placeholder={"Seleccione la capa"}
                                options={capaControlTemp}
                                onChange={onChangeCapaControl}
                                styles={stylesSelect}
                                value={valcapaControl}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor seleccione la Capa de control.
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={2} xs={12}>
                            <label className="label forn-label">Amenazas que mitiga el Control</label>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Select
                                    isMulti
                                    isDisabled={idrolUser == 7}
                                    placeholder={"Seleccione las amenazas"}
                                    options={listaAmenaza}
                                    onChange={onChangeAmenaza}
                                    styles={stylesSelect}
                                    value={valAmenaza}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Por favor seleccione la Capa de control.
                                </Form.Control.Feedback>
                        </Col>
                        <Col sm={2} xs={12}>
                            <label className='label forn-label'>Tipo Componente</label>
                        </Col>
                        <Col sm={4} xs={12}>
                        { <Select
                            placeholder={"Seleccione tipo de componente"}
                            isDisabled={idrolUser == 7}
                            onChange={changeCompT}             
                            required
                            id='componenteT'  
                            getOptionLabel={(option) => option.tipo_activo}
                            getOptionValue={(option) => option.idtipo_activo}   
                            options={tipoComp}
                            styles={stylesSelect}
                            value={codigoActivo}   
                            
                            /> 
                        }
                            <Form.Control.Feedback type='invalid'>
                            Por favor seleccione un tipo de componente.
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={2} xs={12}>
                            <label className="label forn-label">Mapeado en Estandar Nist</label>
                        </Col>
                        <Col sm={4} xs={12}>
                            <FormControlLabel
                                control={<Switch 
                                    checked={checkedMapeadoControles}
                                    disabled={idrolUser == 7}
                                />}
                                label={stateMapeadoControles}
                                onChange={handleChangeStateMapeadoControles}
                                value={stateMapeadoControles}
                            />
                        </Col>
                        <Col sm={2} xs={12}>
                            <label className='label forn-label'>Tipo Canal</label>
                        </Col>
                        <Col sm={4} xs={12}>
                            <Select
                            placeholder={"Seleccione tipo de canal"}  
                            isDisabled={idrolUser == 7}
                            required
                            onChange={changeCanalT} 
                            id='canalT'   
                            getOptionLabel={(option) => option.vlrmetrica}
                            getOptionValue={(option) => option.idparametrosgenerales}   
                            options={DataCanal}
                            styles={stylesSelect}
                            value={canalT}                              
                            />                        
                            <Form.Control.Feedback type='invalid'>
                            Por favor seleccione un tipo de canal.
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={2} xs={12}>
                            <label className="label forn-label">Performance del control {
                            renderTooltip("Solo diligenciar en caso de tener un indicador que mida el desempeño del control, debe de ser ingresado como la media de las observaciones del último año.")}
                            </label>
                        </Col>
                        <Col sm={2} xs={12}>
                        <TextField
                            type="number"
                            id="porcentajeEficacia"
                            name="porcentajeEficacia"
                            label="Ingrese valor"
                            variant="outlined"
                            error={errorEO}
                            disabled={idrolUser == 7}
                            helperText={errorEO ? 'El valor debe estar entre 0 y 1.' : ''}
                            onChange={handleChangeEficacia}
                            value={valueEficacia}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    max: 1,
                                    step: 0.01,
                                    type: 'number',
                                },
                            }}
                            sx={{ width: '100%', zIndex: 0 }}
                        />                       
                        </Col>
                        <Col sm={4} xs={12}>
                            <label className="label forn-label">Vulnerabilidad del control {
                            renderTooltip("Se calculará una vez se guarde el control si este está asociado a un componente principal")}
                            </label>
                        </Col>
                        <Col sm={4} xs={12}>
                            <label className="label forn-label">Diseño Control {
                            renderTooltip("Se calculará una vez se guarde el control por medio de la Naturaleza, Automatización, Performance y Vulnerabilidad")}
                            </label>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="mb-3">
                                <Col md={10}>
                                    {" "}
                                    <h2 className="subtitulo">
                                        Añadir Estandar Nist
                                    </h2>
                                </Col>
                                <Col md={2}>
                                    <Button
                                        className="botonIngreso"
                                        disabled={idrolUser == 7}
                                        onClick={() => {
                                        setModalShow(true);
                                        setDireccion(true);
                                        }}
                                    >
                                        Añadir
                                    </Button>{" "}
                                </Col>
                            </Row>
                    <Row className="mb-3">
                        <TableContainer
                            component={Paper}
                            className="table table-bordered"
                        >
                            <Table  aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>
                                            Id Control por Estandar Nist
                                        </StyledTableCell>
                                        <StyledTableCell >
                                            ID Estandar Nist
                                        </StyledTableCell>
                                        <StyledTableCell >
                                            Nombre del Estandar Nist
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rowsP.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.idimplementadorporcontrol}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {row.idcontrol}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {row.nombre_control}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Row>
                    <Row className='mb-3'>
                        <Col md={10}>
                            <h2 className='subtitulo'>
                            Añadir Componente principal
                            </h2>
                        </Col>
                        <Col md={2}>
                            <Button
                            className='botonIngreso'
                            disabled={idrolUser == 7}
                            onClick={() => setModalShowCompP(true)}
                            >
                            Añadir
                            </Button>{" "}
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <TableContainer
                            component={Paper}
                            className='table table-bordered'
                        >
                            <Table className={classes.table} aria-label='customized table'>
                                <TableHead>
                                    <TableRow>
                                    <StyledTableCell align='left'>ID</StyledTableCell>
                                    <StyledTableCell align='left'>
                                        Nombre
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>
                                        Descripción
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>
                                        Responsable TI
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>
                                        Responsable Negocio
                                    </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rowsc !== null
                                    ? rowsc.map((row, index) => {
                                        const isItemSelected = isSelectedInfra(
                                        row.idactivo
                                        );
                                        return (
                                        <StyledTableRow
                                            key={row.idactivo}
                                            hover
                                            onClick={(event) =>
                                            handleClickInfra(event, row.idactivo)
                                            }
                                            selected={isItemSelected}
                                            role="checkbox"
                                            tabIndex={-1}
                                        >
                                            <StyledTableCell component='th' scope='row'>
                                            {row.idactivo}
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>
                                            {row.nombre}
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>
                                            {row.descripcion}
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>
                                            {row.UsuarioResponsableTI}
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>
                                            {row.UsuarioResponsableNegocio}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        );
                                        }
                                    )
                                    : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Row>
                    <hr/>              
                    <Row className="mb-3" style={{ padding: "25px 0px" }}>
                        <Col sm={4} xs={1}></Col>
                        <Col sm={3} xs={3}>
                            {" "}
                            <Button 
                                type="submit" 
                                className="botonPositivo" 
                                id="send"
                                disabled={idrolUser == 7}
                            >
                                Guardar
                            </Button>
                        </Col>
                        <Col sm={3} xs={3}>
                            {" "}
                            <Link to="maestro-implementador">
                                <Button 
                                    className="botonNegativo"
                                    disabled={idrolUser == 7}
                                >
                                    Descartar
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Form>
            </>
            )}         
        </>
    )

}

export default NuevosImplementadores;