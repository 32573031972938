class CheckRole {
  constructor(archivo) {
    this.archivo = archivo;
  }

  checkPermits(opc, accion) {
    const opcs = opc;

    var archivo = this.archivo;
    var acciones = accion;
    for (let i = 0; i < opcs.length; i++) {
      if (opcs[i].opcion === archivo) {
        if (acciones === "GET") {
          if (
            opcs[i].permisos.includes("R") ||
            opcs[i].permisos.includes("A")
          ) {
            return true;
          }
        } else if (acciones === "POST") {
          if (opcs[i].permisos.includes("C")) {
            return true;
          }
        } else if (acciones === "PUT") {
          if (
            opcs[i].permisos.includes("U") ||
            opcs[i].permisos.includes("A")
          ) {
            return true;
          }
        } else if (acciones === "DELETE") {
    
          if (opcs[i].permisos.includes("D")) {
            return true;
          }
        }
        return false;
      }
    }
  }
}

export default CheckRole;
