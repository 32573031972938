import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { encryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import makeStyles from "@mui/styles/makeStyles";
import { lighten } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import withStyles from "@mui/styles/withStyles";
import AADService from "../funcionesAuth.js";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

const headCells = [
  { id: "id", numeric: false, disablePadding: false, label: "ID" },
  { id: "nombre", numeric: false, disablePadding: false, label: "Nombre" },
  {
    id: "descripcion",
    numeric: false,
    disablePadding: false,
    label: "Descripción",
  },
  {
    id: "UsuarioResponsableTI",
    numeric: false,
    disablePadding: false,
    label: "Responsable TI",
  },
  {
    id: "UsuarioResponsableNegocio",
    numeric: false,
    disablePadding: false,
    label: "Responsable Negocio",
  },
];


const useToolbarStyles = makeStyles((theme) => ({
  root: {
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "40vh",
    minHeight: "40vh",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding='checkbox'
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className='label'
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",

    color: "white",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const stylesSelect = {
  control: (base) => ({
    ...base,
    fontFamily: "Roboto",
  }),
  menu: (base) => ({
    ...base,
    fontFamily: "CIBFont Sans Regular",
  }),

  option: (provided, state) => ({
    ...provided,
    //color: state.isFocused ? 'white' : '#495057',
  }),
};


const NuevoComponenteInfra = (props) => {
  const classes = useStyles();
  const [modalShow, setModalShow] = React.useState(false);
  const [validated, setValidated] = useState(false);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [direccion, setDireccion] = React.useState(false);
  const [modalCompo, setModalCompo] = useState([]);
  const [componentesSelected, setComponentesSelected] = React.useState([]);
  const [rowsc, setRowsC] = React.useState([]);

  let history = useHistory();
  const ubicacion_logica = [
    { id: "1", nombre: "Expuesto a internet" },
    { id: "2", nombre: "Red Confianza" },
    { id: "3", nombre: "Red Interna" },
    { id: "4", nombre: "Segmento controlado" },
  ];
  const relevancia = [
    { id: "1", nombre: "Componente Soporte" },
    {
      id: "2",
      nombre: "Infraestructura crítica transversal/Punto único de falla",
    },
    { id: "3", nombre: "Infraestructura crítica no transversal" },
  ];
  const tipo_ambiente = [
    { id: "1", nombre: "Producción" },
    { id: "2", nombre: "Certificación" },
  ];
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  useEffect(() => {
    const obtenerComponentes = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "allcomponents/" +
          localStorage.getItem("idcompania") +
          "/1/",  
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datComponentes = await result.json();
/*       datComponentes = datComponentes.filter(
        (dato) => dato.componente_principal === false
      ); */
      setModalCompo(datComponentes);
    };
    obtenerComponentes();
  }, []);


  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let componentePrincipal = [];
      rowsc.map((dat) => {
        componentePrincipal.push({
          idcomponentedelcomponente: 0,
          idactivo: dat.idactivo,
          idtipo_activo: dat.idtipo_activo,
          idposicionresponsablenegocio: dat.idposicionresponsablenegocio,
          idposicionresponsableti: dat.idposicionresponsableti,
          idactivocomponente: 0,
          fechacreacion: today.toISOString(),
          estadoasociacion: true,
          idusuariocreacion: localStorage.getItem("idusuario"),
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: localStorage.getItem("idusuario"),
          disp_numerico1: null,
          disp_numerico2: null,
          disp_varchar1: null,
          disp_varchar2: null,
        });
      });
      console.log("componente principal es", componentePrincipal)
      //Si el array de componentes principales está vacío, no se puede guardar
      if (componentePrincipal.length === 0) 
      {
        alert("Debe seleccionar un componente principal")
        setValidated(false);
        setEstadoPost(4);
        return;
      }

      //Si nombre es vacío, no se puede guardar
      if (document.getElementById("NombreComponente").value === "")
      {
        setValidated(false);
        setEstadoPost(4);
        return;
      }
      //Si ip o dns son vacíos, no se puede guardar
      if (document.getElementById("IP").value === "" || document.getElementById("DNS").value === "")
      {
        setValidated(false);
        setEstadoPost(4);
        return;
      }
      //Si ubicación lógica, relevancia o tipo ambiente son vacíos, no se puede guardar
      if (document.getElementById("Ubicacion_logica").value === "" || document.getElementById("relevancia").value === "" || document.getElementById("Tipo_Ambiente").value === "")
      {
        setValidated(false);
        setEstadoPost(4);
        return;
      }

      var data = {
        idcompania: localStorage.getItem("idcompania"), 
        nombre: document.getElementById("NombreComponente").value,
        descripcion: document.getElementById("Descripcion").value,
        estado: true,
        componente_principal: 0,
        ubicacion_logica:
          document.getElementById("Ubicacion_logica") !== null
            ? document.getElementById("Ubicacion_logica").value
            : null,
        relevancia:
          document.getElementById("relevancia") !== null
            ? document.getElementById("relevancia").value
            : null,
        tipo_ambiente:
          document.getElementById("Tipo_Ambiente") !== null
            ? document.getElementById("Tipo_Ambiente").value
            : null,
        ip:
          document.getElementById("IP") !== null
            ? document.getElementById("IP").value
            : "",
        dns:
          document.getElementById("DNS") !== null
            ? document.getElementById("DNS").value
            : "",
        elemento_configuracion:
          document.getElementById("codigo_unico_aplicacion") !== null
            ? document.getElementById("codigo_unico_aplicacion").value
            : "",
        fechacreacion: today.toISOString(),
        idusuariocreacion: localStorage.getItem("idusuario"),
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"),
        ComponentePrincipal: componentePrincipal,

      };
      console.log("data para post comp infra es", data)
      const objectToSend = JSON.stringify({
        ...data,
        ip: encryptData(data.ip),
        dns: encryptData(data.dns),
      });

      fetch(process.env.REACT_APP_API_URL + "onecomponent/0/", 
    { 
        method: "POST",
        body: objectToSend,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      })
        .then((data) =>
          data.json().then((response) => {
            if (data.status >= 200 && data.status < 300) {
              setEstadoPost(2);
                  localStorage.setItem("idactivo", response.idactivo);
                  history.push({
                    pathname: "/EditarComponenteInfra", //Revisar //TODO
                    state: "COM",
                  });
            } else if (data.status >= 500) {
              setEstadoPost(5);
              if (
                data.non_field_errors[0] ===
                "The fields idactivo must make a unique set."
              ) {
                setEstadoPost(6);
              }
            } else if (data.status >= 400 && data.status < 500) {
              setEstadoPost(4);
            }
          })
        )
        .catch(function (err) {});
    }
    setValidated(true);
  };

  const completarTabla = (obj, id) => {
    let temp = [];

    switch (id) {
      case "detalles":
        setComponentesSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(modalCompo.filter((dato) => dato.idactivo === obj[i])[0]);
        }
        setRowsC(temp);
        setModalShow(false);
        break;

      default:
        break;
    }
  };

  const [selectedInfra, setSelectedInfra] = useState([]);


  const isSelectedInfra = (name) => selectedInfra.indexOf(name) !== -1;

  const handleClickInfra = (event, name) => {
    const selectedIndex = selectedInfra.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      //SetButtonEdit(true);
    } else {
      //SetButtonEdit(false);
    }

    setSelectedInfra(newSelected);
  };

  function MyVerticallyCenteredModal(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [contTecno, setContTecno] = React.useState(props.componentes);
    const [activo, setActivo] = React.useState([]);
    const [ButtonEdit, SetButtonEdit] = React.useState(false);
    const [selected, setSelected] = React.useState([]);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalCompo.map((n) => n.idevaluacion);

        setContTecno(newSelecteds);
        return;
      }
      setContTecno([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {

      setContTecno([id]);
    };

    function add_eval() {
      localStorage.setItem("idactivo", 0);
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (id) => contTecno.indexOf(id) !== -1;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, modalCompo.length - page * rowsPerPage);

    const comData = stableSort(modalCompo, getComparator(order, orderBy))
      .filter((row) =>
        row.idactivo.toString().includes(queryText.toLowerCase()) ||
        row.nombre.toLowerCase().includes(queryText.toLowerCase())
      )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const final_data_ifrastructure = comData;
    
    return (
      <Modal
        {...props}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Añadir componente principal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className='buscar'>
                    <Form.Control
                      type='text'
                      placeholder='Buscar'
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                {/* <Col sm={4} xs={6}>
                  <Link to="NuevoComponente">
                    <Button
                      className="botonPositivo"
                      style={{ marginTop: "1%", width: "100%" }}
                      onClick={() => add_eval()}
                    >
                      {" "}
                      Nuevo{" "}
                    </Button>
                  </Link>
                </Col> */}
                <Col sm={4} xs={6}>
                  <Button
                    className='botonPositivo'
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => completarTabla(contTecno, "detalles")}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby='tableTitle'
                  size={dense ? "small" : "medium"}
                  aria-label='enhanced table'
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={contTecno.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalCompo.length}
                  />
                  <TableBody>
                    {final_data_ifrastructure?.map((row, index) => {
                      const isItemSelected = isSelected(row.idactivo);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          onClick={(event) =>
                            handleClick(
                              event,
                              row.idactivo,
                              row.idtipo_activo,
                              row
                            )
                          }
                          role='checkbox'
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.idactivo}
                          selected={isItemSelected}
                        >
                          <TableCell padding='checkbox'>
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component='th'
                            id={labelId}
                            scope='row'
                            padding='none'
                          >
                            {row.idactivo}
                          </TableCell>
                          <TableCell align='left'>{row.nombre}</TableCell>
                          <TableCell align='left'>{row.descripcion}</TableCell>
                          <TableCell align='left'>{row.UsuarioResponsableTI}</TableCell>
                          <TableCell align='left'>{row.UsuarioResponsableNegocio}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component='div'
                  count={modalCompo.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label='Vista compacta'
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <>
    <AlertDismissibleExample alerta={estadoPOST} />
    <MyVerticallyCenteredModal
      componentes={componentesSelected}
      show={modalShow}
      onHide={() => setModalShow(false)}
    />
    <Row>
        <Col>
          {" "}
          <h2 className='subtitulo'>Nuevo Componente de Infraestructura</h2>
          <hr />
        </Col>
      </Row>

      <Form
        id='formData'
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label forn-label'>Id Componente Infraestructura</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              disabled
              className='form-control text-center'
              placeholder='ID Automático'
              id='IDcomponente'
            ></input>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>Nombre*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              className='form-control text-center'
              placeholder='Introduzca Nombre'
              required
              id='NombreComponente'
            ></input>
            <Form.Control.Feedback type='invalid'>
              Por favor introduzca un nombre.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>Descripción</label>
          </Col>
          <Col sm={8} xs={10}>
            <textarea
              className='form-control text-center'
              placeholder='Introduzca Descripción'
              rows='3'
              id='Descripcion'
            ></textarea>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>IP*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              className='form-control text-center'
              placeholder='Introduzca la IP'
              id='IP'
              required
            ></input>
            <Form.Control.Feedback type='invalid'>
              Por favor introduzca la IP.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>DNS*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              className='form-control text-center'
              placeholder='Introduzca el DNS'
              id='DNS'
              required
            ></input>
            <Form.Control.Feedback type='invalid'>
              Por favor introduzca el DNS.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>
              Código único de aplicación
            </label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              className='form-control text-center'
              placeholder='Introduzca Código único de aplicación'
              id='codigo_unico_aplicacion'
            ></input>
            <Form.Control.Feedback type='invalid'>
              Por favor introduzca el Código único de aplicación
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={5}>
            <label className='label forn-label'>
              Ubicación lógica*
            </label>
          </Col>
          <Col sm={3} xs={5}>
            <select
              className='form-control'
              id='Ubicacion_logica'
              required
            >
              <option value=''>Seleccione...</option>
              {ubicacion_logica.map((campo) => (
                <option key={campo.id} value={campo.nombre}>
                  {campo.nombre}
                </option>
              ))}
            </select>
            <Form.Control.Feedback type='invalid'>
              Por favor seleccione la ubicación lógica.
            </Form.Control.Feedback>
          </Col>
          <Col sm={2} xs={5}>
            <label className='label forn-label'>Tipo Ambiente*</label>
          </Col>
          <Col sm={3} xs={5}>
            <select
              className='form-control'
              id='Tipo_Ambiente'
              required
            >
              <option value=''>Seleccione...</option>
              {tipo_ambiente.map((campo) => (
                <option key={campo.id} value={campo.nombre}>
                  {campo.nombre}
                </option>
              ))}
            </select>
            <Form.Control.Feedback type='invalid'>
              Por favor seleccione el tipo de ambiente.
            </Form.Control.Feedback>
          </Col>
        </Row>        
        <Row className='mb-3'>       
          <Col sm={4} xs={5} className='text-left'>
            <label className='label forn-label'>Relevancia*</label>
          </Col>
          <Col sm={3} xs={5}>
            <select
              className='form-control'
              id='relevancia'
              required
            >
              <option value=''>Seleccione...</option>
              {relevancia.map((campo) => (
                <option key={campo.id} value={campo.nombre}>
                  {campo.nombre}
                </option>
              ))}
            </select>
            <Form.Control.Feedback type='invalid'>
              Por favor seleccione la relevancia.
            </Form.Control.Feedback>
          </Col>
          
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={0}></Col>
          <Col>
            <div className='texto form-text'>* Campos obligatorios</div>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col md={6}>
            <h2 className='subtitulo'>
              Añadir Componente principal*
            </h2>
          </Col>
          <Col md={2}>
            <Button
              className='botonIngreso'
              onClick={() => setModalShow(true)}
            >
              Añadir
            </Button>{" "}
          </Col>
        </Row>
        <Row className='mb-3'>
          <TableContainer
            component={Paper}
            className='table table-bordered'
          >
            <Table className={classes.table} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox"></StyledTableCell>
                  <StyledTableCell align='left'>ID</StyledTableCell>
                  <StyledTableCell align='left'>
                    Nombre
                  </StyledTableCell>
                  <StyledTableCell align='left'>
                    Descripción
                  </StyledTableCell>
                  <StyledTableCell align='left'>
                    Responsable TI
                  </StyledTableCell>
                  <StyledTableCell align='left'>
                    Responsable Negocio
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsc !== null
                  ? rowsc.map((row, index) => {
                    const isItemSelected = isSelectedInfra(
                      row.idactivo
                    );
                    return (
                      <StyledTableRow
                        key={row.idactivo}
                        hover
                        onClick={(event) =>
                          handleClickInfra(event, row.idactivo)
                        }
                        selected={isItemSelected}
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Checkbox checked={isItemSelected} />
                        </StyledTableCell>
                        <StyledTableCell component='th' scope='row'>
                          {row.idactivo}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                          {row.nombre}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                          {row.descripcion}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                          {row.UsuarioResponsableTI}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                          {row.tipo_ambiente}
                        </StyledTableCell>
                      </StyledTableRow>
                      );
                    }
                  )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={1}></Col>
          <Col sm={3} xs={3}>
            {" "}
            <Button
              type='submit'
              className='botonPositivo'
              id='send'
            >
              Guardar
            </Button>
          </Col>
          <Col sm={3} xs={3}>
            {" "}
            <Link to='componente-infraestructura'>
              <Button className='botonNegativo'>Descartar</Button>
            </Link>
          </Col>
        </Row>
      </Form>
  </>
  );
};

export default NuevoComponenteInfra;
