import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";
import AADService from "../../funcionesAuth.js";
import { FcElectricalSensor } from "react-icons/fc";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Situación Observada",
  },
  { id: "name", numeric: false, disablePadding: false, label: "Observaciones" },
  { id: "fat", numeric: false, disablePadding: false, label: "Categoría" },
  { id: "carbs", numeric: false, disablePadding: false, label: "Código" },
  { id: "protein", numeric: false, disablePadding: false, label: "Tipo" },
  {
    id: "solucion_vul",
    numeric: false,
    disablePadding: false,
    label: "Solución de la vulnerabilidad",
  },
  {
    id: "conocimiento_vul",
    numeric: false,
    disablePadding: false,
    label: "Conocimiento de la vulnerabilidad",
  },
  {
    id: "alcance_afectacion",
    numeric: false,
    disablePadding: false,
    label: "Alcance de la afectación",
  },
  {
    id: "contexto_explotacion",
    numeric: false,
    disablePadding: false,
    label: "Contexto requerido para la afectacion",
  },
  {
    id: "vector_explotabilidad",
    numeric: false,
    disablePadding: false,
    label: "Vector explotabilidad",
  },
  {
    id: "factor_exposicion",
    numeric: false,
    disablePadding: false,
    label: "Factor de exposición",
  },
  {
    id: "colateralidad",
    numeric: false,
    disablePadding: false,
    label: "¿Existe efecto de lateralidad?",
  },
  {
    id: "criticidad_inicial",
    numeric: false,
    disablePadding: false,
    label: "Criticidad inicial frente al impacto",
  },
];

/*Encabezado de tabla*/
function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <label className='label'>{headCell.label}</label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "70vh",
    minHeight: "70vh",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "305vh",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
/*Mapeado de la tabla completa */
export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [proveedor, setProveedor] = React.useState([]);
  const [solucion_vln, setSolucion_vln] = React.useState([]);
  const [conocimiento_vln, setConocimiento_vln] = React.useState([]);
  const [alcance_vln, setAlcance_vln] = React.useState([]);
  const [contexto_vln, setContexto_vln] = React.useState([]);
  const [estadoPUT, setEstadoPUT] = React.useState(0);
  const [listadoSolucion, setListadoSolucion] = React.useState([]);
  const [listadoConocimiento, setListadoConocimiento] = React.useState([]);
  const [listadoAlcance, setListadoAlcance] = React.useState([]);
  const [listadoContexto, setListadoContexto] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  const [validRole, setValidRole] = React.useState(
    localStorage.getItem("rolusuario") == 2 ||
      localStorage.getItem("rolusuario") == 1
      ? true
      : false
  );

  function trunc(x, posiciones = 0) {
    var s = x.toString();
    var l = s.length;
    var decimalLength = s.indexOf(".") + 1;
    var numStr = s.substr(0, decimalLength + posiciones);
    return Number(numStr);
  }

  function calcularVectores(index) {
    let temp = 0;
    let vectorTemp;
    let factorTemp;
    let escribeEfectoLat;
    let vectorImpacto;

    let solucionClass = document.getElementsByClassName("solucion")[index];
    let conocimiento = document.getElementsByClassName("conocimiento")[index];
    let alcance = document.getElementsByClassName("alcance")[index];
    let contexto = document.getElementsByClassName("contexto")[index];

    if (
      parseFloat(solucionClass[solucionClass.selectedIndex].id) !== 0 &&
      parseFloat(conocimiento[conocimiento.selectedIndex].id) !== 0 &&
      parseFloat(alcance[alcance.selectedIndex].id) !== 0 &&
      parseFloat(contexto[contexto.selectedIndex].id) !== 0
    ) {
      temp =
        8.22 *
        parseFloat(solucionClass[solucionClass.selectedIndex].id) *
        parseFloat(conocimiento[conocimiento.selectedIndex].id) *
        parseFloat(alcance[alcance.selectedIndex].id) *
        parseFloat(contexto[contexto.selectedIndex].id);

      temp = trunc(temp, 6);
      vectorTemp = temp;

      if (temp !== 0) {
        factorTemp = trunc(1 / (1 + Math.exp(-3 * (temp - 1.8))), 6);
      } else {
        factorTemp = 0;
      }

      //Calcula Efecto de Lateralidad
      if (parseFloat(alcance[alcance.selectedIndex].id) == 0.85) {
        escribeEfectoLat = "SI";
      } else {
        escribeEfectoLat = "NO";
      }

      // Calcula criticidad inicial frente al impacto
      vectorImpacto =
        8.22 *
        parseFloat(conocimiento[conocimiento.selectedIndex].id) *
        parseFloat(alcance[alcance.selectedIndex].id);
      vectorImpacto = Math.round(vectorImpacto * 10) / 10;

      // Asigna valores para escribir en pantalla y la data a enviar a la API
      const escribirVec = vectorTemp.toString();
      document.getElementById("resultadosVec" + index.toString()).innerHTML =
        escribirVec;
      document.getElementById("resultadosVec" + index.toString()).value =
        escribirVec;
      const escribirFac = factorTemp.toString();
      document.getElementById("resultadosFac" + index.toString()).innerHTML =
        escribirFac;
      document.getElementById("resultadosFac" + index.toString()).value =
        escribirFac;
      const escribirEfectoLat = escribeEfectoLat;
      document.getElementById("resultadosLac" + index.toString()).innerHTML =
        escribirEfectoLat;
      const escribreImpacto = vectorImpacto;
      document.getElementById("vector_impacto" + index.toString()).innerHTML =
        escribreImpacto;
      document.getElementById("vector_impacto" + index.toString()).value =
        escribreImpacto;
    }
  }
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant='determinate' {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant='caption' component='div' color='text.secondary'>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    const fetchdata = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/vulnotec/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let param = await result.json();
        setProveedor(param);
      }
      else {
        setProveedor([]);
      }
    };
    const solucion = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "general/parametrosGeneralesxGrupoxParametro/vulnerabilidadesNoTecnicas/Remediacion_de_la_vulnerabilidad/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let param = await result.json();
      let temp = [];
      for (let i = 0; i < param.length; i++) {
        temp.push(param[i].vlrmetrica);
      }
      setListadoSolucion(temp);
      setSolucion_vln(param);
    };
    const conocimiento = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "general/parametrosGeneralesxGrupoxParametro/vulnerabilidadesNoTecnicas/Conocimiento_de_la_vulnerabilidad/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let param = await result.json();
      let temp = [];
      for (let i = 0; i < param.length; i++) {
        temp.push(param[i].vlrmetrica);
      }
      setListadoConocimiento(temp);
      setConocimiento_vln(param);
    };
    const alcance = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "general/parametrosGeneralesxGrupoxParametro/vulnerabilidadesNoTecnicas/Alcance_de_la_vulnerabilidad/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let param = await result.json();
      let temp = [];
      for (let i = 0; i < param.length; i++) {
        temp.push(param[i].vlrmetrica);
      }
      setListadoAlcance(temp);
      setAlcance_vln(param);
    };
    const contexto = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "general/parametrosGeneralesxGrupoxParametro/vulnerabilidadesNoTecnicas/Facilidad_de_explotacion_de_la_vulnerabilidad/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let param = await result.json();
      let temp = [];
      for (let i = 0; i < param.length; i++) {
        temp.push(param[i].vlrmetrica);
      }
      setListadoContexto(temp);
      setContexto_vln(param);
    };
    fetchdata();
    solucion();
    conocimiento();
    alcance();
    contexto();
  }, []);

  const sendData = (event) => {
    event.preventDefault();
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    setLoading(true);
    let dataTemp = [];
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    for (let index = 0; index < proveedor.length; index++) {
      const element = proveedor[index];
      dataTemp.push({
        idvulnerabilidad_notecnica: element.idvulnerabilidad_notecnica,
        idvulnerabilidadevaluacion: element.idvulnerabilidadevaluacion,
        idcuestionarioevaluacion: element.idcuestionarioevaluacion,
        idcategoria_vulnerabilidad: element.idcategoria_vulnerabilidad,
        slnvulnerabilidad:
          document.getElementById("solucion" + index.toString()) == null
            ? " "
            : document.getElementById("solucion" + index.toString()).value,
        conocimientovulnerabilidad:
          document.getElementById("conocimiento" + index.toString()) == null
            ? " "
            : document.getElementById("conocimiento" + index.toString()).value,
        alcanceafectacion:
          document.getElementById("alcance" + index.toString()) == null
            ? " "
            : document.getElementById("alcance" + index.toString()).value,
        contextorequeridoexplotacion:
          document.getElementById("contexto" + index.toString()) == null
            ? " "
            : document.getElementById("contexto" + index.toString()).value,
        vectorexplotabilidad:
          document.getElementById("resultadosVec" + index.toString()) == null
            ? " "
            : document.getElementById("resultadosVec" + index.toString()).value,
        factorexposicion:
          document.getElementById("resultadosFac" + index.toString()) == null
            ? " "
            : document.getElementById("resultadosFac" + index.toString()).value,
        existeefectolateralidad:
          document.getElementById("resultadosLac" + index.toString())
            .innerText == "NO"
            ? false
            : document.getElementById("resultadosLac" + index.toString())
                .innerText == "SI"
            ? true
            : false,
        vectorimpacto:
          document.getElementById("vector_impacto" + index.toString()) == null
            ? " "
            : document.getElementById("vector_impacto" + index.toString())
                .value,
        fechacreacion: element.fechacreacion,
        idusuariocreacion: element.idusuariocreacion,
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"),
        disp_numerico1: 0,
        disp_numerico2: 0,
        disp_varchar1: null,
        disp_varchar2: null,
      });
    }
    let data = JSON.stringify(dataTemp);
    fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/vulnotec/" +
        proveedor[0].idevaluacion +
        "/",
      {
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((data) =>
        data.text().then((response) => {
          if (data.status >= 200 && data.status < 300) {
            setEstadoPUT(2);
          } else if (data.status >= 500) {
            setEstadoPUT(5);
          } else if (data.status >= 400 && data.status < 500) {
            setEstadoPUT(4);
          }
          setLoading(false);
        })
      )
      .catch(function (err) {
        // console.log(err);
      });
  };
  const evaluationState = JSON.parse(localStorage.getItem("estado_evaluacion"));
  return (
    <>
      <AlertDismissibleExample alerta={estadoPUT} />
      <Form id='formData' onSubmit={(e) => sendData(e)}>
        <div className={classes.root}>
          <Row className='mb-2'>
            <Col sm={7}>
              <h2 className='subtitulo'>Vulnerabilidades no técnicas</h2>
            </Col>
            <Col sm={1}>
              {loading && <CircularProgressWithLabel value={progress} />}
            </Col>
            <Col sm={4}>
              {validRole && evaluationState ? (
                <Button className='botonPositivo' type='submit'>
                  Guardar
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <hr />
          <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby='tableTitle'
                size={dense ? "small" : "medium"}
                aria-label='enhanced table'
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {stableSort(proveedor, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      var list_solucion = [];
                      if (listadoSolucion.includes(row.slnvulnerabilidad)) {
                        list_solucion = solucion_vln.map((element) => {
                          if (row.slnvulnerabilidad == element.vlrmetrica) {
                            return (
                              <option
                                id={element.vlrnumericometrica1}
                                key={element.idparametrosgenerales}
                                selected
                              >
                                {element.vlrmetrica}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                id={element.vlrnumericometrica1}
                                key={element.idparametrosgenerales}
                              >
                                {element.vlrmetrica}
                              </option>
                            );
                          }
                        });
                      } else {
                        list_solucion = solucion_vln.map((element) => (
                          <option
                            id={element.vlrnumericometrica1}
                            key={element.idparametrosgenerales}
                          >
                            {element.vlrmetrica}
                          </option>
                        ));
                        list_solucion.push(
                          <option value=' ' selected>
                            --Solución--
                          </option>
                        );
                      }
                      var list_conocimiento = [];
                      if (
                        listadoConocimiento.includes(
                          row.conocimientovulnerabilidad
                        )
                      ) {
                        list_conocimiento = conocimiento_vln.map((element) => {
                          if (
                            row.conocimientovulnerabilidad == element.vlrmetrica
                          ) {
                            return (
                              <option
                                id={element.vlrnumericometrica1}
                                key={element.idparametrosgenerales}
                                selected
                              >
                                {element.vlrmetrica}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                id={element.vlrnumericometrica1}
                                key={element.idparametrosgenerales}
                              >
                                {element.vlrmetrica}
                              </option>
                            );
                          }
                        });
                      } else {
                        list_conocimiento = conocimiento_vln.map((element) => (
                          <option
                            id={element.vlrnumericometrica1}
                            key={element.idparametrosgenerales}
                          >
                            {element.vlrmetrica}
                          </option>
                        ));
                        list_conocimiento.push(
                          <option value=' ' selected>
                            --Conocimiento--
                          </option>
                        );
                      }
                      var list_alcance = [];
                      if (listadoAlcance.includes(row.alcanceafectacion)) {
                        list_alcance = alcance_vln.map((element) => {
                          if (row.alcanceafectacion == element.vlrmetrica) {
                            return (
                              <option
                                id={element.vlrnumericometrica1}
                                key={element.idparametrosgenerales}
                                selected
                              >
                                {element.vlrmetrica}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                id={element.vlrnumericometrica1}
                                key={element.idparametrosgenerales}
                              >
                                {element.vlrmetrica}
                              </option>
                            );
                          }
                        });
                      } else {
                        list_alcance = alcance_vln.map((element) => (
                          <option
                            id={element.vlrnumericometrica1}
                            key={element.idparametrosgenerales}
                          >
                            {element.vlrmetrica}
                          </option>
                        ));
                        list_alcance.push(
                          <option value=' ' selected>
                            --Alcance--
                          </option>
                        );
                      }
                      var list_contexto = [];
                      if (
                        listadoContexto.includes(
                          row.contextorequeridoexplotacion
                        )
                      ) {
                        list_contexto = contexto_vln.map((element) => {
                          if (
                            row.contextorequeridoexplotacion ==
                            element.vlrmetrica
                          ) {
                            return (
                              <option
                                id={element.vlrnumericometrica1}
                                key={element.idparametrosgenerales}
                                selected
                              >
                                {element.vlrmetrica}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                id={element.vlrnumericometrica1}
                                key={element.idparametrosgenerales}
                              >
                                {element.vlrmetrica}
                              </option>
                            );
                          }
                        });
                      } else {
                        list_contexto = contexto_vln.map((element) => (
                          <option
                            id={element.vlrnumericometrica1}
                            key={element.idparametrosgenerales}
                          >
                            {element.vlrmetrica}
                          </option>
                        ));
                        list_contexto.push(
                          <option value=' ' selected>
                            --Contexto--
                          </option>
                        );
                      }

                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={-1}
                          key={row.idvulnerabilidad_notecnica}
                        >
                          <TableCell padding='checkbox'>{row.id}</TableCell>
                          <TableCell
                            component='th'
                            id={labelId}
                            scope='row'
                            padding='none'
                          >
                            {row.pregunta}
                          </TableCell>
                          <TableCell align='left'>
                            {row.observaciones}
                          </TableCell>
                          <TableCell align='left'>
                            {row.categoria_vulnerabilidad}
                          </TableCell>
                          <TableCell align='left'>
                            {row.idcuestionario_tipoactivo}
                          </TableCell>
                          <TableCell align='left'>
                            {row.vulnerabilidad}
                          </TableCell>
                          <TableCell align='center'>
                            <select
                              className='form-control solucion'
                              onChange={() => calcularVectores(index)}
                              id={"solucion" + index.toString()}
                            >
                              {list_solucion}
                            </select>
                          </TableCell>
                          <TableCell align='center'>
                            <select
                              className='form-control conocimiento'
                              onChange={() => calcularVectores(index)}
                              id={"conocimiento" + index.toString()}
                            >
                              {list_conocimiento}
                            </select>
                          </TableCell>
                          <TableCell align='center'>
                            <select
                              className='form-control alcance'
                              onChange={() => calcularVectores(index)}
                              id={"alcance" + index.toString()}
                            >
                              {list_alcance}
                            </select>
                          </TableCell>
                          <TableCell align='center'>
                            <select
                              className='form-control contexto'
                              onChange={() => calcularVectores(index)}
                              id={"contexto" + index.toString()}
                            >
                              {list_contexto}
                            </select>
                          </TableCell>
                          <TableCell
                            align='center'
                            id={"resultadosVec" + index.toString()}
                          >
                            {row.vectorexplotabilidad != 0
                              ? parseFloat(row.vectorexplotabilidad).toFixed(2)
                              : ""}
                          </TableCell>
                          <TableCell
                            align='center'
                            id={"resultadosFac" + index.toString()}
                          >
                            {row.factorexposicion != 0
                              ? parseFloat(row.factorexposicion).toFixed(2)
                              : ""}
                          </TableCell>
                          <TableCell
                            align='center'
                            id={"resultadosLac" + index.toString()}
                          >
                            {row.existeefectolateralidad == true ? "SI" : "NO"}
                          </TableCell>
                          <TableCell
                            align='center'
                            id={"vector_impacto" + index.toString()}
                          >
                            {row.vectorimpacto != 0
                              ? parseFloat(row.vectorimpacto).toFixed(2)
                              : ""}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Form>
    </>
  );
}
