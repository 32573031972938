import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";
import { without, filter } from "lodash";
import AADService from "../funcionesAuth.js";
//Librerias de la tabla
import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";

import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions";
//Finaliza las librerias de la tabla

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}
//procesos header
const headCellsProcesos = [
  {
    id: "idsubproceso",
    numeric: false,
    disablePadding: true,
    label: "Id Subproceso",
  },
  {
    id: "macroproceso",
    numeric: false,
    disablePadding: false,
    label: "macroproceso",
  },
  { id: "proceso", numeric: false, disablePadding: false, label: "proceso" },
  {
    id: "subproceso",
    numeric: false,
    disablePadding: false,
    label: "subproceso",
  },
  { id: "estado", numeric: false, disablePadding: false, label: "estado" },
];
//activos header
const headCells = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "nombre", numeric: false, disablePadding: false, label: "Nombre" },
  {
    id: "descripcion",
    numeric: false,
    disablePadding: false,
    label: "Descripción",
  },
];
//////////////////////Tabla dentro del Modal
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHeadProcesos(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding='checkbox'
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCellsProcesos.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className='label'
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHeadProcesos.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding='checkbox'
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className='label'
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));
//////////////////////Fin Tabla dentro del Modal
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant='h6'
          id='tableTitle'
          component='div'
        >
          Evaluaciones
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title='Delete'>
          <IconButton aria-label='delete' size='large'>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title='Filter list'>
          <IconButton aria-label='filter list' size='large'>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "55vh",
    minHeight: "55vh",
  },
}));
//////////////////////Fin Tabla dentro del Modal

//Empieza tabla de activos
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",

    color: "white",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
//Finaliza tabla de activos

const EditarTecnologia = () => {
  const classes = useStyles();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowProcesos, setModalShowProcesos] = React.useState(false);

  const [activo, setActivo] = React.useState([]);
  const [companiaDat, setCompaniaDat] = React.useState([]);
  const [usuariosDatNeg, setUsuariosDatNeg] = React.useState([]);
  const [usuariosDatTI, setUsuariosDatTI] = React.useState([]);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [modalInfo, setModalInfo] = React.useState([]);
  let history = useHistory();
  const [validated, setValidated] = useState(false);
  const [datContenidos, setDatContenidos] = React.useState([]);
  //////////////////////////////datos tablas
  const [rowsc, setRowsC] = React.useState([]);
  const [rowsCompInit, setRowsCompInit] = React.useState([]);
  const [rowsP, setRowsP] = React.useState([]);
  const [rowsProcInit, setRowsProcInit] = React.useState([]);
  const [procesosSelected, setProcesosSelected] = React.useState([]);
  const [componentesSelected, setComponentesSelected] = React.useState([]);
  const [modalCompo, setModalCompo] = useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  useEffect(() => {
    //////////////////////Datos del JSON
    const obtenerComponentes = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "activosTipo/10/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datComponentes = await result.json();
      datComponentes = datComponentes.filter(
        (dato) => dato.componente_principal === true
      );

      setModalCompo(datComponentes);
    };
    const obtenerProceso = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "general/procesosxCompania/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datProcesos = await result.json();
      setModalInfo(datProcesos);
    };
    const obtenerActivo = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "activoInformacion/" +
          localStorage.getItem("idactivo") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encrypteddatActivo = await result.json();
      const decrypteddatActivo = DecryptData(encrypteddatActivo);
      let datActivo = JSON.parse(decrypteddatActivo);
      let temp = [];
      let temp2 = [];
      let tempRowC = [];
      let tempRowP = [];
      if (datActivo.ComponentesAsociados !== null) {
        for (let i = 0; i < datActivo.ComponentesAsociados.length; i++) {
          const element = datActivo.ComponentesAsociados[i];
          if (element.estadoasociacion == true) {
            tempRowC.push(element);
            temp.push(element.idcomponente);
          }
        }
      }
      if (datActivo.ProcesosAsociados) {
        for (let i = 0; i < datActivo.ProcesosAsociados.length; i++) {
          const element = datActivo.ProcesosAsociados[i];
          if (element.estadoasociacion == true) {
            tempRowP.push(element);
            temp2.push(element.idsubproceso);
          }
        }
      }

      if (datActivo.datos_en_el_activo !== null) {
        setDatContenidos(datActivo.datos_en_el_activo.split(","));
      }
      setComponentesSelected(temp);
      setProcesosSelected(temp2);
      setRowsC(tempRowC);
      setRowsCompInit(datActivo.ComponentesAsociados);
      setRowsP(tempRowP);
      setRowsProcInit(datActivo.ProcesosAsociados);
      setActivo(datActivo);
      setIdState(datActivo.estado);
      if (datActivo.estado == true) {
        setState("Activo");
      } else {
        setState("Inactivo");
      }
    };
    const obtenerCompañias = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datCompania = await result.json();
      setCompaniaDat(datCompania);
    };
    const obtenerUsuariosNeg = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuariosrolneg/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();
      setUsuariosDatNeg(datUsuarios);
    };
    const obtenerUsuariosTI = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuariosrolti/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();
      setUsuariosDatTI(datUsuarios);
    };
    obtenerComponentes();
    obtenerProceso();
    obtenerActivo();
    obtenerUsuariosNeg();
    obtenerUsuariosTI();
    obtenerCompañias();
  }, []);

  /////// Añadir tags para los componentes asociados
  const removeTag = (i) => {
    const newTags = [...datContenidos];
    newTags.splice(i, 1);
    setDatContenidos(newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      e.preventDefault();
      setValidated(false);
      if (
        datContenidos.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      setDatContenidos([...datContenidos, val]);
      let inputTag = document.getElementById("datosSeparados");
      inputTag.value = null;
    } else if (e.key === "Backspace" && !val) {
      removeTag(datContenidos.length - 1);
    }
  };

  const [state, setState] = useState("Activo");
  const [idState, setIdState] = useState(true);

  function bCompania(id) {
    for (const clave in companiaDat) {
      if (id == companiaDat[clave]["idcompania"]) {
        return companiaDat[clave]["compania"];
      }
    }
    return "No Existe";
  }

  const handleChangeState = (event) => {
    if (state == "Activo") {
      setState("Inactivo");
      setIdState(false);
    } else {
      setState("Activo");
      setIdState(true);
    }
  };

  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let componentesAsociadoTemp = [];
      let rowscT;
      let rowspT;
      let actualizarT = [];
      let actualizarF = [];
      let nuevos = rowsc;
      if (rowsProcInit != null) {
        rowspT = rowsProcInit.filter((dato) => dato.estadoasociacion == true);
      }
      if (rowsCompInit != null) {
        rowscT = rowsCompInit.filter((dato) => dato.estadoasociacion == true);
      }

      //-//-// Obtener los nuevos seleccionados
      if (rowsCompInit !== null && nuevos !== null) {
        rowsCompInit.map((row) => {
          nuevos = filter(nuevos, (o) => o.idactivo != row.idcomponente);
          nuevos = filter(nuevos, (o) => o.idcomponente != row.idcomponente);
        });
      }
      //-//-// Obtener los que se deben Actualizar a False
      if (rowscT != null && rowsc != null) {
        actualizarF = rowscT;
        rowsc.map((row) => {
          actualizarF = filter(
            actualizarF,
            (o) => o.idcomponente != row.idactivo
          );
        });
      }
      //-//-// Obtener los que se deben Actualizar a True
      if (rowsCompInit != null) {
        rowsCompInit.map((row) => {
          rowsc.map((act) => {
            if (
              row.estadoasociacion == false &&
              row.idcomponente == act.idactivo
            ) {
              actualizarT.push(row);
            }
          });
        });
      }

      if (actualizarT !== null) {
        actualizarT.map((obj) => {
          componentesAsociadoTemp.push({
            idcomponenteasociado: obj.idcomponenteasociado,
            idactivo: obj.idactivo,
            idactivocomponente: obj.idcomponente,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: true,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }
      if (actualizarF !== null) {
        actualizarF.map((obj) => {
          componentesAsociadoTemp.push({
            idcomponenteasociado: obj.idcomponenteasociado,
            idactivo: obj.idactivo,
            idactivocomponente: obj.idcomponente,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: false,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }
      if (nuevos !== null) {
        nuevos.map((obj) => {
          componentesAsociadoTemp.push({
            idcomponenteasociado: 0,
            idactivo: parseInt(document.getElementById("IDactivo").value),
            idactivocomponente: obj.idactivo,
            fechacreacion: today.toISOString(),
            estadoasociacion: true,
            idusuariocreacion: localStorage.getItem("idusuario"),
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }
      //-//-// Creación para los procesos asociados
      let procesosAsociadosTemp = [];
      nuevos = rowsP;
      actualizarT = [];
      actualizarF = [];
      //-//-// Nuevos procesos
      if (rowsProcInit !== null && nuevos !== null) {
        rowsProcInit.map((row) => {
          nuevos = filter(nuevos, (o) => o.idsubproceso != row.idsubproceso);
        });
      }
      //-//-// Obtener los que se deben Actualizar a False
      if (rowspT !== null && rowspT !== undefined) {
        actualizarF = rowspT;
        rowsP.map((row) => {
          actualizarF = filter(
            actualizarF,
            (o) => o.idsubproceso != row.idsubproceso
          );
        });
      }
      //-//-// Obtener los que se deben Actualizar a True
      if (rowsProcInit !== null && rowsP !== null) {
        rowsProcInit.map((row) => {
          rowsP.map((act) => {
            if (
              row.estadoasociacion == false &&
              row.idsubproceso == act.idsubproceso
            ) {
              actualizarT.push(row);
            }
          });
        });
      }

      if (actualizarT !== null) {
        actualizarT.map((obj) => {
          procesosAsociadosTemp.push({
            idprocesoasociado: obj.idprocesoasociado,
            idsubproceso: obj.idsubproceso,
            idactivo: obj.idactivo,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: true,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }
      if (actualizarF !== null) {
        actualizarF.map((obj) => {
          procesosAsociadosTemp.push({
            idprocesoasociado: obj.idprocesoasociado,
            idsubproceso: obj.idsubproceso,
            idactivo: obj.idactivo,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: false,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }
      if (nuevos !== null) {
        nuevos.map((obj) => {
          procesosAsociadosTemp.push({
            idprocesoasociado: 0,
            idsubproceso: obj.idsubproceso,
            idactivo: parseInt(document.getElementById("IDactivo").value),
            fechacreacion: today.toISOString(),
            estadoasociacion: true,
            idusuariocreacion: localStorage.getItem("idusuario"),
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }

      var data = JSON.stringify({
        idactivo: document.getElementById("IDactivo").value,
        idcompania: parseInt(document.getElementById("Compania").value),
        idtipo_activo: 16,
        nombre: document.getElementById("NombreActivo").value,
        idposicionresponsableti: parseInt(
          document.getElementById("responsableTI").value
        ),
        idposicionresponsablenegocio: parseInt(
          document.getElementById("ResponsableNeg").value
        ),
        descripcion: document.getElementById("Descripcion").value,
        datos_en_el_activo: document.getElementById("datosSeparados").value,
        estado: idState,
        componente_principal: true,
        clasificacion_confidencialidad: null,
        clasificacion_integridad: null,
        clasificacion_disponibilidad: null,
        nivel_criticidad: null,
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"),
        ComponentesAsociados: componentesAsociadoTemp,
        ProveedoresAsociados: "",
        ProductoServiciosAsociados: "",
        ProcesosAsociados: procesosAsociadosTemp,
        ComponentesInfraestructura: "",
      });

      fetch(
        process.env.REACT_APP_API_URL +
          "activoInformacion/" +
          document.getElementById("IDactivo").value +
          "/",
        {
          method: "PUT",
          body: data,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((data) =>
          data.json().then((response) => {
            if (data.status >= 200 && data.status < 300) {
              setEstadoPost(2);
              localStorage.setItem("idactivo", response.idactivo);
              history.push("/EditarTecnologia");
            } else if (data.status >= 500) {
              setEstadoPost(5);
              if (
                data.non_field_errors[0] ===
                "The fields idactivo must make a unique set."
              ) {
                setEstadoPost(6);
              }
            } else if (data.status >= 400 && data.status < 500) {
              setEstadoPost(4);
            }
          })
        )
        .catch(function (err) {});
    }
    setValidated(true);
  };

  /////////////Procesos
  function MyVerticallyCenteredModalProcesos(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [procesosTemp, setProcesosTemp] = React.useState(props.procesos);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalCompo.map((n) => n.idevaluacion);
        setProcesosTemp(newSelecteds);
        return;
      }
      setProcesosTemp([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {
      const selectedIndex = procesosTemp.indexOf(id);

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(procesosTemp, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(procesosTemp.slice(1));
      } else if (selectedIndex === procesosTemp.length - 1) {
        newSelected = newSelected.concat(procesosTemp.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          procesosTemp.slice(0, selectedIndex),
          procesosTemp.slice(selectedIndex + 1)
        );
      }
      setProcesosTemp(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (id) => procesosTemp.indexOf(id) !== -1;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, modalCompo.length - page * rowsPerPage);

    return (
      <Modal
        {...props}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Añadir Procesos asociados a la tecnología
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className='buscar'>
                    <Form.Control
                      type='text'
                      placeholder='Buscar'
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                {/* <Col sm={4} xs={6}>
                  <Link to="NuevoComponente">
                    <Button
                      className="botonPositivo"
                      style={{ marginTop: "1%", width: "100%" }}
                    >
                      {" "}
                      Nuevo{" "}
                    </Button>
                  </Link>
                </Col> */}
                <Col sm={4} xs={6}>
                  <Button
                    className='botonPositivo'
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => completarTabla(procesosTemp, "procesos")}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby='tableTitle'
                  size={dense ? "small" : "medium"}
                  aria-label='enhanced table'
                >
                  <EnhancedTableHeadProcesos
                    classes={classes}
                    numSelected={procesosTemp.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalInfo.length}
                  />
                  <TableBody>
                    {stableSort(modalInfo, getComparator(order, orderBy))
                      .filter((row) =>
                        row.idsubproceso
                          .toLowerCase()
                          .includes(queryText.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idsubproceso);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(event, row.idsubproceso)
                            }
                            role='checkbox'
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idsubproceso}
                            selected={isItemSelected}
                          >
                            <TableCell padding='checkbox'>
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component='th'
                              id={labelId}
                              scope='row'
                              padding='none'
                            >
                              {row.idsubproceso}
                            </TableCell>
                            <TableCell align='left'>
                              {row.macroproceso}
                            </TableCell>
                            <TableCell align='left'>{row.proceso}</TableCell>
                            <TableCell align='left'>{row.subproceso}</TableCell>
                            <StyledTableCell align='center'>
                              <Checkbox
                                inputProps={{ "aria-label": "Checkbox A" }}
                                checked={row.estado}
                              />
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={modalInfo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label='Vista compacta'
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  ///////////// Componetes
  function MyVerticallyCenteredModal(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [contTecno, setContTecno] = React.useState(props.componentes);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalCompo.map((n) => n.idevaluacion);
        setContTecno(newSelecteds);
        return;
      }
      setContTecno([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {
      const selectedIndex = contTecno.indexOf(id);

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(contTecno, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(contTecno.slice(1));
      } else if (selectedIndex === contTecno.length - 1) {
        newSelected = newSelected.concat(contTecno.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          contTecno.slice(0, selectedIndex),
          contTecno.slice(selectedIndex + 1)
        );
      }
      setContTecno(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (id) => contTecno.indexOf(id) !== -1;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, modalCompo.length - page * rowsPerPage);

    return (
      <Modal
        {...props}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Añadir elementos tecnológicos asociados a la tecnología
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className='buscar'>
                    <Form.Control
                      type='text'
                      placeholder='Buscar'
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                {/* <Col sm={4} xs={6}>
                  <Link to="NuevoComponente">
                    <Button
                      className="botonPositivo"
                      style={{ marginTop: "1%", width: "100%" }}
                    >
                      {" "}
                      Nuevo{" "}
                    </Button>
                  </Link>
                </Col> */}
                <Col sm={4} xs={6}>
                  <Button
                    className='botonPositivo'
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => completarTabla(contTecno, "detalles")}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby='tableTitle'
                  size={dense ? "small" : "medium"}
                  aria-label='enhanced table'
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={contTecno.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalCompo.length}
                  />
                  <TableBody>
                    {stableSort(modalCompo, getComparator(order, orderBy))
                      .filter((row) =>
                        row.idactivo
                          .toString()
                          .includes(queryText.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idactivo);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(
                                event,
                                row.idactivo,
                                row.idtipo_activo,
                                row
                              )
                            }
                            role='checkbox'
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idactivo}
                            selected={isItemSelected}
                          >
                            <TableCell padding='checkbox'>
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component='th'
                              id={labelId}
                              scope='row'
                              padding='none'
                            >
                              {row.idactivo}
                            </TableCell>
                            <TableCell align='left'>{row.nombre}</TableCell>
                            <TableCell align='left'>
                              {row.descripcion}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={modalCompo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label='Vista compacta'
            />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
  //TODO: completar función para encontrar con ID obeto y llenar tabla
  const completarTabla = (obj, id) => {
    let temp = [];

    switch (id) {
      case "procesos":
        setProcesosSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(modalInfo.filter((dato) => dato.idsubproceso == obj[i])[0]);
        }
        setRowsP(temp);
        setModalShowProcesos(false);
        break;
      case "detalles":
        setComponentesSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(modalCompo.filter((dato) => dato.idactivo === obj[i])[0]);
        }
        setRowsC(temp);
        setModalShow(false);
        break;

      default:
        break;
    }
  };
  //Fin del Modal

  return (
    <>
      <AlertDismissibleExample alerta={estadoPOST} />
      <MyVerticallyCenteredModal
        componentes={componentesSelected}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <MyVerticallyCenteredModalProcesos
        procesos={procesosSelected}
        show={modalShowProcesos}
        onHide={() => setModalShowProcesos(false)}
      />
      <Row>
        <Col>
          {" "}
          <h2 className='subtitulo'>Editar Tecnología Emergente</h2>
          <br />
        </Col>
      </Row>
      <hr />
      <Form
        id='formData'
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label forn-label'>Id Tecnología Emergente</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              disabled
              className='form-control text-center font-weight-bold'
              defaultValue={activo.idactivo}
              id='IDactivo'
            ></input>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>
              Nombre Tecnología Emergente*
            </label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type='text'
              className='form-control text-center'
              placeholder='Nombre del Activo'
              defaultValue={activo.nombre}
              required
              id='NombreActivo'
            ></input>
            <Form.Control.Feedback type='invalid'>
              Por favor introduzca un nombre.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>Descripción</label>
          </Col>
          <Col sm={8} xs={12}>
            <textarea
              className='form-control text-center'
              placeholder='Descripción de la Tecnología Emergente'
              rows='3'
              defaultValue={activo.descripcion}
              id='Descripcion'
            ></textarea>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label form-label'>
              Datos contenidos en el dominio
            </label>
          </Col>
          <Col sm={8} xs={12}>
            <div className='input-tag' placeholder='Datos separados...'>
              <ul className='input-tag__tags'>
                {datContenidos.map((tag, i) => (
                  <li key={tag}>
                    {tag}
                    <button
                      type='button'
                      onClick={() => {
                        removeTag(i);
                      }}
                    >
                      +
                    </button>
                  </li>
                ))}
                <li className='input-tag__tags__input'>
                  <input
                    id='datosSeparados'
                    type='text'
                    onKeyDown={(e) => {
                      inputKeyDown(e);
                    }}
                  />
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className='mb-3 '>
          <Col sm={4} xs={12}>
            <label className='label forn-label'>Compañía*</label>
          </Col>
          <Col sm={8} xs={10}>
            <select className='form-control' required id='Compania'>
              <option value={activo.idcompania}>
                {bCompania(activo.idcompania)}
              </option>
              {companiaDat.map((compa) =>
                bCompania(activo.idcompania) !== compa.compania ? (
                  <option key={compa.idcompania} value={compa.idcompania}>
                    {compa.compania}
                  </option>
                ) : null
              )}
            </select>
            <Form.Control.Feedback type='invalid'>
              Por favor seleccione una compañía.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label forn-label'>
              Responsable en tecnología*
            </label>
          </Col>
          <Col sm={8} xs={10}>
            <select className='form-control' required id='responsableTI'>
              <option value={activo.idposicionresponsableti}>
                {activo.UsuarioResponsableTI}
              </option>
              {usuariosDatTI.map((usuario) =>
                activo.UsuarioResponsableTI !== usuario.nombre ? (
                  <option key={usuario.idusuario} value={usuario.idposicion}>
                    {usuario.nombre}
                  </option>
                ) : null
              )}
            </select>
            <Form.Control.Feedback type='invalid'>
              Por favor seleccione un responsable.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={12}>
            <label className='label forn-label'>Responsable del negocio</label>
          </Col>
          <Col sm={8} xs={10}>
            <select className='form-control' id='ResponsableNeg'>
              <option value={activo.idposicionresponsablenegocio}>
                {activo.UsuarioResponsableNegocio}
              </option>
              {usuariosDatNeg.map((usuario) =>
                activo.UsuarioResponsableNegocio !== usuario.nombre ? (
                  <option key={usuario.idusuario} value={usuario.idposicion}>
                    {usuario.nombre}
                  </option>
                ) : null
              )}
            </select>
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col sm={4} xs={4}>
            <label className='label forn-label'>Estado</label>
          </Col>
          <Col>
            <FormControlLabel
              id='switch'
              control={<Switch checked={idState} />}
              label={state}
              onChange={handleChangeState}
              name='Estado'
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={0}></Col>
          <Col>
            <div className='texto form-text'>* Campos obligatorios</div>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={4} xs={1}></Col>
          <Col sm={3} xs={3}>
            {" "}
            {/* <a href="Dominio de Información"> */}
            <Button type='submit' className='botonPositivo' id='send'>
              Guardar
            </Button>
            {/* </a> */}
          </Col>
          <Col sm={3} xs={3}>
            {" "}
            <Link to='tecnologia-emergente'>
              <Button className='botonNegativo'>Descartar</Button>
            </Link>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <br />
          </Col>
        </Row>
        <hr />
        <Row className='mb-3'>
          <Col md={10}>
            <h3 className='subtitulo'>
              Detalle contenedores y elementos tecnológicos asociados a la
              tecnología
            </h3>
          </Col>
          <Col md={2}>
            <Button
              className='botonIngreso'
              onClick={() => setModalShow(true)}
              style={{ width: "100%" }}
            >
              Añadir
            </Button>{" "}
          </Col>
        </Row>
        <Row className='mb-3'>
          <TableContainer component={Paper} className='table table-bordered'>
            <Table className={classes.table} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell align='left'>ID</StyledTableCell>
                  <StyledTableCell align='left'>Nombre</StyledTableCell>
                  <StyledTableCell align='left'>
                    Fecha modificación
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsc !== null && rowsc !== undefined
                  ? rowsc.map((row, index) =>
                      row !== undefined ? (
                        <StyledTableRow key={index}>
                          <StyledTableCell component='th' scope='row'>
                            {row.idactivo == activo.idactivo
                              ? row.idcomponente
                              : row.idactivo}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {row.nombrecomponente == null
                              ? row.nombre
                              : row.nombrecomponente}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {row.fechamodificacion}
                          </StyledTableCell>
                        </StyledTableRow>
                      ) : null
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
        <Row className='mb-3'>
          <Col md={10}>
            <h3 className='subtitulo'>Procesos asociados a la tecnología ​</h3>
          </Col>
          <Col md={2}>
            <Button
              className='botonIngreso'
              onClick={() => setModalShowProcesos(true)}
              style={{ width: "100%" }}
            >
              Añadir
            </Button>{" "}
          </Col>
        </Row>
        {/* 
      <Row>
        <Col md={10}>
            <p style={{fontStyle: "italic"}}>
              Tenga cuidado, cuando añada un proceso este no se podrá eliminar, solo se cambiará su estado
              <WarningIcon style={{ color: "#FFC107" }} />
              </p>
        </Col>
      </Row> */}
        <Row className='mb-3'>
          <TableContainer component={Paper} className='table table-bordered'>
            <Table className={classes.table} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID Proceso</StyledTableCell>
                  <StyledTableCell align='left'>
                    Nombre del Proceso
                  </StyledTableCell>
                  <StyledTableCell align='left'>Macroproceso</StyledTableCell>
                  <StyledTableCell align='left'>Subroceso</StyledTableCell>
                  {/* <StyledTableCell align="left">Estado del Proceso</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsP !== null && rowsP !== undefined
                  ? rowsP.map((row, index) =>
                      row.estadoasociacion !== false ? (
                        <StyledTableRow key={index}>
                          <StyledTableCell component='th' scope='row'>
                            {row.idsubproceso}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {row.proceso}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {row.macroproceso}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {row.subproceso}
                          </StyledTableCell>
                          {/* <StyledTableCell align="left"><Checkbox
                                            value="checkedA"
                                            inputProps={{ 'aria-label': 'Checkbox A' }}
                                            checked={row.estado}/>
            </StyledTableCell> */}
                        </StyledTableRow>
                      ) : null
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
        {/*  <Row className="mb-3">
        <Col md={10}>
            <h3>Añadir a canales de activo</h3>
        </Col>
        <Col md={2}>
          <Button variant="warning"  style={{borderRadius: "30px", width: "100%"}}>Añadir</Button>{' '}
        </Col>
      </Row>
      <Row  className="mb-3">
      <TableContainer component={Paper} className="table table-bordered">
    <Table className={classes.table} aria-label="customized table" >
      <TableHead>
        <TableRow>
          <StyledTableCell>ID Canal</StyledTableCell>
          <StyledTableCell align="right">Nombre del Canal</StyledTableCell>
          <StyledTableCell align="right">Estado del canal</StyledTableCell>
          <StyledTableCell align="right">Campo 1</StyledTableCell>
          <StyledTableCell align="right">Campo 2</StyledTableCell>
          <StyledTableCell align="right">Fecha de acción</StyledTableCell>
          <StyledTableCell align="right">Estado de asociación</StyledTableCell>
          <StyledTableCell align="right">Fecha Inactivación</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows1.map((row, index) => (
          <StyledTableRow key={index}>
            <StyledTableCell component="th" scope="row">
              {row.encabezado1}
            </StyledTableCell>
            <StyledTableCell align="right">{row.encabezado2}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado3}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado4}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado5}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado6}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado7}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado8}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
      </Row>
      <Row className="mb-3">
        <Col md={10}>
            <h3>Añadir productos al activo</h3>
        </Col>
        <Col md={2}>
          <Button variant="warning"  style={{borderRadius: "30px", width: "100%"}}>Añadir</Button>{' '}
        </Col>
      </Row>
      <Row  className="mb-3">
      <TableContainer component={Paper} className="table table-bordered">
    <Table className={classes.table} aria-label="customized table" >
      <TableHead>
        <TableRow>
          <StyledTableCell>ID Producto</StyledTableCell>
          <StyledTableCell align="right">Nombre del Producto</StyledTableCell>
          <StyledTableCell align="right">Estado del Producto</StyledTableCell>
          <StyledTableCell align="right">Campo 1</StyledTableCell>
          <StyledTableCell align="right">Campo 2</StyledTableCell>
          <StyledTableCell align="right">Fecha de acción</StyledTableCell>
          <StyledTableCell align="right">Estado de asociación</StyledTableCell>
          <StyledTableCell align="right">Fecha Inactivación</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows2.map((row, index) => (
          <StyledTableRow key={index}>
            <StyledTableCell component="th" scope="row">
              {row.encabezado1}
            </StyledTableCell>
            <StyledTableCell align="right">{row.encabezado2}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado3}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado4}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado5}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado6}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado7}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado8}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
      </Row>
      <Row className="mb-3">
        <Col md={10}>
            <h3>Usuarios interesados</h3>
        </Col>
        <Col md={2}>
          <Button variant="warning"  style={{borderRadius: "30px", width: "100%"}}>Añadir</Button>{' '}
        </Col>
      </Row>
      <Row  className="mb-3">
      <TableContainer component={Paper} className="table table-bordered">
    <Table className={classes.table} aria-label="customized table" >
      <TableHead>
        <TableRow>
          <StyledTableCell>ID Usuario</StyledTableCell>
          <StyledTableCell align="right">Nombre del Usuario</StyledTableCell>
          <StyledTableCell align="right">Tipo Interesado</StyledTableCell>
          <StyledTableCell align="right">Alcance de interes</StyledTableCell>
          <StyledTableCell align="right">Campo 2</StyledTableCell>
          <StyledTableCell align="right">Fecha de acción</StyledTableCell>
          <StyledTableCell align="right">Estado de asociación</StyledTableCell>
          <StyledTableCell align="right">Fecha Inactivación</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows3.map((row, index) => (
          <StyledTableRow key={index}>
            <StyledTableCell component="th" scope="row">
              {row.encabezado1}
            </StyledTableCell>
            <StyledTableCell align="right">{row.encabezado2}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado3}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado4}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado5}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado6}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado7}</StyledTableCell>
            <StyledTableCell align="right">{row.encabezado8}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
      </Row> */}
      </Form>
    </>
  );
};

export default EditarTecnologia;
