import React, { useState, useRef } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Collapse } from "@mui/material";
import { Link } from "react-router-dom";

function SidebarItem({
  url_opcion,
  opcion,
  items,
  depthStep = 10,
  depth = 0,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (e) => {
    const target = e.target;
    const value = target.in;
    setIsOpen(!isOpen);
  };
  if (depth === 0) {
    return (
      <>
        <ListItem button dense {...rest} onClick={toggle}>
          <ListItemText style={{ paddingLeft: depth * depthStep }}>
            <span>
              <Link
                className="label"
                to={url_opcion}
                style={{ color: "#FFD700" }}
              >
                + {opcion}
              </Link>
            </span>
          </ListItemText>
        </ListItem>

        {Array.isArray(items) ? (
          <Collapse in={isOpen}>
            <List disablePadding dense>
              {items.map((subItem) => (
                <SidebarItem
                  key={subItem.idopcion}
                  depth={depth + 1}
                  depthStep={depthStep}
                  {...subItem}
                />
              ))}
            </List>
          </Collapse>
        ) : null}
      </>
    );
  } else {
    return (
      <>
        <ListItem button dense {...rest} onClick={toggle}>
          <ListItemText style={{ paddingLeft: depth * depthStep }}>
            <span>
              <Link
                to={url_opcion}
                className="texto"
                style={{ color: "#ffffff" }}
              >
                {opcion}
              </Link>
            </span>
          </ListItemText>
        </ListItem>

        {Array.isArray(items) ? (
          <Collapse in={isOpen}>
            <List disablePadding dense>
              {items.map((subItem) => (
                <SidebarItem
                  key={subItem.idopcion}
                  depth={depth + 1}
                  depthStep={depthStep}
                  {...subItem}
                />
              ))}
            </List>
          </Collapse>
        ) : null}
      </>
    );
  }
}

function Sidebar({ items, depthStep, depth, orden_menu }) {
  orden_menu = parseInt(orden_menu, 10);
  if (orden_menu !== 0) {
    items = items.filter((item) => item.orden_menu != 0);
    //items = items.map((item) => item.items.filter(i => i.orden_menu !== "0.000000"))
  } else {
    items = items.filter((item) => item.orden_menu == 0);
  }

  return (
    <div className="sidebar">
      <List disablePadding dense>
        {items.map((sidebarItem, index) => (
          <>
            <SidebarItem
              key={`${sidebarItem.idopcion}${index}`}
              depthStep={depthStep}
              depth={depth}
              {...sidebarItem}
            />
          </>
        ))}
      </List>
    </div>
  );
}

export default Sidebar;
