import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";



function AlertDismissibleExample({ alerta }) {
    switch (alerta) {
        case 1:
            return <Alert variant="warning">Alerta</Alert>;
            break;
        case 2:
            return <Alert variant="success">Guardó exitosamente la informacióm del control</Alert>;
            break;
        case 3:
            return <Alert variant="danger"></Alert>;
            break;
        case 4:
            return <Alert variant="warning">Error al enviar la información del control</Alert>;
            break;
        case 5:
            return <Alert variant="danger">Error en el servidor</Alert>;
            break;
        default:
            return <p></p>;
            break;
    }
}


const opcion_categoria_control = [
    { id: "1", nombre: "Controles generales de ciber seguridad" },
    { id: "2", nombre: "Controles específicos sobres los componentes tecnológicos" },
    { id: "3", nombre: "Controles específicos sobre los datos" },
];
const opcion_tipo_control = [
    { id: "1", nombre: "Correctivos" },
    { id: "2", nombre: "Detectivos" },
    { id: "3", nombre: "Preventivos" },
];
const opcion_capa_control = [
    { id: "1", nombre: "Gobierno de seguridad y conciencia del personal" },
    { id: "2", nombre: "Aplicaciones y sistemas de información" },
    { id: "3", nombre: "Perímetro y red de datos" },
    { id: "4", nombre: "Archivos y datos" },
    { id: "5", nombre: "Plataformas tecnológicas" },
    { id: "6", nombre: "Respuesta a los eventos de seguridad" },
    { id: "7", nombre: "Almacenamiento" },
    { id: "8", nombre: "Acceso físico a las instalaciones de procesamiento" },
];
const opcion_framework_nist_control = [
    { id: "1", nombre: "Proteger" },
    { id: "2", nombre: "Identificar" },
    { id: "3", nombre: "Detectar" },
    { id: "4", nombre: "Responder" },
];
const opcion_habilitador_control = [
    { id: "1", nombre: "Control embebido en el componente" },
    { id: "2", nombre: "Proceso/Actividad manual" },
    { id: "3", nombre: "Proceso/Tecnología de control" },
];

const EditarControles = (props) => {
    const [infocontrol, setInfocontrol] = React.useState([]);
    const [estadoPOST, setEstadoPost] = React.useState(0);
    const [validated, setValidated] = useState(false);
    const [checkedEstado, setCheckedEstado] = useState(true);
    const [stateEstado, setStateEstado] = useState("");
    const [checkedImplementador, setCheckedImplementador] = useState(true);
    const [stateImplementador, setStateImplementador] = useState("");
    const [checkedTransversal, setCheckedTransversal] = useState(true);
    const [stateTransversal, setStateTransversal] = useState("");
    const [checkedControlProveedor, setCheckedControlProveedor] = useState(true);
    const [stateControlProveedor, setStateControlProveedor] = useState("");


    useEffect(() => {
        const obtenerControl = async () => {
            const result = await fetch(
                process.env.REACT_APP_API_URL +
                "maestro/controles/" +
                localStorage.getItem("idcontrol") +
                "/",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        //Authorization: "Bearer " + token,
                    },
                }
            );
            let datProcesos = await result.json();
            setInfocontrol(datProcesos);
            if (datProcesos.estado == true) {
                setStateEstado("Activo");
                setCheckedEstado(true)
            } else {
                setStateEstado("Inactivo");
                setCheckedEstado(false)
            }
            if (datProcesos.conimplementador == true) {
                setStateImplementador("Si");
                setCheckedImplementador(true)
            } else {
                setStateImplementador("No");
                setCheckedImplementador(false)
            }
            if (datProcesos.transversal == true) {
                setStateTransversal("Si");
                setCheckedTransversal(true)
            } else {
                setStateTransversal("No");
                setCheckedTransversal(false)
            }
            if (datProcesos.controlbcoproveedor == true) {
                setStateControlProveedor("Si");
                setCheckedControlProveedor(true)
            } else {
                setStateControlProveedor("No");
                setCheckedControlProveedor(false)
            }
            document.getElementById("Bajo").checked = datProcesos.bajo;
            document.getElementById("Moderado").checked = datProcesos.moderado;
            document.getElementById("Alto").checked = datProcesos.alto;
            document.getElementById("Critico").checked = datProcesos.critico;
        };
        obtenerControl();
    }, []);

    const handleChangeStateEstado = (event) => {
        setCheckedEstado(event.target.checked)
        if (stateEstado == "Activo") {
            setStateEstado("Inactivo");
        } else {
            setStateEstado("Activo");
        }

    };
    const handleChangeStateImplementador = (event) => {
        setCheckedImplementador(event.target.checked)
        if (stateImplementador == "Si") {
            setStateImplementador("No");
        } else {
            setStateImplementador("Si");
        }

    };
    const handleChangeStateTransversal = (event) => {
        setCheckedTransversal(event.target.checked)
        if (stateTransversal == "Si") {
            setStateTransversal("No");
        } else {
            setStateTransversal("Si");
        }

    };
    const handleChangeStateControlProveedor = (event) => {
        setCheckedControlProveedor(event.target.checked)
        if (stateControlProveedor == "Si") {
            setStateControlProveedor("No");
        } else {
            setStateControlProveedor("Si");
        }

    };
    const sendData = (event) => {
        event.preventDefault();
        var data = JSON.stringify({
            idcontrol: parseInt(document.getElementById("IDcontrol").value),
            idcontrol_nist: document.getElementById("idcontrolNist").value,
            nombre_control: document.getElementById("NombreControl").value,
            descripcion_control: document.getElementById("Descripcion").value,
            // categoria_control: document.getElementById("categoriacontrol") !== null ? document.getElementById("categoriacontrol").value : null,
            // tipo_control: document.getElementById("tipocontrol") !== null ? document.getElementById("tipocontrol").value : null,
            // capa_control: document.getElementById("capacontrol") !== null ? document.getElementById("capacontrol").value : null,
            framework_nist: document.getElementById("frameworknist") !== null ? document.getElementById("frameworknist").value : null,
            estado: checkedEstado,
            idfamilia: document.getElementById("idfamilia").value,
            conimplementador: checkedImplementador,
            habilitador: document.getElementById("habilitador").value,
            bajo: document.getElementById("Bajo") == null ? false : document.getElementById("Bajo").checked,
            moderado: document.getElementById("Moderado") == null ? false : document.getElementById("Moderado").checked,
            alto: document.getElementById("Alto") == null ? false : document.getElementById("Alto").checked,
            critico: document.getElementById("Critico") == null ? false : document.getElementById("Critico").checked,
            transversal: checkedTransversal,
            controlbcoproveedor: checkedControlProveedor,
        });
        fetch(
            process.env.REACT_APP_API_URL +
            "maestro/controles/" +
            localStorage.getItem("idcontrol") +
            "/",
            {
                method: "PUT",
                body: data,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    //Authorization: "Bearer " + token,
                },
            }
        )
            .then((data) =>
                data.json().then((response) => {
                    if (data.status >= 200 && data.status < 300) {
                        setEstadoPost(2);
                    } else if (data.status >= 500) {
                        setEstadoPost(5);

                    } else if (data.status >= 400 && data.status < 500) {
                        setEstadoPost(4);
                    }
                })
            )
            .catch(function (err) { });

        setValidated(true);
    }
    return (
        <>
            <AlertDismissibleExample alerta={estadoPOST} />
            <Row>
                <Col>
                    {" "}
                    <h2 className="subtitulo">Editar Estándar</h2>
                </Col>
            </Row>
            <hr />
            <Form
                id="formData"
                onSubmit={(e) => sendData(e)}
                noValidate
                validated={validated}
            >
                <Row className="mb-3">
                    <Col sm={2} xs={12}>
                        <label className="label forn-label">Id</label>
                    </Col>
                    <Col sm={4} xs={12}>
                        <input
                            type="text"
                            disabled
                            className="form-control text-center font-weight-bold"
                            defaultValue={infocontrol.idcontrol}
                            id="IDcontrol"
                        ></input>
                    </Col>
                    <Col sm={2} xs={12}>
                        <label className="label form-label">Id Estándar NIST*</label>
                    </Col>
                    <Col sm={4} xs={12}>
                        <input
                            type="text"
                            className="form-control text-center"
                            placeholder="Id Estándar NIST"
                            defaultValue={infocontrol.idcontrol_nist}
                            required
                            id="idcontrolNist"
                        ></input>
                        <Form.Control.Feedback type="invalid">
                            Por favor introduzca un id NIST.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label form-label">Nombre*</label>
                    </Col>
                    <Col sm={8} xs={12}>
                        <input
                            type="text"
                            className="form-control text-center"
                            placeholder="Nombre del Estándar"
                            defaultValue={infocontrol.nombre_control}
                            required
                            id="NombreControl"
                        ></input>
                        <Form.Control.Feedback type="invalid">
                            Por favor introduzca un nombre.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label form-label">Descripción*</label>
                    </Col>
                    <Col sm={8} xs={10}>
                        <textarea
                            className="form-control text-center"
                            placeholder="Descripción del Estándar"
                            defaultValue={infocontrol.descripcion_control}
                            rows="3"
                            required
                            id="Descripcion"
                        ></textarea>
                        <Form.Control.Feedback type="invalid">
                            Por favor introduzca una descripción.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                {/* <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label forn-label">Categoria del NIST*</label>
                    </Col>
                    <Col sm={8} xs={12}>
                        <select
                            className="form-control"
                            required
                            id="categoriacontrol"
                        >
                            <option value={infocontrol.categoria_control}>{infocontrol.categoria_control}</option>
                            {opcion_categoria_control.map((campo) =>
                                infocontrol.categoria_control !== campo.nombre ? (
                                    <option key={campo.id} value={campo.nombre}>
                                        {campo.nombre}
                                    </option>
                                ) : null
                            )}
                        </select>
                        <Form.Control.Feedback type="invalid">
                            Por favor seleccione la categoria del NIST.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label forn-label">Tipo de NIST*</label>
                    </Col>
                    <Col sm={8} xs={12}>
                        <select
                            className="form-control"
                            // onChange={() => changeRequired()}
                            required
                            id="tipocontrol"
                        >
                            <option value={infocontrol.tipo_control}>{infocontrol.tipo_control}</option>
                            {opcion_tipo_control.map((campo) =>
                                infocontrol.tipo_control !== campo.nombre ? (
                                    <option key={campo.id} value={campo.nombre}>
                                        {campo.nombre}
                                    </option>
                                ) : null
                            )}
                        </select>
                        <Form.Control.Feedback type="invalid">
                            Por favor seleccione el tipo de NIST.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label forn-label">Capa de Control*</label>
                    </Col>
                    <Col sm={8} xs={12}>
                        <select
                            className="form-control"
                            // onChange={() => changeRequired()}
                            required
                            id="capacontrol"
                        >
                            <option value={infocontrol.capa_control}>{infocontrol.capa_control}</option>
                            {opcion_capa_control.map((campo) =>
                                infocontrol.capa_control !== campo.nombre ? (
                                    <option key={campo.id} value={campo.nombre}>
                                        {campo.nombre}
                                    </option>
                                ) : null
                            )}
                        </select>
                        <Form.Control.Feedback type="invalid">
                            Por favor seleccione la Capa de control.
                        </Form.Control.Feedback>
                    </Col>
                </Row> */}
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label forn-label">Framework NIST*</label>
                    </Col>
                    <Col sm={4} xs={12}>
                        <select
                            className="form-control"
                            // onChange={() => changeRequired()}
                            required
                            id="frameworknist"
                        >
                            <option value={infocontrol.framework_nist}>{infocontrol.framework_nist}</option>
                            {opcion_framework_nist_control.map((campo) =>
                                infocontrol.framework_nist !== campo.nombre ? (
                                    <option key={campo.id} value={campo.nombre}>
                                        {campo.nombre}
                                    </option>
                                ) : null
                            )}
                        </select>
                        <Form.Control.Feedback type="invalid">
                            Por favor seleccione el Framework NIST.
                        </Form.Control.Feedback>
                    </Col>
                    <Col xs={2}>
                        <label className="label forn-label">Estado*</label>
                    </Col>
                    <Col sm={2} xs={12}>
                        <FormControlLabel
                            control={<Switch checked={checkedEstado}
                            />}
                            label={stateEstado}
                            onChange={handleChangeStateEstado}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={4} xs={12}>
                        <label className="label form-label">Id Familia*</label>
                    </Col>
                    <Col sm={4} xs={12}>
                        <input
                            type="text"
                            className="form-control text-center"
                            placeholder="Nombre de Familia"
                            defaultValue={infocontrol.idfamilia}
                            required
                            id="idfamilia"
                        ></input>
                        <Form.Control.Feedback type="invalid">
                            Por favor introduzca un id Familia.
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm={2} xs={12}>
                        <label className="label forn-label">Tiene Implementador*</label>
                    </Col>
                    <Col sm={2} xs={12}>
                        <FormControlLabel
                            control={<Switch checked={checkedImplementador}
                            />}
                            label={stateImplementador}
                            onChange={handleChangeStateImplementador}
                        />
                    </Col>
                </Row>
                <Row style={{ padding: "15px 0px" }}>
                    <Col sm={4} xs={12}>
                        <label className="label forn-label">Habilitador*</label>
                    </Col>
                    <Col sm={4} xs={12}>
                        <select
                            className="form-control"
                            // onChange={() => changeRequired()}
                            required
                            id="habilitador"
                        >
                            <option value={infocontrol.habilitador}>{infocontrol.habilitador}</option>
                            {opcion_habilitador_control.map((campo) =>
                                infocontrol.habilitador !== campo.nombre ? (
                                    <option key={campo.id} value={campo.nombre}>
                                        {campo.nombre}
                                    </option>
                                ) : null
                            )}
                        </select>
                        <Form.Control.Feedback type="invalid">
                            Por favor seleccione el habilitador.
                        </Form.Control.Feedback>
                    </Col>
                    <Col sm={2} xs={12}>
                        <label className="label forn-label">Transversal*</label>
                    </Col>
                    <Col sm={1} xs={12}>
                        <FormControlLabel
                            control={<Switch checked={checkedTransversal}
                            />}
                            label={stateTransversal}
                            onChange={handleChangeStateTransversal}
                        />
                    </Col>

                </Row>
                <Row className="mb-3" style={{ paddingTop: "10px" }}>

                    <Col sm={1} xs={12}>
                        <label className="label forn-label">Bajo:</label>
                    </Col>
                    <Col sm={1} xs={5}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Bajo"
                        ></input>
                    </Col>

                    <Col sm={1} xs={12}>
                        <label className="label forn-label">Moderado:</label>
                    </Col>
                    <Col sm={1} xs={5}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Moderado"
                        ></input>
                    </Col>
                    <Col sm={1} xs={12}>
                        <label className="label forn-label">Alto:</label>
                    </Col>
                    <Col sm={1} xs={5}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Alto"
                        ></input>
                    </Col>
                    <Col sm={1} xs={12}>
                        <label className="label forn-label">Critico:</label>
                    </Col>
                    <Col sm={1} xs={5}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Critico"
                        ></input>
                    </Col>
                    <Col sm={2} xs={12}>
                        <label className="label forn-label">NIST Proveedor*</label>
                    </Col>
                    <Col sm={1} xs={12}>
                        <FormControlLabel
                            control={<Switch checked={checkedControlProveedor}
                            />}
                            label={stateControlProveedor}
                            onChange={handleChangeStateControlProveedor}
                        />
                    </Col>

                </Row>

                <Row style={{ padding: "15px 0px" }}>

                </Row>

                <Row className="mb-3" style={{ padding: "25px 0px" }}>
                    <Col sm={4} xs={1}></Col>
                    <Col sm={3} xs={3}>
                        {" "}
                        {/* <Button type="submit" className="botonPositivo" id="send">
                            Guardar
                        </Button> */}
                    </Col>
                    <Col sm={3} xs={3}>
                        {" "}
                        <Link to="maestro-controles">
                            <Button className="botonNegativo">Descartar</Button>
                        </Link>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
export default EditarControles;