import { authContext, adalConfig, obtainTimeLeft } from './adalConfig';
//import decode from 'jwt-decode';


class AADService {
    
    login() {
        authContext.login();
    }
    
    loggedIn() {
        return this.getUser() ? true : false;
    }

    logout() {
        authContext.logOut();
    }

    getToken() {
        return authContext.getCachedToken(adalConfig['endpoints']['api']);
    }

    getUser() {
        return authContext.getCachedUser();
    }

    /* getClaims() {
        return decode(this.getToken());
    } */

    getRoles() {
        const claims = this.getClaims();
        if (claims.hasOwnProperty('roles')) {
            return claims.roles;
        }
        return null;
    }

    //obtainTimeLeft() {
    //    //Get the token
    //    const token = this.getToken();
    //    if (!token) {
    //        console.log("No hay token");
    //        return;
    //    }
    //    const tokenDecoded = decode(token);
    //    const timeLeft = (tokenDecoded.exp - Date.now() / 1000) / 60;
    //    localStorage.setItem("timeLeft", timeLeft);
    //    return timeLeft;
    //}
    

    isInRole(role) {
        if (this.getRoles()) {
        for (var i = 0; i < this.getRoles().length; i++) {
            if (this.getRoles()[i] === role)
            return true;
        }
        }
        return false;
    }
}

export default AADService;
