import React, { useEffect, useRef, useState } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from '@material-ui/core/Checkbox';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { HelpOutline } from '@material-ui/icons';

import { Table,TableHead,TableBody,TableRow,TableCell,Paper, Tooltip, List, ListItem, ListItemText} from "@material-ui/core";
import {Button, Row, Col, Modal, Alert} from "react-bootstrap";
import Select from "react-select";
import * as XLSX from 'xlsx';
import AADService from "../funcionesAuth.js";


function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          No hay vulnerabilidades para simular
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

  const useStyles = makeStyles({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },   
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "White",
    },
    
  });

  const headCells = [
    {
      id: "tipo_vulnerabilidad",
      numeric: false,
      disablePadding: false,
      label: "Tipo",
    },
    {
      id: "detallevulnerabilidad",
      numeric: false,
      disablePadding: false,
      label: "Situación Observada",
    },
    {
      id: "fuente",
      numeric: false,
      disablePadding: false,
      label: "Fuente",
    },
    {
      id: "vector_explotabilidad",
      numeric: true,
      disablePadding: false,
      label: "Vector de Explotabilidad",
    },
    {
      id: "CVEB_VectorScoreBCO",
      numeric: true,
      disablePadding: false,
      label: "CVEB Vector Score BCO",
    },
    {
      id: "estadovulnerabilidad",
      numeric: false,
      disablePadding: false,
      label: "Estado de Vulnerabilidad",
    },

  ];

  const headCellsControles = [
    {
      id: "idimplementador",
      numeric: false,
      disablePadding: false,
      label: "Id Control",
    },
    {
      id: "nombre",
      numeric: false,
      disablePadding: false,
      label: "Control",
    },
    {
      id: "descripcion",
      numeric: false,
      disablePadding: false,
      label: "Descripción",
    },
    {
      id: "aplicabilidad",
      numeric: false,
      disablePadding: false,
      label: "Aplica",
    },
    {
      id: "implementado",
      numeric: false,
      disablePadding: false,
      label: "Implementado",
    },
    {
      id: "efectividad",
      numeric: false,
      disablePadding: false,
      label: "Diseño del Control %",
    },

  ];

  const headCellsRiesgos = [
    {
      id: "idriesgo",
      numeric: true,
      disablePadding: false,
      label: "ID Riesgo",
    },
    {
      id: "evento",
      numeric: false,
      disablePadding: false,
      label: "Evento",
    },
    {
      id: "riesgo_inherente",
      numeric: true,
      disablePadding: false,
      label: "Riesgo Inherente",
    },
    {
      id: "efectividad_actual",
      numeric: true,
      disablePadding: false,
      label: "Efectividad Actual/Manual",
    },
    {
      id: "efectividad_simulacion",
      numeric: true,
      disablePadding: false,
      label: "Efectividad Simulación",
    },
    {
      id: "riesgo_residual_actual",
      numeric: true,
      disablePadding: false,
      label: "Riesgo Residual Actual",
    },
    {
      id: "riesgo_residual_simulacion",
      numeric: true,
      disablePadding: false,
      label: "Riesgo Residual Simulación",
    },
    {
      id: "diferencia_efectividad_simulacion",
      numeric: true,
      disablePadding: false,
      label: "Diferencia Efectividad Simulación",
    },
    {
      id: "diferencia_residual_simulacion",
      numeric: true,
      disablePadding: false,
      label: "Diferencia Residual Simulación",
    },
  ];

const renderEfectividadTooltip = () => (
  <Tooltip 
    title={
      <React.Fragment>
      <List>
        <ListItem>
          <ListItemText primary="Este valor refleja la efectividad de control utilizada en la pestaña 'Riesgos', la generada por el GRC o la Manual" />
        </ListItem>
      </List>
    </React.Fragment>
  }>
    <HelpOutline />
  </Tooltip>
);


const AdvertenciaReiniciar = ({ mostrar, onClose, onConfirm }) => {
  return (
    <Modal show={mostrar} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Advertencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        ¿Estás seguro de que deseas reiniciar? Se perderán tus cambios.
        <br />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Volver
        </Button>
        <Button variant="secondary" onClick={onConfirm}>
          Reiniciar de todos modos
        </Button>
      </Modal.Footer>
    </Modal>
  );
};




function EnhancedTableHead(props) {
  const columnWidths = {
    tipo_vulnerabilidad: '10%',
    detallevulnerabilidad: '30%',
    fuente: '10%',
    vector_explotabilidad: '10%',
    CVEB_VectorScoreBCO: '10%',
    estadovulnerabilidad: '20%',
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{
              backgroundColor: "#2c2a29",
              color: "#ffffff",
              width: columnWidths[headCell.id]
            }}
          >
            <label className='label'>{headCell.label}</label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

function EnhancedTableHeadControl(props) {
  const columnWidths = {
    idimplementador: '10%',
    nombre: '20%',
    descripcion: '40%',
    aplicabilidad: '10%',
    implementado: '10%',
    efectividad: '10%',
  };
  return (
    <TableHead>
      <TableRow>
        {headCellsControles.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{
              backgroundColor: "#2c2a29",
              color: "#ffffff",
              width: columnWidths[headCell.id]
            }}
          >
            <label className='label'>{headCell.label}</label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHeadControl.propTypes = {
  classes: PropTypes.object.isRequired,
};

function EnhancedTableHeadRiesgos(props) {
  const columnWidths = {
    idriesgo: '10%',
    evento: '20%',
    riesgo_inherente: '10%',
    efectividad_actual: '10%',
    efectividad_simulacion: '10%',
    riesgo_residual_actual: '10%',
    riesgo_residual_simulacion: '10%',
    diferencia_efectividad_simulacion: '10%',
    diferencia_residual_simulacion: '10%',
  };
  return (
    <TableHead>
      <TableRow>
        {headCellsRiesgos.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{
              backgroundColor: "#2c2a29",
              color: "#ffffff",
              width: columnWidths[headCell.id]
            }}
          >
            <label className='label'>{headCell.label}</label>
            {/** Agregamos tooltip solo si el headCell.id es efectividad_actual*/}
            {headCell.id === "efectividad_actual" ? renderEfectividadTooltip() : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHeadControl.propTypes = {
  classes: PropTypes.object.isRequired,
};


function EscenariosMitigacion() {
    const AzureADService = new AADService();
    const token = AzureADService.getToken();
    const [dataVulnerabilidades, setDatadataVulnerabilidades] = useState([]);
    const [dataFilterVulnerabilidades, setDataFilterVulnerabilidades] = useState([]);
    const [dataControles, setDataControles] = useState([]);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [dataRiesgosSimulacion, setDataRiesgosSimulacion] = useState([]);
    const classes = useStyles();
    const [dense, setDense] = React.useState(false);
    const [selectedOptionTipo, setSelectedOptionTipo] = useState(null);
    const [selected, setSelected] = React.useState([]);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [newRuta, setNewRuta] = React.useState("");
    const [selectAll, setSelectAll] = useState(false);
    const [filterMinimoExplotabilidad, setFilterMinimoExplotabilidad] = useState(false);
    const [filterMaximoExplotabilidad, setFilterMaximoExplotabilidad] = useState(false);
    const [valorMinimoExplotabilidad, setValorMinimoExplotabilidad] = useState('');
    const [valorMaximoExplotabilidad, setValorMaximoExplotabilidad] = useState('');
    const [filtroAplicado, setFiltroAplicado] = useState(false);
    const [fetchSimulacion, setFetchSimulacion] = useState(false);
    const [showRiesgos, setShowRiesgos] = useState(false);
    const [mostrarAdvertencia, setMostrarAdvertencia] = useState(false);
    const [estadoGET, setEstadoGET] = React.useState(0);

    const mapeLetrasInicialesDespliegue = (dataDespliegue) => {
      const palabras = dataDespliegue.split(",");
      const iniciales = palabras.map((palabra) => palabra[0]);
      return iniciales.join('');
    };

  function handleCheckboxChange(event, index_unique) {
    //console.log("index_unique", index_unique)
    //console.log("event.target.checked", event.target.checked)
    const newDataVulnerabilidades = dataFilterVulnerabilidades.map(row => {
      if (row.index_unique === index_unique) {
        return { ...row, estadovulnerabilidad: !event.target.checked };
      }
      return row; 
    });
  
    setDataFilterVulnerabilidades(newDataVulnerabilidades);
  }

  function handleCheckboxAllChange(event) {
    const newDataVulnerabilidades = [...dataFilterVulnerabilidades];
    newDataVulnerabilidades.forEach((row) => {
      row.estadovulnerabilidad = !event.target.checked;
    });
    setDataFilterVulnerabilidades(newDataVulnerabilidades);
    setSelectAll(event.target.checked);
  }

  function handleChangeValorMinimoExplotabilidad(event) {
    const newValue = parseFloat(event.target.value);
    if (newValue >= 0 && newValue <= 3.9) {
      setValorMinimoExplotabilidad(newValue);
      setFilterMinimoExplotabilidad(true);
    }
  }
  
  function handleChangeValorMaximoExplotabilidad(event) {  
    const newValue = parseFloat(event.target.value);
    if (newValue >= 0 && newValue <= 3.9) {
      setValorMaximoExplotabilidad(event.target.value);
      setFilterMaximoExplotabilidad(true);
    }
  }

  function handleChangeImplementacion(event, index) {
    const newData = [...dataControles];
    newData[index].implementado = event.target.value; 
    setDataControles(newData);
}

const handleChangeTipoVul = (selectedOptionTipo) => {
  setSelectedOptionTipo(selectedOptionTipo);
  if (selectedOptionTipo) {
    if (selectedOptionTipo.label === "Todas") {
      setDataFilterVulnerabilidades(dataVulnerabilidades);
      return;
    }
    else {
    const filteredData = dataVulnerabilidades.filter(
      (row) => row.tipo === selectedOptionTipo.label
    );
    setDataFilterVulnerabilidades(filteredData);
    }
  }
};

  
  function aplicarFiltro() {
    if (!filtroAplicado) {
      let newDataVulnerabilidades = [...dataFilterVulnerabilidades];
  
      if (filterMinimoExplotabilidad && valorMinimoExplotabilidad !== '') {
        newDataVulnerabilidades = newDataVulnerabilidades.filter((item) => item.vectorexplotabilidad >= parseFloat(valorMinimoExplotabilidad));
      }
  
      if (filterMaximoExplotabilidad && valorMaximoExplotabilidad !== '') {
        newDataVulnerabilidades = newDataVulnerabilidades.filter((item) => item.vectorexplotabilidad <= parseFloat(valorMaximoExplotabilidad));
      }
  
      setDataFilterVulnerabilidades(newDataVulnerabilidades);
      setFiltroAplicado(true);
    } else {
      // Eliminar filtro
      setDataFilterVulnerabilidades(dataVulnerabilidades.map(originalItem => {
        // Buscar el elemento correspondiente en los datos originales y actualizar su estado si ha sido modificado
        const modifiedItem = dataFilterVulnerabilidades.find(filteredItem => filteredItem.index_unique === originalItem.index_unique);
        if (modifiedItem) {
          return { ...originalItem, estadovulnerabilidad: modifiedItem.estadovulnerabilidad };
        }
        return originalItem;
      }));
  
      setFiltroAplicado(false);
    }
  }

    useEffect(() => {
      const dataStorageDespliegue = localStorage.getItem("variable_tipo_despliegue");
      const dataStorageTipoCanal = localStorage.getItem("variable_tipo_canal");
      const dataStorageComponente = localStorage.getItem("variable_tipo_activo");
      const inicialesDespl = mapeLetrasInicialesDespliegue(dataStorageDespliegue);
      const rutaGetTiposNew = dataStorageComponente + "/" + inicialesDespl + "/" + dataStorageTipoCanal + "/";
      const fetchMitigacion = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/mitigacion/" +
            localStorage.getItem("idcompania") +
            "/" +
            localStorage.getItem("idevaluacion") +
            "/" +
            rutaGetTiposNew,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (result.status === 200) {
          let dataFetchMitigacion = await result.json();
          //console.log("dataFetchMitigacion", dataFetchMitigacion);
          setNewRuta(rutaGetTiposNew);  
          const ArraydataVulnerabilidades = dataFetchMitigacion.vulnerabilidades
          const dataVulnerabilidades_temp = ArraydataVulnerabilidades.map((item) => ({
            index_unique: item.index_unique,
            idvulnerabilidadevaluacion: item.idvulnerabilidadevaluacion,
            idvulnerabilidadtipo: item.idvulnerabilidadtipo,
            vectorexplotabilidad: item.vectorexplotabilidad,
            cveb_vectorscorebco: item.cveb_vectorscorebco,
            estadovulnerabilidad: item.estadovulnerabilidad,
            tipo: item.tipo,
            fuente: item.fuente,
            detallevulnerabilidad: item.detallevulnerabilidad,
          }));
          setDatadataVulnerabilidades(dataVulnerabilidades_temp);
          setDataFilterVulnerabilidades(dataVulnerabilidades_temp);

          const ArraydataControles = dataFetchMitigacion.controles
          const dataControles_temp = ArraydataControles.map((item) => ({
            idimplementador: item.idimplementador,
            nombre: item.nombre,
            descripcion: item.descripcion,
            aplicabilidad: item.aplicabilidad,
            implementado: item.implementado,
            efectividad: item.efectividad,
          }));
          setDataControles(dataControles_temp);

        }
        else if (result.status === 404){
          setEstadoGET(6);
          return null;
        }
        else {
          setEstadoGET(5);
          return null;
        }
      }
      fetchMitigacion();
    },[fetchAgain]);

  function ExportarExcel (){
    const newBook = XLSX.utils.book_new();
    const dataExportVulnerabilidades = dataVulnerabilidades.map((item) => ({
      idvulnerabilidadevaluacion: item.idvulnerabilidadevaluacion,
      idvulnerabilidadtipo: item.idvulnerabilidadtipo,
      tipo: item.tipo,
      detallevulnerabilidad: item.detallevulnerabilidad,
      fuente: item.fuente,
      vectorexplotabilidad: item.vectorexplotabilidad,
      cveb_vectorscorebco: item.cveb_vectorscorebco,
      estadovulnerabilidad: item.estadovulnerabilidad ? "No remediada" : "Remediada",
    }));
    const newSheetVulnerabilidades = XLSX.utils.json_to_sheet(dataExportVulnerabilidades);
    //console.log("dataExportVulnerabilidades", dataExportVulnerabilidades);
    //console.log("newSheetVulnerabilidades", newSheetVulnerabilidades);
    newSheetVulnerabilidades['!cols'] = [];
    newSheetVulnerabilidades["!cols"][0] = { hidden: true };
    newSheetVulnerabilidades["!cols"][1] = { hidden: true };
    const newSheetControles = XLSX.utils.json_to_sheet(dataControles);
    const newSheetRiesgos = XLSX.utils.json_to_sheet(dataRiesgosSimulacion);
    XLSX.utils.book_append_sheet(newBook, newSheetVulnerabilidades, "Vulnerabilidades");
    XLSX.utils.book_append_sheet(newBook, newSheetControles, "Controles");
    XLSX.utils.book_append_sheet(newBook, newSheetRiesgos, "Riesgos");
    const nombreArchivo = "Simulacion_" + "idevaluacion_" + localStorage.getItem("idevaluacion") + "_" + new Date().toLocaleDateString() + ".xlsx";
    XLSX.writeFile(newBook, nombreArchivo);
  }
    
  function renderArrowIcon(value_actual, value_simulado) {
    if (value_actual > value_simulado) {
      return <ArrowDownwardIcon style={{ color: 'red' }} />;
    } else if (value_actual < value_simulado) {
      return <ArrowUpwardIcon style={{ color: 'green' }} />;
    } else {
      return null; 
    }
  }
function Simular() {
  //console.log("Estoy en simular")
  const dataVulnerabilidadesSimulacion = dataFilterVulnerabilidades.map((item) => ({
    idvulnerabilidadevaluacion: item.idvulnerabilidadevaluacion,
    estadovulnerabilidad: item.estadovulnerabilidad,
    vectorexplotabilidad: item.vectorexplotabilidad,
    idvulnerabilidadtipo: item.idvulnerabilidadtipo,
  }));
  //console.log("dataVulnerabilidadesSimulacion", dataVulnerabilidadesSimulacion)

  const dataControlesSimulacion = dataControles.map((item) => ({
    idimplementador: item.idimplementador,
    implementado: item.implementado,
    efectividad: item.efectividad,
  }));
  //console.log("dataControlesSimulacion", dataControlesSimulacion)

  //data a enviar
  const dataSimulacion = {
    idevaluacion: localStorage.getItem("idevaluacion"),
    dataVulnerabilidadesSimulacion,
    dataControlesSimulacion
  }
  //console.log("dataSimulacion", dataSimulacion)

  let datosEnvioSimulacion = JSON.stringify(dataSimulacion);
  //console.log("datosEnvioSimulacion", datosEnvioSimulacion)

  const fetchSimulacion = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/simulacion/" +
        localStorage.getItem("idcompania") +
        "/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: datosEnvioSimulacion,
      }
    )
    if (result.status === 200) {
      let dataFetchSimulacion = await result.json();
      setDataRiesgosSimulacion(dataFetchSimulacion);
    }
    else {
      return null;
    }
  }
  fetchSimulacion();
}





    return (
      <>
        <AlertDismissibleExample alerta={estadoGET} />
        {/* Modal de advertencia */}
        <AdvertenciaReiniciar
          mostrar={mostrarAdvertencia}
          onClose={() => {
            setMostrarAdvertencia(false);
          }}
          onConfirm={() => {
            //console.log("Estoy en el onConfirm")
            setDataFilterVulnerabilidades(dataVulnerabilidades);
            setFetchAgain(!fetchAgain);
            setFiltroAplicado(false);
            setShowRiesgos(false);
            setDataRiesgosSimulacion([]);
            setMostrarAdvertencia(false);
            setValorMinimoExplotabilidad('');
            setValorMaximoExplotabilidad('');
            //reiniciar();
          }}
        />
        <div className={classes.root}>
            <div style={{ marginBottom: '30px', marginTop:'30' }}>
              <h1 className='titulo' style={{ marginBottom: '30px' }}>Escenarios de Mitigación</h1> 
            </div>  
            <Row>
                <Col sm={2} xs={12} style={{ marginRight: '10px' }} >
                  <label  className="label forn-label">Vulnerabilidades</label>
                </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col sm={2} xs={12} style={{ marginRight: '5px' }} >
                <label>Filtro por explotabilidad</label>
              </Col>
              <Col sm={2} xs={12} style={{ marginRight: '5px' }} >
                <input
                  type='number'
                  className='form-control'
                  placeholder='Valor mínimo de explotabilidad'
                  step={0.1}
                  min={0}
                  max={3.9}
                  onChange={(event) => {
                    setFilterMinimoExplotabilidad(true);
                    handleChangeValorMinimoExplotabilidad(event)
                  }}
                  value={valorMinimoExplotabilidad}
                />
              </Col>
              <Col sm={2} xs={12} style={{ marginRight: '5px' }} >
                <input
                  type='number'
                  className='form-control'
                  placeholder='Valor máximo de explotabilidad'
                  step={0.1}
                  min={0}
                  max={3.9}
                  onChange={(event) => {
                    setFilterMaximoExplotabilidad(true);
                    handleChangeValorMaximoExplotabilidad(event)
                  }}
                  value={valorMaximoExplotabilidad}
                />
              </Col>
              <Col sm={2} xs={12}>
                <Button
                  className='botonGeneral'
                  onClick={() => aplicarFiltro()}
                  type='submit'
                >
                  {filtroAplicado ? "Quitar Filtro" : "Aplicar Filtro"}
                </Button>
              </Col>
              <Col sm={2} xs={12}></Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} style={{ marginTop: '20px' }} >
              </Col>
            </Row>
            <Row>
              <Col sm={2} xs={12} style={{ marginRight: '5px' }} >
                <label>Filtro por Tipo</label>
              </Col>
              <Col sm={2} xs={12} style={{ marginRight: '5px' }} >
                <Select
                  className='basic-single'
                  classNamePrefix='select'
                  isSearchable
                  name='color'
                  options={[
                    { value: '1', label: 'Todas' },
                    { value: '2', label: 'Técnica' },
                    { value: '3', label: 'No técnica' },
                  ]}
                  value={selectedOptionTipo}
                  onChange={(e) => {
                    handleChangeTipoVul(e);
                  }
                  }
                />
              </Col>
            </Row>
            <Row>
            <Col sm={2} xs={12} style={{ marginRight: '5px' }} >
                  <div>
                    <Checkbox
                      checked={selectAll}
                      onChange={(event) => handleCheckboxAllChange(event)}
                      color="primary"
                      inputProps={{ 'aria-label': 'select all checkbox' }}
                    />
                    {selectAll ? "Deseleccionar todo" : "Seleccionar todo"}
                  </div>
              </Col>
            </Row>
            <hr></hr>
            <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
              <Table
                className={classes.table}
                aria-labelledby='tableTitle'
                size={dense ? "small" : "medium"}
                aria-label='enhanced table'
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {dataFilterVulnerabilidades
                    .map((row, index) => {
                      return (
                        <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                          <TableCell>{row.tipo}</TableCell>
                          <TableCell>{row.detallevulnerabilidad}</TableCell>
                          <TableCell>{row.fuente}</TableCell>
                          <TableCell>{row.vectorexplotabilidad}</TableCell>
                          <TableCell>{row.cveb_vectorscorebco ? row.cveb_vectorscorebco : "No Aplica"}</TableCell>
                          <TableCell>
                            <label>
                            <Checkbox
                              checked={!row.estadovulnerabilidad}
                              onChange={(event) => handleCheckboxChange(event, row.index_unique)}
                              color="primary"
                              inputProps={{ 'aria-label': 'checkbox with default color' }}
                            />
                                {row.estadovulnerabilidad ? "No remediada" : "Remediada"}
                            </label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>   
            <hr></hr>
            <Row>
                <Col sm={2} xs={12} style={{ marginRight: '10px' }} >
                  <label  className="label forn-label">Controles</label>
                </Col>
            </Row>  
            <hr></hr>      
            <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby='tableTitle'
                size={dense ? "small" : "medium"}
                aria-label='enhanced table'
              >
                <EnhancedTableHeadControl
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {dataControles.map((row, index) => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                        <TableCell>{row.idimplementador}</TableCell>
                        <TableCell>{row.nombre}</TableCell>
                        <TableCell>{row.descripcion}</TableCell>
                        <TableCell>{row.aplicabilidad ? row.aplicabilidad === "Aplica" ? "Si" : "No" : "No"}</TableCell>
                        <TableCell>
                          <select
                          className='form-control automatizacion'
                          onChange={(event) => {
                            handleChangeImplementacion(event, index)
                          }}

                          value={row.implementado}
                          disabled={row.aplicabilidad === "No Aplica" ? true : false}
                          >
                          <option value='Si'>Si</option>
                          <option value='No'>No</option>
                          </select>
                        </TableCell>
                        <TableCell>{row.efectividad}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <hr></hr>      
            <Row>  
            <Col sm={3} xs={12} style={{ marginRight: '10px' }} >
            <Button
                  className='botonPositivo'
                  onClick={() => {
                    Simular()
                    setShowRiesgos(true)
                  }}
                  type='submit'
                >
                  Simular
                </Button>
            </Col>
            <Col sm={3} xs={12} style={{ marginRight: '10px' }} >
                <Button
                  className='botonGeneral'
                  onClick={() => {
                    //setFetchSimulacion(true)
                    setMostrarAdvertencia(true)
                  }}
                  type='submit'
                >
                  Reiniciar
                </Button>
            </Col>
            </Row> 
            <hr></hr>      
            {/* Aparición de la tabla de riesgos */}
            {showRiesgos ? 
            <Paper className={classes.paper}>
            <Row>
                <Col sm={2} xs={12} style={{ marginRight: '10px' }} >
                  <label  className="label forn-label">Riesgos</label>
                </Col>
                <Col sm={3} xs={12} style={{ marginRight: '10px' }} >
                <Button
                  className='botonGeneral'
                  onClick={() => {
                    ExportarExcel()
                    //setFetchSimulacion(true)
                    //setMostrarAdvertencia(true)
                  }}
                  type='submit'
                >
                  Descargar excel con resultados
                </Button>
            </Col>
            </Row> 
            <hr></hr>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby='tableTitle'
                size={dense ? "small" : "medium"}
                aria-label='enhanced table'
              >
                <EnhancedTableHeadRiesgos
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {dataRiesgosSimulacion.map((row, index) => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                        <TableCell>{row.idriesgo}</TableCell>
                        <TableCell>{row.evento}</TableCell>
                        <TableCell>{row.riesgo_inherente}</TableCell>
                        <TableCell>
                          {/* Si la efectividad_actual_manual no es nula, usamos esa, de lo contrario usamos efectividad_actual */}
                          {row.efectividad_actual_manual ? row.efectividad_actual_manual.toFixed(4) : row.efectividad_actual.toFixed(4)}
                          </TableCell>
                        <TableCell>{(row.efectividad_simulacion).toFixed(4)}</TableCell>
                        <TableCell>{(row.riesgo_residual_actual).toFixed(4)}</TableCell>
                        <TableCell>{(row.riesgo_residual_simulacion).toFixed(4)}</TableCell>
                        <TableCell>
                          {/* Si la efectividad_actual_manual no es nula, usamos esa, de lo contrario usamos efectividad_actual */}
                          {renderArrowIcon(row.efectividad_actual_manual ? row.efectividad_actual_manual : row.efectividad_actual, row.efectividad_simulacion)}{' '}
                          {Math.abs((row.efectividad_actual_manual ? row.efectividad_actual_manual : row.efectividad_actual) - row.efectividad_simulacion).toFixed(4)}
                        </TableCell>
                        <TableCell>
                          {renderArrowIcon(row.riesgo_residual_actual, row.riesgo_residual_simulacion)}{' '}
                          {Math.abs(row.riesgo_residual_actual - row.riesgo_residual_simulacion).toFixed(4)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          : null
          }
        </div>  
      </>     
    );
}           
export default EscenariosMitigacion;