import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import { Row, Col, Form } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../CheckRole";
import AADService from "../funcionesAuth.js";
import { IoMdDownload } from "react-icons/io";
import CircularProgress from "@mui/material/CircularProgress";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions";
import XLSX from "xlsx";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function App(params) {
  //Establece estilos de componentes
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      //margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  //Modifica temas predeterminados por Material-Table
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });

  var columns = [
    {
      field: "idevaluacion",
      title: "Id evaluación",
    },
    {
      field: "fecha_evaluacion",
      title: "Fecha evaluación",
    },
    {
      field: "tipo_activo",
      title: "Tipo de activo",
    },
    {
      field: "activo_evaluado",
      title: "Activo evaluado",
    },
    {
      field: "estado_flujo",
      title: "Estado flujo evaluación",
    },
    {
      field: "analista",
      title: "Analista de riesgos",
    },
  ];

  const classes = useStyles();
  const [evaluacion, setEvaluacion] = useState([]); //table data
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [filter, setFilter] = useState(false);
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const check = new CheckRole("/evaluaciones");
  const [activoEvaluar, setActivoEvaluar] = React.useState(0);
  const checkEvaluaciones = new CheckRole("/detalle-evaluacion");
  const [loading, setLoading] = React.useState(false);
  const [evaluacionxRiesgo, setEvaluacionxRiesgo] = React.useState([]);
  const [currentRol, setCurrentRol] = useState(null);
  const [buttonDelete, setButtonDelete] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const decodeEmail = (encodedEmail) => {
    const decodedBuffer = Buffer.from(encodedEmail, "base64");
    const decodedString = decodedBuffer.toString("ascii");
    const charCodes = decodedString.split("/");
    let decodedEmail = "";
    for (let i = 1; i < charCodes.length; i++) {
      decodedEmail += String.fromCharCode(parseInt(charCodes[i]));
    }
    return decodedEmail;
  };

  const fetchdata = async (rol) => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/" +
        localStorage.getItem("idcompania") +
        "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          //Authorization: "Bearer " + token,
        },
      }
    );
    let data = await result.json();
    const resultUsuario = await fetch(
      process.env.REACT_APP_API_URL +
        "usuariosrol/" +
        localStorage.getItem("idcompania") +
        "/" +
        rol,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    let dataUsuario = await resultUsuario.json();
    let evaluaciones = [];

    if (rol === 1 || rol === 2 || rol === 7 || rol === 8) {
      setEvaluacion(data);
    } else {
      var posicionUsuario = 0;
      const userEmailEncoded = localStorage.getItem("usuario");
      const userEmail = decodeEmail(userEmailEncoded);

      for (let i = 0; i < dataUsuario.length; i++) {
        if (dataUsuario[i].email === userEmail) {
          posicionUsuario = dataUsuario[i].idposicion;
          break;
        }
      }

      for (let i = 0; i < data.length; i++) {
        if (
          data[i].responsableti === posicionUsuario ||
          data[i].responsablenegocio === posicionUsuario
        ) {
          evaluaciones.push(data[i]);
        }
      }

      setEvaluacion(evaluaciones);
    }
  };
  useEffect(() => {
    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            /*Authorization: "Bearer " + token,*/
          },
        }
      );
      const encryptedUser = await result.json();
      const decryptedUser = DecryptData(encryptedUser);
      let usuario = JSON.parse(decryptedUser);
      let opciones = usuario.map((user) => {
        return {
          opcion: user.url_opcion,
          permisos: user.permisos.split(";"),
        };
      });
      setOpcionesGenerales(opciones);
      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
        fetchdata(usuario[0].idrol);
        setCurrentRol(usuario[0].idrol);
      }
    };

    const fetchdataEvaluacionxRiesgo = async () => {
      setLoading(true);
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "descarga/evaluacionxriesgo/" +
          localStorage.getItem("idcompania"),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let param = await result.json();
        setEvaluacionxRiesgo(param);
        setLoading(false);
      } else if (result.status >= 400 && result.status <= 500) {
        return result.status;
      }
    };
    fetchUsuario();
    fetchdataEvaluacionxRiesgo();
  }, []);

  const handleClick = (id, idactivo) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], id);
      SetButtonEdit(true);
      setButtonDelete(true);
    } else {
      SetButtonEdit(false);
      setButtonDelete(false);
    }
    setSelected(newSelected);
    setActivoEvaluar(idactivo);
  };

  const editar = (event, props) => {
    localStorage.setItem("idevaluacion", selected);
    localStorage.setItem("idactivo", activoEvaluar);
    params.action();
  };

  function add_eval() {
    localStorage.setItem("idevaluacion", 0);
  }

  //Habilita y desabilita los filtros por columnas
  const handleChange = () => {
    setFilter(!filter);
  };
  const handleOnExport = () => {
    const newBook = XLSX.utils.book_new();
    const sheet1 = XLSX.utils.json_to_sheet(evaluacionxRiesgo.evaluaciones_por_riesgo);
    XLSX.utils.book_append_sheet(newBook, sheet1, "Informe_Evaluaciones_Riesgo");
    const sheet2 = XLSX.utils.json_to_sheet(evaluacionxRiesgo.controles_por_evaluacion);
    XLSX.utils.book_append_sheet(newBook, sheet2, "ControlesXEvaluacion");
    const sheet3 = XLSX.utils.json_to_sheet(evaluacionxRiesgo.controles_por_evaluacion_old);
    XLSX.utils.book_append_sheet(newBook, sheet3, "ControlesXEvaluacion_Antiguos");
    XLSX.writeFile(newBook, "Informes de Evaluaciones por Riesgo.xlsx");
  };


  const handleDeleteItem = () => {
    setOpenDeleteModal(!openDeleteModal);
  };
  const deleteEvaluations = async () => {
    try {
      await fetch(
        process.env.REACT_APP_API_URL +
          `evaluacion/triage/${selectedToDelete.idevaluacion}/`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      fetchdata(currentRol);
      setOpenDeleteModal(!openDeleteModal);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.root}>
      <Row className='pl-3 pt-3 pb-3'>
        <Col sm={8}>
          <h2 className='titulo'>Evaluaciones</h2>
        </Col>
      </Row>
      <Paper variant='outlined' className={classes.paper}>
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            columns={columns}
            data={evaluacion}
            icons={tableIcons}
            options={{
              rowStyle: (rowData) => ({
                backgroundColor:
                  ButtonEdit === true && selectedRow === rowData.tableData.id
                    ? "#fff8d3"
                    : "#FFF",
              }),
              showTitle: false,
              filtering: filter,
              searchFieldAlignment: "left",
              searchFieldStyle: {}, //Llenar estilo de barra de busqueda
              headerStyle: {
                backgroundColor: "#2c2a29",
                color: "#ffffff",
                position: "sticky",
                top: 0,
                fontFamily: "CIBFont Sans Regular",
                fontWeight: 400,
                fontSize: "1.2rem",
                lineHeight: 1.6,
                letterSpacing: "0.0075em",
                flex: "1 1 100%",
              },
              maxBodyHeight: "650px",
              selection: false,
              disableMultipleSelection: true,
              pageSizeOptions: [10, 20, 30],
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 10,
            }}
            components={{
              Pagination: (props) => (
                <div className={classes.pagination}>
                  <FormControlLabel
                    className={classes.switch}
                    control={
                      <Checkbox
                        checked={filter}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={
                      <Typography variant='body2'>
                        Mostrar filtros por columnas
                      </Typography>
                    }
                  />
                  <TablePagination {...props} className={classes.pagination} />
                </div>
              ),
            }}
            onRowClick={(evt, selectedRow) => {
              handleClick(selectedRow.idevaluacion, selectedRow.idactivo);
              setSelectedToDelete(selectedRow);
              return setSelectedRow(selectedRow.tableData.id);
            }}
            actions={[
              {
                icon: () => (
                  <Col>
                    <>
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        (currentRol === 1 || currentRol === 2 || currentRol === 8)  && (
                          <Button
                            style={{
                              backgroundColor: "#00c389",
                              color: "#fff",
                              borderRadius: "5px",
                              height: "40px",
                              minWidth: "300px",
                            }}
                            onClick={handleOnExport}
                            endIcon={<IoMdDownload />}
                          >
                            Descarga de Evaluaciones &nbsp;
                          </Button>
                        )
                      )}
                    </>
                  </Col>
                ),
                position: "toolbar",
              },
              {
                icon: () => (
                  <Link to='nueva_eval'>
                    {check.checkPermits(opcionesGenerales, "POST") ? (
                      <Button
                        style={{
                          backgroundColor: "#00c389",
                          color: "#fff",
                          borderRadius: "5px",
                          height: "40px",
                          minWidth: "100px",
                        }}
                      >
                        Nuevo
                      </Button>
                    ) : (
                      ""
                    )}
                  </Link>
                ),
                onClick: add_eval(),
                position: "toolbar",
                isFreeAction: true,
              },
              {
                icon: () => (
                  <Link to='detalle-evaluacion'>
                    <Button
                      style={{
                        backgroundColor: "#a9a9a9",
                        color: "#fff",
                        borderRadius: "5px",
                        height: "40px",
                        minWidth: "100px",
                      }}
                    >
                      Editar
                    </Button>
                  </Link>
                ),
                onClick: () => editar(),
                position: "toolbar",
                isFreeAction: true,
                hidden: ButtonEdit === false,
              },
              {
                icon: () =>
               
                  currentRol === 1 && (
                    <Button
                      style={{
                        backgroundColor: "#ff7f41",
                        color: "#fff",
                        width: "200px",
                        borderRadius: "5px",
                        height: "40px",
                      }}
                    >
                      Eliminar evaluación
                    </Button>
                  ),
                onClick: () => handleDeleteItem(),
                position: "toolbar",
                isFreeAction: true,
                hidden: buttonDelete === false,
              },
            ]}
            localization={{
              toolbar: {
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No se encontraron registros",
              },
              pagination: {
                labelRowsSelect: "Filas",
                firstTooltip: "Primera página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Última página",
              },
            }}
          />
        </MuiThemeProvider>
      </Paper>
      {openDeleteModal && (
        <Dialog open={openDeleteModal} sx={{ borderRadius: "10px" }}>
          <DialogTitle>¿Desea Eliminar evaluación?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Esta seguro que desea eliminar la evaluacion? No podras
              recuperarlo luego de eliminarlo
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: "#a9a9a9",
                color: "#fff",
                width: "100px",
                borderRadius: "10px",
                height: "35px",
              }}
              onClick={() => setOpenDeleteModal(!openDeleteModal)}
            >
              Cancelar
            </Button>
            <Button
              style={{
                backgroundColor: "#ff7f41",
                color: "#fff",
                width: "100px",
                borderRadius: "10px",
                height: "35px",
              }}
              onClick={() => deleteEvaluations()}
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default App;
