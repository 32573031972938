import React, { useEffect, useState } from 'react';
import Riesgos from "./Riesgos";
import RiesgosNew from "./RiesgosNew";

function RiesgosPadre() {
    // Estado para controlar la inclusión del componente basado en los datos recuperados.
    const [incluirRiesgoNew, setIncluirRiesgoNew] = useState("");
    
    useEffect(() => {
        const tipoDespliegue = localStorage.getItem("variable_tipo_despliegue");
        const tipoCanal = localStorage.getItem("variable_tipo_canal");
        
        if (tipoDespliegue !== null && tipoCanal !== null) {
            setIncluirRiesgoNew("Nuevo");
        }
        else if (tipoDespliegue === null && tipoCanal === null) {
            setIncluirRiesgoNew("Viejo");
        }
    }, []);


    return (
        <div>
             {/* Si incluirRiesgoNew es vacío no ejecutar nada */}
            {incluirRiesgoNew === "Nuevo" ? (
                <RiesgosNew shouldExecuteEffect={true} />
            )
            : incluirRiesgoNew === "Viejo" ? (
                <Riesgos shouldExecuteEffect={false} />
            )
            : (
                <div></div>
            )}
        </div>
    );
}
export default RiesgosPadre;