import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import { IoMdDownload } from "react-icons/io";
import XLSX from "xlsx";
import AADService from "../funcionesAuth.js";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

export default function InformeVSC() {
  const [estadoPOST, setEstadoPost] = useState(0);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [riesgos, setRiesgos] = useState([]);
  const [planesAccion, setPlanesAccion] = useState([]);
  const [datosVSC, setDatosVSC] = useState([]);
  const [loading, setLoading] = useState(true);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  useEffect(() => {
    const generarInfo = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "informes/VSC/",
        {
          method: "GET",
          headers: {
            "Content-type": "application/json;",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datainfo = await result.json();
      
      setDatosVSC(datainfo);
      setLoading(false);
    };
    generarInfo();
    //informeEvaluaciones();
    //informeRiesgo();
    //informePA();
  }, []);

  const handleOnExport = () => {
    let datosVSCFiltrados = datosVSC.filter((item) => {
        return item.idcompania === parseInt(localStorage.getItem("idcompania"));
    });
    let nameCompania = datosVSCFiltrados[0].nombreregional;

    const newBook = XLSX.utils.book_new(),
      sheet1 = XLSX.utils.json_to_sheet(datosVSCFiltrados);
    XLSX.utils.book_append_sheet(newBook, sheet1, "Informe_VSC");
    XLSX.writeFile(newBook, "Informe_VSC_" + nameCompania + ".xlsx");
  };
  return (
    datosVSC &&
    datosVSC.length > 0 && (
      <div className='d-flex flex-column'>
        <AlertDismissibleExample alerta={estadoPOST} />
        <h2 className='titulo'>Informe VSC</h2>
        <hr />

        {loading ? (
          <CircularProgress />
        ) : (
          <div className='d-flex justify-content-center align-items-center col-12'>
            <Button className='botonPositivo' onClick={handleOnExport}>
              Descargar informe de VSC &nbsp;
              <IoMdDownload />
            </Button>
          </div>
        )}
      </div>
    )
  );
}
