import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { CircularProgress, TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";

import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../CheckRole";
import AADService from "../funcionesAuth.js";
import XLSX from "xlsx";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions";

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function App() {
  //Establece estilos de componentes

  const ExportToExcel = ({ apiData }) => {
    const downloadExcel = (data) => {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "informe.xlsx");
    };

    return (
      <Button
        className='botonPositivo'
        style={{ minWidth: "250px", float: "right" }}
        onClick={(e) => downloadExcel(apiData)}
      >
        Descargar Informe
      </Button>
    );
  };

  function AlertDismissibleExampleModal({ alertaModal, descargainforme }) {
    const [data, setData] = React.useState([]);
    useEffect(() => {
      const excelData = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "activoInformeError/" +
            descargainforme,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (result.status >= 200 && result.status <= 300) {
          let data = await result.json();
          setData(data);
        } else if (result.status >= 400 && result.status <= 500) {
          return result.status;
        }
      };
      excelData();
    }, []);
    if (descargainforme != 0) {
      switch (alertaModal) {
        case 1:
          return <Alert variant='warning'>Alerta</Alert>;
          break;
        case 2:
          return (
            <Alert variant='success'>
              Se han cargado los datos exitosamente
            </Alert>
          );
          break;
        case 3:
          return <Alert variant='warning'>Elija un Archivo</Alert>;
          break;
        case 4:
          return (
            <Alert variant='warning' style={{ paddingBottom: "22px" }}>
              <div className='App'>
                <ExportToExcel apiData={data} />
              </div>
              Error al enviar la información
            </Alert>
          );
          break;
        case 5:
          return (
            <Alert variant='danger'>El Archivo Cargado No es valido</Alert>
          );
          break;
        case 6:
          return <Alert variant='warning'>Error procesando los datos</Alert>;
          break;
        default:
          return null;
          break;
      }
    } else {
      return null;
    }
  }

  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      //margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  //Modifica temas predeterminados por Material-Table
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });

  var columns = [
    {
      field: "idactivo",
      title: "Id Componente",
      align: "justify",
    },
    {
      field: "elemento_configuracion",
      title: "Codigo app",
      align: "justify",
    },
    {
      field: "nombre",
      title: "Nombre",
    },
    {
      field: "compania",
      title: "Compañia",
      render: (rowData) => bCompania(rowData.idcompania),
    },
    {
      field: "componente_principal",
      title: "Principal",
      render: (rowData) => cprincipal(rowData.componente_principal),
    },
    {
      field: "descripcion",
      title: "Descripción",
    },
    {
      field: "UsuarioResponsableTI",
      title: "Responsable TI",
    },
    {
      field: "UsuarioResponsableNegocio",
      title: "Responsable Negocio",
    },
    {
      field: "fechacreacion",
      title: "Fecha Creación",
    },
    {
      field: "fechamodificacion",
      title: "Fecha Actualización",
    },
  ];

  const classes = useStyles();
  const [eventos, setEventos] = useState([]);
  const [activo, setActivo] = useState([]); //TODO: table data 
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [filter, setFilter] = useState(false);
  const [nameCompa, setNameCompa] = React.useState("");
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  const check = new CheckRole("/componente-tecnologico");
  const [showModal, setShowModal] = React.useState(false);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [id_descargainforme, setId_descargainforme] = React.useState(1);

  useEffect(() => {
    //let data = [];
    const fetchdata_COM = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "allcomponents/" +
          localStorage.getItem("idcompania") +
          "/1/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let data = await result.json(); //TODO:
        setActivo(data);
        //fetchdata_NUB(data);
      } else if (result.status >= 400 && result.status <= 500) {
        return result.status;
      }
    };

/*     const fetchdata_NUB = async (data) => {
      const result2 = await fetch(
        process.env.REACT_APP_API_URL +
          "activosCodGen/" +
          localStorage.getItem("idcompania") +
          "/NUB/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            //Authorization: "Bearer " + token,
          },
        }
      );
      if (result2.status >= 200 && result2.status <= 300) {
        let data2 = await result2.json();
        let data_final = data.concat(data2);
        setActivo(data_final);
      } else if (result2.status >= 400 && result2.status <= 500) {
        return result2.status;
      }
    }; */

    const obtenerCompañias = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datCompania = await result.json();
      setNameCompa(datCompania);
    };
    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encryptedUser = await result.json();
      const decryptedUser = DecryptData(encryptedUser);
      let usuario = JSON.parse(decryptedUser);

      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }

      setOpcionesGenerales(opciones);
      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
        fetchdata_COM();
        //fetchdata_NUB();
        obtenerCompañias();
      }
    };
    fetchUsuario();
  }, []);

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], id);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };

  const editar = () => {
    localStorage.setItem("idactivo", selected);
  };

  function add_eval() {
    localStorage.setItem("idactivo", 0);
  }

  function ModalCarga(props) {
    const [stateArchivo, setStateArchivo] = React.useState([]);
    const [archivo, setArchivo] = React.useState([]);
    const [names, setNames] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [informeExcel, setInformeExcel] = React.useState([]);
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    let tempArchivos = [];
    let temp = [];

    const subirArchivo = (e) => {
      //console.log("Estoy en subir archivo");
      //console.log("info archivo", e);
      setEstadoPost(0);
      setArchivo([]);
      //setNames([]);
      setStateArchivo([]);
      setNames([]);
      //setNamesN([]);

      for (let i = 0; i < e.length; i++) {
        if (
          e[i].name.split(".")[1] == "xlsx" ||
          e[i].name.split(".")[1] == "csv"
        ) {
          temp.push(e[i].name);
          tempArchivos.push(e[i]);
        }
      }
      setArchivo(tempArchivos);
      setNames(temp);
      // setLoading(false)
    };

    const fetchdataPost = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "activoInfoMasivo/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setActivo(data);
    };

    const postArchivo = async (event, file) => {
      //console.log("Estoy en post archivo");
      setLoading(true);
      if (file.length) {
        setEstadoPost(0);
        event.preventDefault();
        const form = event.currentTarget;
        let reader = new FileReader();
        reader.readAsArrayBuffer(file[0]);
        reader.onloadend = (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
          var sheetName = workbook.SheetNames[0];
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          procesarDatos(names[0], XL_row_object);
        };
      } else {
        event.preventDefault();
        setLoading(false);
        setEstadoPost(3);
      }
      // setLoading(false)
    };

    const procesarDatos = async (archivosKEY, info) => {
      //console.log("Estoy en procesar datos")
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      //console.log("tipo de dato de info", typeof info);
      for (let i = 0; i < info.length; i++) {
        info[i]['fechacreacion'] = today.toISOString();
        info[i]['fechamodificacion'] = today.toISOString();
        info[i]['idusuariocreacion'] = localStorage.getItem("idusuario");
        info[i]['idusuariomodificacion'] = localStorage.getItem("idusuario");
        info[i]['componente_principal'] = 0;
      }
      var data = {
        idcompania:
          localStorage.getItem("idcompania") != null &&
          localStorage.getItem("idcompania") != undefined
            ? localStorage.getItem("idcompania")
            : 1,

        key: archivosKEY,
        message: info,
      };
      let datos = JSON.stringify(data);
      fetch(
        process.env.REACT_APP_API_URL +
          "activoInfoMasivo/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "POST",
          //responseType: "blob",
          body: datos,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) =>
          response.json().then((data) => {
            //console.log("$$$$", response);
            if (response.status >= 200 && response.status < 300) {
              setEstadoPost(2);
              setLoading(false);
              fetchdataPost();
              //props.onHide();
            } else if (response.status >= 500) {
              setEstadoPost(5);
              //props.onHide();
              setLoading(false);
            } else if (response.status >= 400 && response.status < 500) {
              setId_descargainforme(data.id_activo);
              // console.log(id_descargainforme)
              setEstadoPost(4);
              setLoading(false);
            }
          })
        )
        .catch(function (err) {});
      //setShowModal(true);
    };

    return (
      <Modal
        {...props}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
      >
        <Form
          onSubmit={(e) => {
            postArchivo(e, archivo);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Cargue Masivo de Componentes
              <br></br>
              <a
                href='https://bancolombia.sharepoint.com.mcas.ms/teams/CdulaRiesgoTI-Ciber/_layouts/15/download.aspx?UniqueId=ac10f560%2Db69d%2D4bc2%2Da755%2D8240645a720c&McasTsid=20892'
                target='_blank'
                rel='noopener noreferrer'
              >
                Descargar plantilla
              </a>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AlertDismissibleExampleModal
              alertaModal={estadoPOST}
              descargainforme={id_descargainforme}
            />
            <Row className='mb-3'>
              <Col>
                <input
                  type='file'
                  name='files'
                  accept='.xlsx,.csv'
                  multiple
                  onChange={(e) => subirArchivo(e.target.files)}
                ></input>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <ul className='list-group'>
                  <li className='list-group-item'>
                    <Row>
                      <Col sm={4}>
                        <h5>Nombre del archivo</h5>
                      </Col>
                      <Col sm={4}>
                        <h5>Nombre en el servidor</h5>
                      </Col>
                      <Col sm={1}>
                        <h5>Estado</h5>
                      </Col>
                    </Row>
                  </li>
                  {names.map((item, index) => (
                    <li className='list-group-item' key={index}>
                      <Row>
                        <Col sm={4}>{item}</Col>
                        <Col sm={4}>{names[index]}</Col>
                        <Col sm={1}>{stateArchivo[index]}</Col>
                      </Row>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <Button
                type='submit'
                className='botonPositivo'
                style={{ minWidth: "20%" }}
              >
                Cargar
              </Button>
            )}
            <Button onClick={props.onHide} className='botonNegativo'>
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  function cprincipal (componente_principal){

    if (componente_principal === true) {
      return "Si";
    } else if (componente_principal === false) {
      return "No";
    } else {
      return "Valor no reconocido";
    }
  }

  //Retorna el nombre de la compañía segun id
  function bCompania(id) {
    for (const clave in nameCompa) {
      if (id == nameCompa[clave]["idcompania"]) {
        return nameCompa[clave]["compania"];
      }
    }
    return "No Existe";
  }

  //Habilita y desabilita los filtros por columnas
  const handleChange = () => {
    setFilter(!filter);
  }; 
  return (
    <div className={classes.root}>
      <ModalCarga show={showModal} onHide={() => setShowModal(false)} />
      <Row>
        <Col>
          <h2 className='titulo'>Componentes tecnológicos</h2>
        </Col>
        {/* <Button
          className='botonNegativo'
          onClick={() => setShowModal(true)}
          style={{ margin: "15px" }}
        >
          Cargue Masivo De Componentes
        </Button> */}
      </Row>
      <Paper variant='outlined' className={classes.paper}>
        <MuiThemeProvider style={theme}>
          <MaterialTable
            columns={columns}
            data={activo}
            icons={tableIcons}
            options={{
              rowStyle: (rowData) => ({
                backgroundColor:
                  ButtonEdit == true && selectedRow === rowData.tableData.id
                    ? "#fff8d3"
                    : "#FFF",
              }),
              showTitle: false,
              filtering: filter,
              searchFieldAlignment: "left",
              searchFieldStyle: {}, //Llenar estilo de barra de busqueda
              headerStyle: {
                backgroundColor: "#2c2a29",
                color: "#ffffff",
                position: "sticky",
                top: 0,
                fontFamily: "CIBFont Sans Regular",
                fontWeight: 400,
                fontSize: "1.2rem",
                lineHeight: 1.6,
                letterSpacing: "0.0075em",
                flex: "1 1 100%",
              },
              maxBodyHeight: "650px",
              selection: false,
              disableMultipleSelection: true,
              pageSizeOptions: [10, 20, 30],
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 10,
            }}
            components={{
              Pagination: (props) => (
                <div className={classes.pagination}>
                  <FormControlLabel
                    className={classes.switch}
                    control={
                      <Checkbox
                        checked={filter}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={
                      <Typography variant='body2'>
                        Mostrar filtros por columnas
                      </Typography>
                    }
                  />
                  <TablePagination {...props} className={classes.pagination} />
                </div>
              ),
            }}
            onRowClick={(evt, selectedRow) => {
              handleClick(selectedRow.idactivo);
              return setSelectedRow(selectedRow.tableData.id);
            }}
            actions={[
              // {
              //   icon: () => (
              //     <Link >
              //       {check.checkPermits(opcionesGenerales, "POST") ? (
              //         <Button className="botonNegativo" style={{minWidth: "250px"}}>Cargar Componentes</Button>
              //       ) : (
              //         ""
              //       )}
              //     </Link>
              //   ),
              //   onClick: setShowModal(true),
              //   position: "toolbar",
              //   isFreeAction: true,
              // },
              {
                icon: () => {
                  var rolcompaniaselected = localStorage.getItem("idcompania")
                  if (rolcompaniaselected!== "1" && rolcompaniaselected !== "5") { //verifica si la compañia es bancolombia ó batnismo
                    return (
                      <Link to='NuevoComponente'>
                        {check.checkPermits(opcionesGenerales, "POST") ? (
                          <Button
                            className='botonPositivo'
                            style={{ minWidth: "280px" }}
                          >
                            Nuevo
                          </Button>
                        ) : (
                          ""
                        )}
                      </Link>
                    );
                  } else {
                    return null; 
                  }
                },
                onClick: add_eval(),
                position: "toolbar",
                isFreeAction: true,                
              },
              {
                icon: () => (
                  <Link to='EditarComponente'>
                    {check.checkPermits(opcionesGenerales, "PUT") ? (
                      <Button
                        className='botonGeneral'
                        style={{ minWidth: "250px" }}
                      >
                        Editar
                      </Button>
                    ) : (
                      ""
                    )}
                  </Link>
                ),
                onClick: editar(),
                position: "toolbar",
                isFreeAction: true,
                hidden: ButtonEdit == false,
              },
            ]}
            localization={{
              toolbar: {
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No se encontraron registros",
              },
              pagination: {
                labelRowsSelect: "Filas",
                firstTooltip: "Primera página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Última página",
              },
            }}
          />
        </MuiThemeProvider>
      </Paper>
    </div>
  );
}

export default App;
