export const obtenerCompanias = async () => {
  const result = await fetch(
    process.env.REACT_APP_API_URL + "general/compania/",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        //Authorization: "Bearer " + token,
      },
    }
  );
  let datCompania = await result.json();
  return datCompania;
};
