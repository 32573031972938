import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const AdvertenciaModal = ({ mostrar, onClose, onConfirm }) => {
  return (
    <Modal show={mostrar} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Advertencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        ¿Estás seguro de que deseas cambiar de pestaña? Debes guardar los cambios antes de continuar, si no, se perderá tu progreso en el cuestionario nuevo.
        <br />
        Volver para guardar los cambios o continuar sin guardar.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Volver
        </Button>
        <Button variant="secondary" onClick={onConfirm}>
          No guardar y continuar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdvertenciaModal;
